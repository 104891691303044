import gql from "graphql-tag";
export const fiscalStockAnalysisFragment = gql`
  fragment FiscalStockAnalysis on StockAnalysis {
    id,
    name,
    unit,
    availableBalance,
    totalBalance,
    previousBalance,    
    entries,
    exits,
    growth,
    growthPercentage,
    reserved,
    reservedPercentage,
    losses,
    lossesPercentage,
    minimum,
    maximum,
  }
`;