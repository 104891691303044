import gql from "graphql-tag";
import { companyFragment } from 'admin-front';
import { paymentConditionFragment, paymentMethodFragment } from "financial-front";
import { simpleRequestProductUnitFragment } from "./SimpleRequestProductUnit";
export const simpleRequestFragment = gql`
  fragment SimpleRequest on SimpleRequest  { 
    id,
    transactionId,
    paymentCondition {
      ...PaymentCondition
    },
    paymentMethod {
      ...PaymentMethod
    },
    pickupCompany{
      ...Company
    },
    currentEvent,
    currentDate,
    requestIds,
    requestProductUnits {
      ...SimpleRequestProductUnit
    }
  }
  ${simpleRequestProductUnitFragment}
  ${paymentConditionFragment}
  ${paymentMethodFragment}
  ${companyFragment}
`;