import gql from "graphql-tag";
import { stockDailyBalanceFragment } from "./StockDailyBalance";
import { stockFlowMovementFragment } from "./StockFlowMovement";
import { stockFlowBatchFragment } from "./StockFlowBatch";
export const stockFlowLocationFragment = gql`
  fragment StockFlowLocation on StockFlowLocation  {
    id,
    name,
    batches {
      ...StockFlowBatch
    }
    movements {
      ...StockFlowMovement
    }
    balance {
      ...StockDailyBalance
    }
  }
  ${stockFlowBatchFragment}
  ${stockFlowMovementFragment}
  ${stockDailyBalanceFragment}
`;