import { dateOrToday } from 'grifo-components';
import { MONTH } from "financial-front";
import { FINISHED, OPENED, FROM_REQUEST, MAKE_UP_TOTAL, invoiceDefaultBuilder } from 'fiscal-front';
export const RequestToinvoiceParamBuilder = (record, values) => {
  let data = {
    requestId: (record || {}).requestId,
    invoice: {
      origin: FROM_REQUEST,
      invoiceNumber: values.invoiceNumber,
      dfeNumber: values.dfeNumber,
      dfeSeries: values.dfeSeries,
      dfeModel: values.dfeModel,
      personId: values.personId,
      addressId: values.addressId,
      pickupMethod: values.pickupMethod,
      receiverId: values.receiverId,
      pickupLocationId: values.pickupLocationId,
      deliveryMethod: values.deliveryMethod,
      freightMethod: values.freightMethod,
      dispatcherId: values.dispatcherId,
      deliveryPlaceId: values.deliveryPlaceId,
      shipperId: values.shipperId,
      vehicleId: values.vehicleId,
      operationId: values.operationId,
      transactionId: values.transactionId,
      situation: FINISHED,
      date: values.date ? values.date.format("YYYY-MM-DD") : undefined,
      issuanceDate: values.issuanceDate ? values.issuanceDate.format("YYYY-MM-DD") : undefined,
      departureDate: values.departureDate ? values.departureDate.format("YYYY-MM-DD") : undefined,
      operationDestination: values.operationDestination,
      finalCostumerMethod: values.finalCostumerMethod,
      buyerPresenceIndicator: values.buyerPresenceIndicator,
      brokerIndicator: values.brokerIndicator,
      itemsTotal: values.itemsTotal,
      totalTaxes: values.totalTaxes,
      freightValue: values.freightValue,
      insuranceValue: values.insuranceValue,
      discountValue: values.discountValue,
      otherValue: values.otherValue,
      total: values.total,
      infos: values.infos || [],
      volumes: values.volumes || [],
      items: (values.items || []).map((object, index) => {
        const preparedAttributes = {
          invoiceIndex: object.invoiceIndex || index + 1,
          taxableQuantity: object.quantity,
          taxableUnitaryValue: object.unitaryValue,
          taxableGrossTotal: object.grossTotal,
          productId: object.productUnit[0],
          productUnitId: object.productUnit[1],
          locationId: object.locationId || object.productUnit[2],
          batchId: object.productUnit[3]
        };
        delete object.productUnit;
        delete object.product;
        delete object.location;
        delete object.batch;
        return {
          ...object,
          ...preparedAttributes
        };
      }),
      payments: values.payments
    }
  };
  return {
    attributes: {
      ...data
    }
  };
};
export const RequestToinvoiceDefaultBuilder = record => {
  const object = record || {};
  let data = {
    requestId: object.requestId,
    personId: object.companyId,
    situation: OPENED,
    items: object.requestProductUnits.map(p => ({
      productUnitId: p.productUnit.id,
      invoiceIndex: object?.invoiceIndex || 1,
      discountValue: 0,
      freightValue: 0,
      insuranceValue: 0,
      otherExpenses: 0,
      totalInvoiceMethod: MAKE_UP_TOTAL,
      quantity: p.quantity
    })),
    date: dateOrToday(object.date),
    payments: [{
      paymentMethodId: (object.paymentMethod || {}).id,
      paymentConditionId: (object.paymentCondition || {}).id,
      valueToPay: 0,
      methodFees: 0,
      methodFeesRate: 0,
      conditionFees: 0,
      conditionFeesRate: 0,
      fees: 0,
      methodDiscount: 0,
      methodDiscountRate: 0,
      conditionDiscount: 0,
      conditionDiscountRate: 0,
      discount: 0,
      total: 0,
      time: 1,
      timeKind: MONTH,
      installment: 1,
      gracePeriod: 0
    }]
  };
  return invoiceDefaultBuilder(data);
};