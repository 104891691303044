import gql from "graphql-tag";
import { vehicleTypeFragment } from "../fragments/VehicleType";
export const GQL_VEHICLE_TYPES_SEARCH = gql`
  {
    vehicleTypes { 
      ...VehicleType
    }
  }
  ${vehicleTypeFragment}   
`;
export const GQL_VEHICLE_TYPES_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    vehicleTypesPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...VehicleType
        }
      }
    }    
  }
  ${vehicleTypeFragment}
`;
export const GQL_ALL_VEHICLE_TYPES_LIST = gql`
  query GetAllToList {    
      vehicleTypes { 
        ...VehicleType
      }
  }
  ${vehicleTypeFragment}
`;
export const GQL_VEHICLE_TYPES_STORE = gql`
  mutation StoreVehicleType($attributes: VehicleTypeAttributes!) {
    storeVehicleType(input: {attributes: $attributes}) { 
      vehicleType {
        ...VehicleType
    }, 
      errors 
    } 
  }
  ${vehicleTypeFragment}   
`;
export const GQL_VEHICLE_TYPES_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Fleet::VehicleType"}) {
      id,
      discardedAt
    }
  }
`;