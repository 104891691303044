import gql from "graphql-tag";
export const WaybillSimpleFragment = gql`
  fragment WaybillSimple on Waybill  { 
    id,
    cooperativeAct,
    transactionId,
    contributeIcms,
    finalCostumerMethod,
    operationDestination,
    dfeSeries,
    dfeModel,
    dfeNumber,
    issuancePurpose,
    issuanceDate,
    serviceType,
    modal,
    receiverWithdraw,
    receiverDetail,
    serviceTakerIdentifier,
    description,
    additionalTransportationFeature,
    additionalServiceFeature,
    additionalCargoFeature,
    cargoValue,
    value,
    total,
    expectedDeliveryDate,

    userId,    
    operationId,
    taxProfileId,
    cfopId,
    cityIssuerId,
    stateIssuerId,
    personOriginId,
    addressOriginId,
    cityOriginId,
    cityStartId,
    dispatcherId,
    personDestinationId,
    addressDestinationId,
    cityDestinationId,
    receiverId,
    serviceTakerId,
    serviceTakerAddressId,
    hiredId,
    driverId,
    vehicleOwnerId,
    vehicleId,
  }
`;