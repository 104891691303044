import gql from "graphql-tag";
import { invoiceListingFragment } from "./InvoiceListing";
export const parentInvoiceListingFragment = gql`
  fragment ParentInvoiceListing on Invoice {
    ...InvoiceListing,
    parent {
      ...InvoiceListing,
    },
    children {
      ...InvoiceListing,
      children {
        ...InvoiceListing,
        children {
          ...InvoiceListing,
          children {
            ...InvoiceListing,
            children {
              ...InvoiceListing,
            }
          }
        }
      }
    }
  }
  ${invoiceListingFragment}
`;