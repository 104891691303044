export const additionalInformationParamBuilder = (record, values) => {
  return {
    attributes: values.additionalInformations.map(object => ({
      ...object,
      taxProfileId: record.id
    }))
  };
};
export const additionalInformationDefaultBuilder = record => {
  return {
    additionalInformations: record || []
  };
};