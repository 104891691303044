import gql from "graphql-tag";
import { productInfoFragment } from "../fragments/ProductInfo";
export const GQL_PRODUCT_INFOS_STORE = gql`
  mutation StoreProductInfos($attributes: [ProductInfosAttributes]!, $productId: ID!) {
    storeProductInfos(input: {attributes: $attributes, productId: $productId}) { 
      productInfos {
        ...ProductInfo
      }, 
      errors 
    } 
  }
  ${productInfoFragment}   
`;