import gql from "graphql-tag";
export const certificateFragment = gql`
  fragment Certificate on Certificate {
    id,
    expirationDate,
    documents64 {
      id,
      data,
      filename,
      contentType,
      key
    }
  }
`;