import gql from "graphql-tag";
import { TaxProfileFragment } from "../fragments/TaxProfile";
export const GQL_TAX_PROFILE_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $taxProfileFilter: TaxProfileFilter) {    
    taxProfilesPaginated(first: $pageSize, after: $cursor, taxProfileFilter: $taxProfileFilter ) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...TaxProfile
        }
      }
    }    
  }
  ${TaxProfileFragment}
`;
export const GQL_TAX_PROFILE_LIST_BY_KIND = gql`
  query GetByKind($kind: String) {    
    taxProfiles(kind: $kind) {
      ...TaxProfile
    }
  }
  ${TaxProfileFragment}
`;
export const GQL_TAX_PROFILE_BY_KIND_AND_FINAL_COSTUMER_METHOD = gql`
  query GetByKindAndFinalCostumerMethod($filter: FilterTaxProfile) {    
    taxProfiles(filter: $filter) {
      ...TaxProfile
    }
  }
  ${TaxProfileFragment}
`;
export const GQL_TAX_PROFILE_LIST_BY_APPLICATION_KIND = gql`
  query GetByApplicationKind($applicationKind: String) {    
    taxProfiles(applicationKind: $applicationKind) {
      ...TaxProfile
    }
  }
  ${TaxProfileFragment}
`;
export const GQL_TAX_PROFILE_STORE = gql`
  mutation StoreTaxProfile($attributes: TaxProfileAttributes!) {
    storeTaxProfile(input: {attributes: $attributes}) { 
      taxProfile {
        ...TaxProfile,
      }, 
      errors 
    } 
  }
  ${TaxProfileFragment}
`;
export const GQL_TAX_PROFILE_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Fiscal::TaxProfile"}) {
      id,
      discardedAt
    }
  }
`;