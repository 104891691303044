import gql from "graphql-tag";
import { ncmCestFragment } from "./NcmCest";
export const cestFragment = gql`
  fragment Cest on Cest {
    id,
    code,
    description,
    start,
    finish,
    ncmCests {
      ...NcmCest
    }
  }
  ${ncmCestFragment}
`;