import { CANDLESTICK_CHART_TYPE, LINE_CHART_TYPE } from "../../constants/Charts";
import { NEGATIVE_RED, POSITIVE_GREEN } from "../../constants/Colors";
import { getLabelByValue, uniq } from "../ListUtil";
import { buildMarkLine, formatter } from "./GenericChartUtil";
import { buildSeries } from "./SeriesLineBarBuilderUtil";
export const buildCandlestickSeries = (data, seriesField, valueField, valueDataType, seriesFieldDictionary, xAxisData, xAxisField, markLineData, value2Field, value2DataType, seriesFieldName, series2FieldName) => {
  let series = [];
  uniq(data, seriesField).map(item => {
    series.push({
      name: seriesFieldDictionary ? getLabelByValue(seriesFieldDictionary, item) : seriesFieldName ? seriesFieldName : item,
      type: CANDLESTICK_CHART_TYPE,
      smooth: true,
      data: buildXData(data, seriesField, valueField, xAxisData, xAxisField, item),
      label: {
        formatter: (params, index) => formatter(params.value, valueDataType, params.seriesName + ': \n')
      },
      itemStyle: {
        color: POSITIVE_GREEN,
        color0: NEGATIVE_RED,
        borderColor: POSITIVE_GREEN,
        borderColor0: NEGATIVE_RED
      },
      endLabel: {
        show: true,
        align: 'left',
        position: 'top',
        fontWeight: 'bold',
        fontSize: 12,
        overflow: 'breakAll',
        offset: [-100, -10]
      },
      markLine: buildMarkLine(markLineData, valueDataType)
    });
    if (value2Field) series.push(buildSeries(data, item, seriesField, series2FieldName, seriesFieldDictionary, value2Field, value2DataType, LINE_CHART_TYPE, xAxisData, xAxisField, [], 1));
  });
  return series;
};
const buildXData = (data, seriesField, valueField, xAxisData, xAxisField, item) => {
  let seriesData = [];
  const records = data.filter(record => record[seriesField] === item);
  xAxisData.map(data => {
    const record = records.filter(record => record[seriesField] === item && record[xAxisField] === data)[0];
    const openValue = record?.[valueField[0]] || 0;
    const closeValue = record?.[valueField[1]] || 0;
    const lowValue = record?.[valueField[2]] || 0;
    const highValue = record?.[valueField[3]] || 0;
    seriesData.push([openValue, closeValue, lowValue, highValue]);
  });
  return seriesData;
};