import gql from "graphql-tag";
import { offerFragment } from "../fragments/Offer";
import { productSearchFragment } from 'stock-front';
export const GQL_SEARCH_OFFERS = gql`
  query GetByName($name: String) {    
    offers(name: $name) { 
        ...Offer
    }
  }
  ${offerFragment}   
`;
export const GQL_SEARCH_BY_ID_OFFERS = gql`
  query GetById($id: ID) {    
    offerProductSearch(id: $id) { 
      ...ProductSearch
    }
  }
  ${productSearchFragment}   
`;
export const GQL_PUBLISHED_OFFERS = gql`
  query GetPublished($pageSize: Int, $cursor: String, $published: Boolean) {    
    offersPaginated(first: $pageSize, after: $cursor, published: $published) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Offer
        }
      }
    }    
  }
  ${offerFragment}
`;
export const GQL_OFFERS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $offerFilter: OfferFilter) {    
    offersPaginated(first: $pageSize, after: $cursor, offerFilter: $offerFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Offer
        }
      }
    }    
  }
  ${offerFragment}
`;
export const GQL_OFFER_STORE = gql`
  mutation StoreOffer($attributes: OfferAttributes!) {
    storeOffer(input: {attributes: $attributes}) { 
      offer {
        ...Offer
    }, 
      errors 
    } 
  }
  ${offerFragment}   
`;
export const GQL_CANCEL_OFFER = gql`
  mutation CancelOffer($id: ID!) { 
    cancelOffer(input: {id: $id}) {
      id
    }
  }
`;
export const GQL_PUBLISH_OFFER = gql`
  mutation PublishOffer($id: ID!) { 
    publishOffer(input: {id: $id}) {
      id
    }
  }
`;
export const GQL_FINISH_OFFER = gql`
  mutation FinishOffer($id: ID!) { 
    finishOffer(input: {id: $id}) {
      id
    }
  }
`;
export const GQL_OFFER_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Acquisition::Offer"}) {
      id,
      discardedAt
    }
  }
`;