import { YES, NO } from 'grifo-components';
export const PRODUCT_AND_SERVICE = 'product_and_service';
export const PRODUCT = 'product';
export const SERVICE = 'service';
export const TRANSPORT_SERVICE = 'transport_service';
export const ENTRY = 'entry';
export const EXIT = 'exit';
export const NORMAL = 'normal';
export const COSTUMER_FINAL = 'costumer_final';
export const COSTUMER_FINAL_NFCE = 'costumer_final_nfce';
export const WITHHOLDING = 'withholding';
export const COLLECTED = 'collected';
export const TAXABLE = 'taxable';
export const TAXABLE_WITH_DISTINGUISH_RATE = 'taxable_with_distinguish_rate';
export const MISSING_TAX_PROFILE = 'missing_tax_profile';
export const TAXABLE_WITH_DISTINGUISH_RATE_PER_PRODUCT_UNIT = 'taxable_with_distinguish_rate_per_product_unit';
export const TAXABLE_SINGLE_PHASE = 'taxable_single_phase';
export const TAXABLE_WITH_TAX_SUBSTITUTION = 'taxable_with_tax_substitution';
export const TAXABLE_ZERO_RATE = 'taxable_zero_rate';
export const EXEMPT = 'exempt';
export const WITHOUT_INCIDENCE = 'without_incidence';
export const SUSPENSION = 'suspension';
export const OTHER_EXIT_OPERATIONS = 'other_exit_operations';
export const CREDIT_INTERN_MARKET_TAXED = 'credit_intern_market_taxed';
export const CREDIT_INTERN_MARKET_NOT_TAXED = 'credit_intern_market_not_taxed';
export const CREDIT_EXPORT = 'credit_export';
export const CREDIT_INTERN_MARKET_TAXED_AND_NOT = 'credit_intern_market_taxed_and_not';
export const CREDIT_INTERN_MARKET_TAXED_AND_EXPORT = 'credit_intern_market_taxed_and_export';
export const CREDIT_INTERN_MARKET_NOT_TAXED_AND_EXPORT = 'credit_intern_market_not_taxed_and_export';
export const CREDIT_INTERN_MARKET_TAXED_AND_NOT_AND_EXPORT = 'credit_intern_market_taxed_and_not_and_export';
export const PRESUMED_CREDIT_INTERN_MARKET_TAXED = 'presumed_credit_intern_market_taxed';
export const PRESUMED_CREDIT_INTERN_MARKET_NOT_TAXED = 'presumed_credit_intern_market_not_taxed';
export const PRESUMED_CREDIT_EXPORT = 'presumed_credit_export';
export const PRESUMED_CREDIT_INTERN_MARKET_TAXED_AND_NOT = 'presumed_credit_intern_market_taxed_and_not';
export const PRESUMED_CREDIT_INTERN_MARKET_TAXED_AND_EXPORT = 'presumed_credit_intern_market_taxed_and_export';
export const PRESUMED_CREDIT_INTERN_MARKET_NOT_TAXED_AND_EXPORT = 'presumed_credit_intern_market_not_taxed_and_export';
export const PRESUMED_CREDIT_INTERN_MARKET_TAXED_AND_NOT_AND_EXPORT = 'presumed_credit_intern_market_taxed_and_not_and_export';
export const PRESUMED_CREDIT_OTHER = 'presumed_credit_other';
export const AQUISITION_WITHOUT_CREDIT = 'aquisition_without_credit';
export const AQUISITION_WITH_EXEMPT = 'aquisition_with_exempt';
export const AQUISITION_WITH_SUSPENSION = 'aquisition_with_suspension';
export const AQUISITION_ZERO_RATE = 'aquisition_zero_rate';
export const AQUISITION_WITHOUT_INCIDENCE = 'aquisition_without_incidence';
export const AQUISITION_WITH_TAX_SUBSTITUTION = 'aquisition_with_tax_substitution';
export const OTHER_ENTRY_OPERATIONS = 'other_entry_operations';
export const OTHER_OPERATIONS = 'other_operations';
export const PER_RATE = 'per_rate';
export const PER_UNIT = 'per_unit';
export const ENTRY_WITH_CREDIT_RECOVERY = 'entry_with_credit_recovery';
export const ENTRY_TAXABLE_ZERO_RATE = 'entry_taxable_zero_rate';
export const ENTRY_EXEMPT = 'entry_exempt';
export const ENTRY_NOT_TAXABLE = 'entry_not_taxable';
export const ENTRY_IMMUNE = 'entry_immune';
export const ENTRY_SUSPENSION = 'entry_suspension';
export const OTHER_ENTRIES = 'other_entries';
export const TAXABLE_EXIT = 'taxable_exit';
export const EXIT_TAXABLE_ZERO_RATE = 'exit_taxable_zero_rate';
export const EXIT_EXEMPT = 'exit_exempt';
export const EXIT_NOT_TAXABLE = 'exit_not_taxable';
export const EXIT_IMMUNE = 'exit_immune';
export const EXIT_SUSPENSION = 'exit_suspension';
export const OTHER_EXITS = 'other_exits';
export const CHARGEABLE = 'chargeable';
export const NON_INCIDENCE = 'non_incidence';
export const EXPORTATION = 'exportation';
export const IMMUNE = 'immune';
export const SUSPENDED_BY_JURIDICAL_DECISION = 'suspended_by_juridical_decision';
export const SUSPENDED_BY_ADMINISTRATIVE_PROCESS = 'suspended_by_administrative_process';
export const MUNICIPAL_MICRO_COMPANY = 'municipal_micro_company';
export const ESTIMATED = 'estimated';
export const PROFESSIONAL_SOCIETY = 'professional_society';
export const COOPERATIVE = 'cooperative';
export const MEI = 'mei';
export const ME_EPP = 'me_epp';

// export const TAXABLE = 'taxable'
export const TAXABLE_BY_SUBSTITUTION = 'taxable_by_substitution';
export const TAXABLE_WITH_BASIS_REDUCTION = 'taxable_with_basis_reduction';
export const EXEMPT_WITH_SUBSTITUTION = 'exempt_with_substitution';
// export const EXEMPT = 'exempt'
export const NOT_TAXABLE = 'not_taxable';
// export const SUSPENSION = 'suspension'
export const DEFERRAL = 'deferral';
export const PREVIOUS_TAXABLE_BY_SUBSTITUTION = 'previous_taxable_by_substitution';
export const TAXABLE_WITH_BASIS_REDUCTION_AND_SUBSTITUTION = 'taxable_with_basis_reduction_and_substitution';
export const OTHERS = 'others';
export const NATIONAL_EXCEPT_3_4_5_8 = 'national_except3458';
export const FOREIGN_EXCEPT_6 = 'foreign_except6';
export const FOREIGN_EXCEPT_7 = 'foreign_except7';
export const NATIONAL_IMPORT_CONTENT_BETWEEN_40_70 = 'national_import_content_between4070';
export const NATIONAL_ACCORDANCE_WITH_LAW = 'national_accordance_with_law';
export const NATIONAL_IMPORT_CONTENT_UNTIL_40 = 'national_import_content_until40';
export const FOREIGN_IMPORT_CAMEX_LIST = 'foreign_import_camex_list';
export const FOREIGN_NATIONAL_CAMEX_LIST = 'foreign_national_camex_list';
export const NATIONAL_IMPORT_CONTENT_ABOVE_70 = 'national_import_content_above70';
export const MVA = 'mva';
export const VALUE = 'value';
export const MAX_FIXED_PRICE = 'max_fixed_price';
export const OPERATION_VALUE = 'operation_value';
export const FIXED_PRICE_ST = 'fixed_price_st';
export const NEGATIVE_LIST = 'negative_list';
export const POSITIVE_LIST = 'positive_list';
export const NEUTRAL_LIST = 'neutral_list';
export const MVA_ST = 'mva_st';
export const VALUE_ST = 'value_st';
export const OPERATION_VALUE_ST = 'operation_value_st';
export const TAXI = 'taxi';
export const AGRICULTURE = 'agriculture';
export const RENTAL_COMPANY = 'rental_company';
export const DIPLOMATIC = 'diplomatic';
export const AMAZON_VEHICLES = 'amazon_vehicles';
export const SUFRAMA = 'suframa';
export const SALE_TO_PUBLIC_AGENCY = 'sale_to_public_agency';
export const OTHERS_REASON = 'others_reason';
export const DISABLE_DRIVER = 'disable_driver';
export const DISABLE_NON_DRIVER = 'disable_non_driver';
export const AGRICULTURAL_DEVELOPMENT_AGENCY = 'agricultural_development_agency';
export const OLYMPICS_2016 = 'olympics2016';
export const FISCO = 'fisco';
export const INSIDE_STATE = 'inside_state';
export const OUTSIDE_STATE = 'outside_state';
export const FOREIGN = 'foreign';
export const DOES_NOT_APPLY = 'does_not_apply';
export const IN_PERSON = 'in_person';
export const NOT_IN_PERSON_BY_INTERNET = 'not_in_person_by_internet';
export const NOT_IN_PERSON_CALL_CENTER = 'not_in_person_call_center';
export const NFCE_WITH_HOME_DELIVERY = 'nfce_with_home_delivery';
export const IN_PERSON_OUTSIDE_COMPANY = 'in_person_outside_company';
export const NOT_IN_PERSON_OTHERS = 'not_in_person_others';
export const WITHOUT_BROKER = 'without_broker';
export const SITE_OR_THIRD_PARTY_PLATFORM = 'site_or_third_party_platform';
export const DONT_MAKE_UP_TOTAL = 'dont_make_up_total';
export const MAKE_UP_TOTAL = 'make_up_total';
export const COMMUNICATION = 'communication';
export const DEVOLUTION = 'devolution';
export const REMITTANCE = 'remittance';
export const RETURN = 'return';
export const TRANSPORT = 'transport';
export const ANNULMENT = 'annulment';
export const NO_FUEL = 'no_fuel';
export const OPTIONAL_FREIGHTER = 'optional_freighter';
export const MANDATORY_FREIGHTER = 'mandatory_freighter';
export const ANY_DOCUMENT = 'any_document';
export const ANY_DOCUMENT_EXCEPT_NFCE = 'any_document_except_nfce';
export const MOVEMENT_KIND = [{
  value: ENTRY,
  label: 'Entrada'
}, {
  value: EXIT,
  label: 'Saída'
}];
export const MOVEMENT_KIND_NFCE = [{
  value: EXIT,
  label: 'Saída'
}];
export const APPLICATION_KIND = [{
  value: PRODUCT_AND_SERVICE,
  label: 'Produto e Serviço'
}, {
  value: PRODUCT,
  label: 'Produto'
}, {
  value: SERVICE,
  label: 'Serviço'
}, {
  value: TRANSPORT_SERVICE,
  label: 'Serviço de Transporte'
}];
export const INVOICE_FINAL_COSTUMER_METHOD = [{
  value: NORMAL,
  label: 'Normal'
}, {
  value: COSTUMER_FINAL,
  label: 'Consumidor Final'
}];
export const FINAL_COSTUMER_METHOD = [{
  value: NORMAL,
  label: 'Normal'
}, {
  value: COSTUMER_FINAL,
  label: 'Consumidor Final'
}, {
  value: COSTUMER_FINAL_NFCE,
  label: 'Consumidor Final - NFC-e'
}];
export const CSLL_KIND = [{
  value: WITHHOLDING,
  label: 'Retido'
}, {
  value: COLLECTED,
  label: 'Recolhido'
}];
export const IRPJ_KIND = [{
  value: WITHHOLDING,
  label: 'Retido'
}, {
  value: COLLECTED,
  label: 'Recolhido'
}];
export const INSS_KIND = [{
  value: WITHHOLDING,
  label: 'Retido'
}, {
  value: COLLECTED,
  label: 'Recolhido'
}];
export const PIS_KIND = [{
  value: WITHHOLDING,
  label: 'Retido'
}, {
  value: COLLECTED,
  label: 'Recolhido'
}];
export const IPI_KIND = [{
  value: WITHHOLDING,
  label: 'Retido'
}, {
  value: COLLECTED,
  label: 'Recolhido'
}];
export const IPI_CALCULATION_METHOD = [{
  value: PER_RATE,
  label: 'Por Alíquota'
}, {
  value: PER_UNIT,
  label: 'Por Unidade'
}];
export const PIS_TAX_SITUATION = [{
  value: TAXABLE,
  label: '01=Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))'
}, {
  value: TAXABLE_WITH_DISTINGUISH_RATE,
  label: '02=Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))'
}, {
  value: TAXABLE_WITH_DISTINGUISH_RATE_PER_PRODUCT_UNIT,
  label: '03=Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)'
}, {
  value: TAXABLE_SINGLE_PHASE,
  label: '04=Operação Tributável (tributação monofásica (alíquota zero))'
}, {
  value: TAXABLE_WITH_TAX_SUBSTITUTION,
  label: '05=Operação Tributável (Substituição Tributária)'
}, {
  value: TAXABLE_ZERO_RATE,
  label: '06=Operação Tributável (alíquota zero)'
}, {
  value: EXEMPT,
  label: '07=Operação Isenta da Contribuição'
}, {
  value: WITHOUT_INCIDENCE,
  label: '08=Operação Sem Incidência da Contribuição'
}, {
  value: SUSPENSION,
  label: '09=Operação com Suspensão da Contribuição'
}, {
  value: OTHER_EXIT_OPERATIONS,
  label: '49=Outras Operações de Saída'
}, {
  value: CREDIT_INTERN_MARKET_TAXED,
  label: '50=Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno'
}, {
  value: CREDIT_INTERN_MARKET_NOT_TAXED,
  label: '51=Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno'
}, {
  value: CREDIT_EXPORT,
  label: '52=Operação com Direito a Crédito – Vinculada Exclusivamente a Receita de Exportação'
}, {
  value: CREDIT_INTERN_MARKET_TAXED_AND_NOT,
  label: '53=Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado'
}, {
  value: CREDIT_INTERN_MARKET_TAXED_AND_EXPORT,
  label: '54=Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'
}, {
  value: CREDIT_INTERN_MARKET_NOT_TAXED_AND_EXPORT,
  label: '55=Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'
}, {
  value: CREDIT_INTERN_MARKET_TAXED_AND_NOT_AND_EXPORT,
  label: '56=Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'
}, {
  value: PRESUMED_CREDIT_INTERN_MARKET_TAXED,
  label: '60=Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno'
}, {
  value: PRESUMED_CREDIT_INTERN_MARKET_NOT_TAXED,
  label: '61=Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno'
}, {
  value: PRESUMED_CREDIT_EXPORT,
  label: '62=Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação'
}, {
  value: PRESUMED_CREDIT_INTERN_MARKET_TAXED_AND_NOT,
  label: '63=Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno'
}, {
  value: PRESUMED_CREDIT_INTERN_MARKET_TAXED_AND_EXPORT,
  label: '64=Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'
}, {
  value: PRESUMED_CREDIT_INTERN_MARKET_NOT_TAXED_AND_EXPORT,
  label: '65=Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'
}, {
  value: PRESUMED_CREDIT_INTERN_MARKET_TAXED_AND_NOT_AND_EXPORT,
  label: '66=Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'
}, {
  value: PRESUMED_CREDIT_OTHER,
  label: '67=Crédito Presumido - Outras Operações'
}, {
  value: AQUISITION_WITHOUT_CREDIT,
  label: '70=Operação de Aquisição sem Direito a Crédito'
}, {
  value: AQUISITION_WITH_EXEMPT,
  label: '71=Operação de Aquisição com Isenção'
}, {
  value: AQUISITION_WITH_SUSPENSION,
  label: '72=Operação de Aquisição com Suspensão'
}, {
  value: AQUISITION_ZERO_RATE,
  label: '73=Operação de Aquisição a Alíquota Zero'
}, {
  value: AQUISITION_WITHOUT_INCIDENCE,
  label: '74=Operação de Aquisição; sem Incidência da Contribuição'
}, {
  value: AQUISITION_WITH_TAX_SUBSTITUTION,
  label: '75=Operação de Aquisição por Substituição Tributária'
}, {
  value: OTHER_ENTRY_OPERATIONS,
  label: '98=Outras Operações de Entrada'
}, {
  value: OTHER_OPERATIONS,
  label: '99=Outras Operações'
}];
export const IPI_TAX_SITUATION = [{
  value: ENTRY_WITH_CREDIT_RECOVERY,
  label: '00=Entrada com recuperação de crédito'
}, {
  value: ENTRY_TAXABLE_ZERO_RATE,
  label: '01=Entrada tributada com alíquota zero'
}, {
  value: ENTRY_EXEMPT,
  label: '02=Entrada isenta'
}, {
  value: ENTRY_NOT_TAXABLE,
  label: '03=Entrada não-tributada'
}, {
  value: ENTRY_IMMUNE,
  label: '04=Entrada imune'
}, {
  value: ENTRY_SUSPENSION,
  label: '05=Entrada com suspensão'
}, {
  value: OTHER_ENTRIES,
  label: '49=Outras entradas'
}, {
  value: TAXABLE_EXIT,
  label: '50=Saída tributada'
}, {
  value: EXIT_TAXABLE_ZERO_RATE,
  label: '51=Saída tributada com alíquota zero'
}, {
  value: EXIT_EXEMPT,
  label: '52=Saída isenta'
}, {
  value: EXIT_NOT_TAXABLE,
  label: '53=Saída não-tributada'
}, {
  value: EXIT_IMMUNE,
  label: '54=Saída imune'
}, {
  value: EXIT_SUSPENSION,
  label: '55=Saída com suspensão'
}, {
  value: OTHER_EXITS,
  label: '99=Outras saídas'
}];
export const ISS_CHARGEABILITY_INDICATOR = [{
  value: CHARGEABLE,
  label: 'Exigível'
}, {
  value: NON_INCIDENCE,
  label: 'Não Incidência'
}, {
  value: EXEMPT,
  label: 'Isenção'
}, {
  value: EXPORTATION,
  label: 'Exportação'
}, {
  value: IMMUNE,
  label: 'Imunidade'
}, {
  value: SUSPENDED_BY_JURIDICAL_DECISION,
  label: 'Exibilidade Suspensa por Decisão Judicial'
}, {
  value: SUSPENDED_BY_ADMINISTRATIVE_PROCESS,
  label: 'Exibilidade Suspensa por Processo Administrativo'
}];
export const ISS_TAX_REGIME = [{
  value: MUNICIPAL_MICRO_COMPANY,
  label: 'Microempresa Municipal'
}, {
  value: ESTIMATED,
  label: 'Estimativa'
}, {
  value: PROFESSIONAL_SOCIETY,
  label: 'Sociedade de Profissionais'
}, {
  value: COOPERATIVE,
  label: 'Cooperativa'
}, {
  value: MEI,
  label: 'Microempresário Individual (MEI)'
}, {
  value: ME_EPP,
  label: 'Microempresa ou Empresa de Pequeno Porte (ME/EPP)'
}];
export const ISS_TAX_INCENTIVE = [{
  value: YES,
  label: 'Sim'
}, {
  value: NO,
  label: 'Não'
}];
export const ICMS_TAX_SITUATION = [{
  value: TAXABLE,
  label: '00=Tributada integralmente'
}, {
  value: TAXABLE_BY_SUBSTITUTION,
  label: '10=Tributada e com cobrança do ICMS por substituição tributária'
}, {
  value: TAXABLE_WITH_BASIS_REDUCTION,
  label: '20=Com redução de base de cálculo'
}, {
  value: EXEMPT_WITH_SUBSTITUTION,
  label: '30=Isenta ou não tributada e com cobrança do ICMS por substituição tributária'
}, {
  value: EXEMPT,
  label: '40=Isenta'
}, {
  value: NOT_TAXABLE,
  label: '41=Não tributada'
}, {
  value: SUSPENSION,
  label: '50=Suspensão'
}, {
  value: DEFERRAL,
  label: '51=Diferimento'
}, {
  value: PREVIOUS_TAXABLE_BY_SUBSTITUTION,
  label: '60=ICMS cobrado anteriormente por substituição tributária'
}, {
  value: TAXABLE_WITH_BASIS_REDUCTION_AND_SUBSTITUTION,
  label: '70=Com redução de base de cálculo e cobrança do ICMS por substituição tributária'
}, {
  value: OTHERS,
  label: '90=Outros'
}];
export const WAYBILL_ICMS_TAX_SITUATION = [{
  value: TAXABLE,
  label: '00=Tributada integralmente'
}, {
  value: TAXABLE_WITH_BASIS_REDUCTION,
  label: '20=Com redução de base de cálculo'
}, {
  value: EXEMPT,
  label: '40=Isenta'
}, {
  value: NOT_TAXABLE,
  label: '41=Não tributada'
}, {
  value: DEFERRAL,
  label: '51=Diferimento'
}, {
  value: PREVIOUS_TAXABLE_BY_SUBSTITUTION,
  label: '60=ICMS cobrado anteriormente por substituição tributária'
}, {
  value: OTHERS,
  label: '90=Outros'
}];
export const TRANSPORT_SERVICE_ICMS_TAX_SITUATION = [{
  value: TAXABLE,
  label: '00=Tributada integralmente'
}, {
  value: TAXABLE_WITH_BASIS_REDUCTION,
  label: '20=Com redução de base de cálculo'
}, {
  value: EXEMPT,
  label: '40=Isenta'
}, {
  value: NOT_TAXABLE,
  label: '41=Não tributada'
}, {
  value: DEFERRAL,
  label: '51=Diferimento'
}, {
  value: PREVIOUS_TAXABLE_BY_SUBSTITUTION,
  label: '60=ICMS cobrado anteriormente por substituição tributária'
}, {
  value: OTHERS,
  label: '90=Outros'
}];
export const FINAL_COSTUMER_METHOD_NFCE_ICMS_TAX_SITUATION = [{
  value: TAXABLE,
  label: '00=Tributada integralmente'
}, {
  value: TAXABLE_WITH_BASIS_REDUCTION,
  label: '20=Com redução de base de cálculo'
}, {
  value: EXEMPT,
  label: '40=Isenta'
}, {
  value: NOT_TAXABLE,
  label: '41=Não tributada'
}, {
  value: PREVIOUS_TAXABLE_BY_SUBSTITUTION,
  label: '60=ICMS cobrado anteriormente por substituição tributária'
}, {
  value: OTHERS,
  label: '90=Outros'
}];
export const TAX_BENEFIT_TAX_SITUATION = [{
  value: TAXABLE_WITH_BASIS_REDUCTION,
  label: '20=Com redução de base de cálculo'
}, {
  value: EXEMPT_WITH_SUBSTITUTION,
  label: '30=Isenta ou não tributada e com cobrança do ICMS por substituição tributária'
}, {
  value: EXEMPT,
  label: '40=Isenta'
}, {
  value: NOT_TAXABLE,
  label: '41=Não tributada'
}, {
  value: SUSPENSION,
  label: '50=Suspensão'
}, {
  value: DEFERRAL,
  label: '51=Diferimento'
}, {
  value: TAXABLE_WITH_BASIS_REDUCTION_AND_SUBSTITUTION,
  label: '70=Com redução de base de cálculo e cobrança do ICMS por substituição tributária'
}, {
  value: OTHERS,
  label: '90=Outros'
}];
export const GOODS_ORIGIN = [{
  value: NATIONAL_EXCEPT_3_4_5_8,
  label: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8'
}, {
  value: FOREIGN_EXCEPT_6,
  label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6'
}, {
  value: FOREIGN_EXCEPT_7,
  label: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7'
}, {
  value: NATIONAL_IMPORT_CONTENT_BETWEEN_40_70,
  label: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%'
}, {
  value: NATIONAL_ACCORDANCE_WITH_LAW,
  label: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes'
}, {
  value: NATIONAL_IMPORT_CONTENT_UNTIL_40,
  label: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%'
}, {
  value: FOREIGN_IMPORT_CAMEX_LIST,
  label: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural'
}, {
  value: FOREIGN_NATIONAL_CAMEX_LIST,
  label: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural'
}, {
  value: NATIONAL_IMPORT_CONTENT_ABOVE_70,
  label: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%'
}];
export const ICMS_METHOD_BASIS = [{
  value: MVA,
  label: '0=Margem Valor Agregado (%)'
}, {
  value: VALUE,
  label: '1=Pauta (Valor)'
}, {
  value: MAX_FIXED_PRICE,
  label: '2=Preço Tabelado Máx. (valor)'
}, {
  value: OPERATION_VALUE,
  label: '3=Valor da operação'
}];
export const ICMS_METHOD_BASIS_ST = [{
  value: FIXED_PRICE_ST,
  label: '0=Preço tabelado ou máximo sugerido'
}, {
  value: NEGATIVE_LIST,
  label: '1=Lista Negativa (valor)'
}, {
  value: POSITIVE_LIST,
  label: '2=Lista Positiva (valor)'
}, {
  value: NEUTRAL_LIST,
  label: '3=Lista Neutra (valor)'
}, {
  value: MVA_ST,
  label: '4=Margem Valor Agregado (%)'
}, {
  value: VALUE_ST,
  label: '5=Pauta (valor)'
}, {
  value: OPERATION_VALUE_ST,
  label: '6 = Valor da Operação'
}];
export const ICMS_UNENCUMBERED_REASON = [{
  value: TAXI,
  label: '1=Táxi'
}, {
  value: AGRICULTURE,
  label: '3=Uso na agropecuária'
}, {
  value: RENTAL_COMPANY,
  label: '4=Frotista/Locadora'
}, {
  value: DIPLOMATIC,
  label: '5=Diplomático/Consular'
}, {
  value: AMAZON_VEHICLES,
  label: '6=Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio (Resolução 714/88 e 790/94 – CONTRAN e suas alterações)'
}, {
  value: SUFRAMA,
  label: '7=SUFRAMA'
}, {
  value: SALE_TO_PUBLIC_AGENCY,
  label: '8=Venda a Órgão Público'
}, {
  value: OTHERS_REASON,
  label: '9=Outros'
}, {
  value: DISABLE_DRIVER,
  label: '10=Deficiente Condutor (Convênio ICMS 38/12)'
}, {
  value: DISABLE_NON_DRIVER,
  label: '11=Deficiente Não Condutor (Convênio ICMS 38/12)'
}, {
  value: AGRICULTURAL_DEVELOPMENT_AGENCY,
  label: '12=Órgão de fomento e desenvolvimento agropecuário'
}, {
  value: OLYMPICS_2016,
  label: '16=Olimpíadas Rio 2016 (NT 2015.002)'
}, {
  value: FISCO,
  label: '90=Solicitado pelo Fisco'
}];
export const CFOP_DESTINATION = [{
  value: INSIDE_STATE,
  label: 'Dentro do Estado'
}, {
  value: OUTSIDE_STATE,
  label: 'Fora do Estado'
}, {
  value: FOREIGN,
  label: 'Exterior'
}];
export const CFOP_USAGE = [{
  value: NORMAL,
  label: 'Normal'
}, {
  value: COMMUNICATION,
  label: 'Comunicação'
}, {
  value: DEVOLUTION,
  label: 'Devolução'
}, {
  value: REMITTANCE,
  label: 'Remessa'
}, {
  value: RETURN,
  label: 'Retorno'
}, {
  value: TRANSPORT,
  label: 'Transporte'
}, {
  value: ANNULMENT,
  label: 'Anulação de valor'
}];
export const DFE_USAGE = [{
  value: ANY_DOCUMENT,
  label: 'Qualquer documento'
}, {
  value: ANY_DOCUMENT_EXCEPT_NFCE,
  label: 'Qualquer documento exceto NFC-e'
}];
export const CFOP_FUEL_USAGE = [{
  value: NO_FUEL,
  label: 'Não'
}, {
  value: OPTIONAL_FREIGHTER,
  label: 'Sim, transportador opcional'
}, {
  value: MANDATORY_FREIGHTER,
  label: 'Sim, transportador obrigatório'
}];
export const BUYER_PRESENCE_INDICATOR = [{
  value: IN_PERSON,
  label: 'Operação presencial'
}, {
  value: IN_PERSON_OUTSIDE_COMPANY,
  label: 'Operação presencial, fora do estabelecimento'
}, {
  value: NOT_IN_PERSON_BY_INTERNET,
  label: 'Operação não presencial, pela Internet'
}, {
  value: NOT_IN_PERSON_CALL_CENTER,
  label: 'Operação não presencial, Teleatendimento'
}, {
  value: NOT_IN_PERSON_OTHERS,
  label: 'Operação não presencial, outros'
}, {
  value: DOES_NOT_APPLY,
  label: 'Não se aplica (por exemplo, Nota Fiscal complementar ou de ajuste)'
}, {
  value: NFCE_WITH_HOME_DELIVERY,
  label: 'NFC-e em operação com entrega a domicílio',
  disabled: true
}];
export const BROKER_INDICATOR = [{
  value: WITHOUT_BROKER,
  label: 'Operação sem intermediador (em site ou plataforma própria)'
}, {
  value: SITE_OR_THIRD_PARTY_PLATFORM,
  label: 'Operação em site ou plataforma de terceiros'
}];
export const TOTAL_INVOICE_METHOD = [{
  value: DONT_MAKE_UP_TOTAL,
  label: 'Não compõe total da NF-e'
}, {
  value: MAKE_UP_TOTAL,
  label: 'Compõe total da NF-e'
}];