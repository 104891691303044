import gql from "graphql-tag";
import { personFiscalInfoFragment } from "./PersonFiscalInfo";
import { personCnaeFragment } from "./PersonCnae";
import { personFiscalStFragment } from "./PersonFiscalSt";
import { personFiscalStartFragment } from "./PersonFiscalStart";
import { addressFragment, phoneFragment, userStoreSimpleFragment, contactFragment } from 'admin-front';
import { stateRegistrationFragment } from "./StateRegistration";
import { certificateFragment } from "./Certificate";
import { personDocumentFragment } from "./PersonDocument";
import { personBankingFragment } from "financial-front";
export const fiscalPersonFragment = gql`
  fragment FiscalPerson on FiscalPerson {
    id,
    name,
    tradeName,
    document,
    naturalRegistration,
    naturalRegistrationStateId,
    naturalRegistrationAgencyId,
    governmentInstitution, 
    photo64,
    email,
    gender,
    birth,
    closure,
    cadunicoCode,
    nisCode,
    nickname, 
    pisCode, 
    ctps, 
    voterRegistrationNumber, 
    voterRegistrationZone, 
    voterRegistrationSection, 
    nationalityId, 
    citizenshipId, 
    inss, 
    incomeId,
    lowIncome, 
    dependents, 
    occupationCode, 
    jobRoleId,
    discardedAt,
    contributeIcms,
    municipalRegistration,
    suframaRegistration,
    taxRegime,
    nfceToken,
    nfceId,
    environment,
    issueMode,
    producer,
    stateRegistration,
    stateRegistrations {
      ...StateRegistration
    },
    juridical,
    natural,    
    mainAddress {
      ...Address
    },
    addresses {
      ...Address
    },
    phone {
      ...Phone
    },
    phones {
      ...Phone
    },    
    user {
      ...UserStoreSimple
    },
    ofCompany {
      id,
    }
    contacts {
      ...Contact
    }
    cnaes {
      ...PersonCnae
    },
    sts {
      ...PersonFiscalSt
    },
    infos {
      ...PersonFiscalInfo
    },
    currentInfos {
      ...PersonFiscalInfo
    },    
    fiscalStarts {
      ...PersonFiscalStart
    },
    certificate {
      ...Certificate
    },
    personBankings {
      ...PersonBanking
    },
    personDocuments {
      ...FiscalPersonDocument
    }
  }
  ${addressFragment}
  ${phoneFragment}
  ${userStoreSimpleFragment}
  ${contactFragment}
  ${personCnaeFragment}
  ${personFiscalStFragment}
  ${personFiscalInfoFragment}
  ${personFiscalStartFragment}
  ${stateRegistrationFragment}
  ${certificateFragment}
  ${personBankingFragment}
  ${personDocumentFragment}
`;
export const fiscalPersonToCardFragment = gql`
fragment FiscalPersonToCard on FiscalPerson {
  id
  document
  name
  tradeName
  email
  phone {
    id
    areaCode
    number
    extension
  }
  mainAddress {
    id
    street
    number
    room
    complement
    city{
      id
      cityState
    }
    zipcode
  }
}
`;