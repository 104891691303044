import gql from "graphql-tag";
import { companyFragment, personFragment } from 'admin-front';
import { paymentConditionFragment, paymentMethodFragment } from "financial-front";
import { requestEventFragment } from "./RequestEvent";
import { requestProductUnitFragment } from "./RequestProductUnit";
export const requestFragment = gql`
  fragment Request on Request  { 
    id,
    transactionId,
    paymentCondition {
      ...PaymentCondition
    },
    paymentMethod {
      ...PaymentMethod
    },
    pickupCompany{
      ...Company
    },
    currentEvent {
      ...RequestEvent
    },
    person{
      ...Person
    },
    canEdit,
    invoiceNumber,
    companyId,
    canCancel,
    canCompanyCancel,
    changeRequested,
    canSendToReview,
    canOrder,
    hasOrdered,
    requestProductUnits {
      ...RequestProductUnit
    }
  }
  ${requestProductUnitFragment}
  ${paymentConditionFragment}
  ${paymentMethodFragment}
  ${companyFragment}
  ${personFragment}
  ${requestEventFragment}
`;