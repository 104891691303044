import { MAIN } from 'admin-front';
import { dateOrToday, sanitizeBackend, sanitize } from 'grifo-components';
import { trailerAttributesBuilder, trailerDefaultBuilder } from "./TrailerBuilder";
import { vehicleAttributesBuilder, vehicleDefaultBuilder } from "./VehicleBuilder";
export const organizationMemberDriverParamBuilder = (record, values) => {
  let params = sanitize(values, ["city", "contact"]);
  if (params.phone) params.phone.naming = "main";
  if (params.address) params.address.naming = MAIN;
  let memberRegistration = {
    id: (((record || {}).person || {}).memberRegistration || {}).id ? record.person.memberRegistration.id : null,
    registrationCode: values.registrationCode,
    observation: values.observation,
    start: values.start,
    finish: values.finish,
    personId: values.personId
  };
  let driver = {
    id: record ? record.id : null,
    personId: values.personId,
    person: values.person
  };
  return {
    attributes: {
      driver: driver,
      memberRegistration: memberRegistration
    }
  };
};
export const organizationMemberDriverBuilder = record => {
  let data = record || {};
  let member = (data.person || {}).memberRegistration || {};
  const start = member.start;
  const finish = member.finish;
  return {
    person: data.person,
    personId: (data.person || {}).id,
    phone: data.phone,
    address: data.address,
    name: (data.person || {}).name,
    tradeName: (data.person || {}).tradeName,
    document: (data.person || {}).document,
    driverVehicles: (data.driverVehicles || []).map(object => vehicleDefaultBuilder(object)),
    organizationMemberId: member.id,
    registrationCode: member.registrationCode,
    observation: member.observation,
    operative: member.operative,
    start: dateOrToday(start),
    finish: sanitizeBackend(finish),
    photo64: (data.person || {} || {}).photo64,
    email: (data.person || {} || {}).email,
    phoneNumber: ((data.person || {} || {}).phone || {}).number,
    phoneAreaCode: ((data.person || {} || {}).phone || {}).areaCode,
    phoneExtension: ((data.person || {} || {}).phone || {}).extension
  };
};