import gql from "graphql-tag";
import { InvoiceItemIcmsFragment } from "../fragments/InvoiceItemIcms";
export const InvoiceItemIcmsCalculationFragment = gql`
  fragment InvoiceItemIcmsCalculation on InvoiceItemIcmsCalculation  { 
    errors,    
    icms {
      ...InvoiceItemIcms
    }
  }  
  ${InvoiceItemIcmsFragment}  
`;