import gql from "graphql-tag";
export const printConfigFragment = gql`
  fragment PrintConfig on PrintConfig {
    id,
    name,
    quantity,
    width,
    height,
    fontSize,
    quantity,
    columns,
    marginTop,
    marginRight,
    showCode,
    default,
    discardedAt
  }
`;