import { OUTSIDE_STATE } from "../constants/invoices/InvoiceFieldsOptions";
import { bind } from 'grifo-components';
import { NOT_TAXABLE, PREVIOUS_TAXABLE_BY_SUBSTITUTION } from "../constants/taxProfile/TaxFieldsOptions";
export const calculatePIS = (data, form, indexKey) => {
  if (!data) return;
  let result = data;
  let path = ['items', indexKey, 'pis'];
  if (result.errors) {
    if (!result.status) return;
    bind(form, undefined, path);
    return;
  }
  let values = [{
    'taxSituation': result.taxSituation
  }, {
    'basis': result.basis
  }, {
    'rate': result.rate
  }, {
    'quantity': result.quantity
  }, {
    'rateByValue': result.rateByValue
  }, {
    'value': result.value
  }, {
    'taxProfileId': result.taxProfile?.id
  }, {
    'basisReductionPercentage': result.basisReductionPercentage
  }];
  bind(form, values, path);
};
export const calculateIPI = (data, form, indexKey) => {
  if (!data) return;
  let result = data.ipi || {};
  let path = ['items', indexKey, 'ipi'];
  if (!result) return;
  let values = [{
    'ipiLegalFrameworkId': result.ipiLegalFrameworkId
  }, {
    'taxSituation': result.taxSituation
  }, {
    'basis': result.basis || 0
  }, {
    'rate': result.rate || 0
  }, {
    'quantity': result.quantity || 0
  }, {
    'rateByValue': result.rateByValue || 0
  }, {
    'value': result.value || 0
  }, {
    'taxProfileId': (result.taxProfile || {}).id
  }];
  bind(form, values, path);
};
export const calculateCOFINS = (data, form, indexKey) => {
  if (!data) return;
  let result = data;
  let path = ['items', indexKey, 'cofins'];
  let values = [{
    'taxSituation': result.taxSituation
  }, {
    'basis': result.basis
  }, {
    'rate': result.rate
  }, {
    'quantity': result.quantity
  }, {
    'rateByValue': result.rateByValue
  }, {
    'value': result.value
  }, {
    'taxProfileId': result.taxProfile?.id
  }, {
    'basisReductionPercentage': result.basisReductionPercentage
  }];
  bind(form, values, path);
};
export const calculateICMS = (data, form, indexKey, operationDestination, taxes = undefined) => {
  if (!data) return;
  let result = data;
  if (!result) return;
  if (result.errors) {
    bind(form, undefined, path);
    return;
  }
  let path = ['items', indexKey, 'icms'];
  let values = [{
    'goodsOrigin': result.goodsOrigin
  }, {
    'taxSituation': result.taxSituation
  }, {
    'methodBasis': result.methodBasis
  }, {
    'basisReductionPercentage': result.basisReductionPercentage
  }, {
    'basis': result.basis
  }, {
    'rate': result.rate
  }, {
    'value': result.value
  }, {
    'fcpBasis': result.fcpBasis
  }, {
    'fcpRate': result.fcpRate
  }, {
    'fcpValue': result.fcpValue
  }, {
    'methodBasisSt': result.methodBasisSt
  }, {
    'mvaRate': result.mvaRate
  }, {
    'basisReductionPercentageSt': result.basisReductionPercentageSt
  }, {
    'basisSt': result.basisSt
  }, {
    'rateSt': result.rateSt
  }, {
    'valueSt': result.valueSt
  }, {
    'fcpBasisSt': result.fcpBasisSt
  }, {
    'fcpRateSt': result.fcpRateSt
  }, {
    'fcpValueSt': result.fcpValueSt
  }, {
    'unencumberedRate': result.unencumberedRate
  }, {
    'unencumberedBasisReduction': result.unencumberedBasisReduction
  }, {
    'unencumberedBasis': result.unencumberedBasis
  }, {
    'unencumberedValue': result.unencumberedValue
  }, {
    'unencumberedReason': result.unencumberedReason
  }, {
    'deferralRate': result.deferralRate
  }, {
    'deferralValue': result.deferralValue
  }, {
    'valueWithDeferral': result.valueWithDeferral
  }, {
    'taxProfileId': (result.taxProfile || {}).id
  }, {
    'taxProfileName': (result.taxProfile || {}).name
  }, {
    'cestId': result?.cestId || null
  }, {
    'taxBenefitCodeId': result?.taxBenefitCodeId || null
  }, {
    'ncmIds': result?.ncmIds || []
  }];
  bind(form, values, path);
  if (result.taxProfile) {
    let cfop = (result.taxProfile.cfops.filter(cfop => cfop.destination === operationDestination) || [])[0];
    bind(form, [{
      'cfopId': cfop?.cfopId
    }], ['items', indexKey]);
  }
  if ([NOT_TAXABLE, PREVIOUS_TAXABLE_BY_SUBSTITUTION].includes(result.taxSituation) && form.getFieldsValue().operationDestination === OUTSIDE_STATE) {
    if (!result) return;
    calcICMSTransfer(result, form, indexKey);
  }
  if (form.getFieldsValue().items[indexKey].cfopId && taxes) {
    calculateICMSDestination(taxes?.icmsDestination, form, indexKey);
    calculateICMSShared(taxes?.icmsShared, form, indexKey);
  }
};
const calcICMSTransfer = (icms, form, indexKey) => {
  if (!icms) return;
  let path = ['items', indexKey, 'icmsTransfer'];
  let values = [{
    'goodsOrigin': icms.goodsOrigin
  }, {
    'taxSituation': icms.taxSituation
  }];
  bind(form, values, path);
};
export const calculateICMSDestination = (data, form, indexKey) => {
  if (!data) return;
  let icms = data;
  if (!icms) return;
  let path = ['items', indexKey, 'icmsDestination'];
  let values = [{
    'basis': icms.basis || 0
  }, {
    'rate': icms.rate || 0
  }, {
    'interstateRate': icms.interstateRate || 0
  }, {
    'provisionalRate': icms.provisionalRate || 0
  }, {
    'value': icms.value || 0
  }, {
    'originValue': icms.originValue || 0
  }, {
    'fcpBasis': icms.fcpBasis || 0
  }, {
    'fcpRate': icms.fcpRate || 0
  }, {
    'fcpValue': icms.fcpValue || 0
  }];
  bind(form, values, path);
};
export const calculateICMSShared = (data, form, indexKey) => {
  if (!data) return;
  let icms = data.invoiceItemIcmsSharedCalc;
  if (!icms) return;
  let path = ['items', indexKey, 'icmsShared'];
  let values = [{
    'stateId': icms.stateId
  }, {
    'goodsOrigin': icms.goodsOrigin
  }, {
    'taxSituation': icms.taxSituation
  }, {
    'methodBasis': icms.methodBasis
  }, {
    'basis': icms.basis || 0
  }, {
    'value': icms.value || 0
  }, {
    'basisReductionPercentage': icms.basisReductionPercentage || 0
  }, {
    'rate': icms.rate || 0
  }, {
    'methodBasisSt': icms.methodBasisSt || 0
  }, {
    'mvaRate': icms.mvaRate || 0
  }, {
    'basisReductionPercentageSt': icms.basisReductionPercentageSt || 0
  }, {
    'basisSt': icms.basisSt || 0
  }, {
    'rateSt': icms.rateSt || 0
  }, {
    'valueSt': icms.valueSt || 0
  }];
  bind(form, values, path);
};