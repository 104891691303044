import gql from "graphql-tag";
import { fuelPumpBrandFragment } from "../fragments/FuelPumpBrand";
export const GQL_FUEL_PUMP_BRAND_STORE = gql`
  mutation StoreFuelPumpBrand($attributes: [FuelPumpBrandAttributes!], $fuelPumpId: ID!) {
    storeFuelPumpBrand(input: {attributes: $attributes, fuelPumpId: $fuelPumpId}) { 
      fuelPumpBrands {
        ...FuelPumpBrand
      }, 
      errors 
    } 
  }
  ${fuelPumpBrandFragment}   
`;