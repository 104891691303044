import { STOCK, CONFIG } from 'grifo-components';
export const buildFeatures = () => {
  const stockPath = "/" + STOCK;
  const stockConfigPath = stockPath + '/' + CONFIG;
  const stockConfigRoutes = [{
    ordination: '03.05.01',
    name: 'Operações',
    key: stockConfigPath + "/operations",
    routePath: stockConfigPath + "/operations",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "StockOperationsPage",
      import_path: "containers/operations/OperationsPage"
    }
  }, {
    ordination: '03.05.02',
    name: 'Unidades comerciais',
    key: stockConfigPath + "/commercial_units",
    routePath: stockConfigPath + "/commercial_units",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "CommercialUnitsPage",
      import_path: "containers/commercialUnits/CommercialUnitsPage"
    }
  }, {
    ordination: '03.05.03',
    name: 'Grupos',
    key: stockConfigPath + "/groups",
    routePath: stockConfigPath + "/groups",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "GroupsPage",
      import_path: "containers/groups/GroupsPage"
    }
  }, {
    ordination: '03.05.04',
    name: 'Locais',
    key: stockConfigPath + "/locations",
    routePath: stockConfigPath + "/locations",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "LocationsPage",
      import_path: "containers/locations/LocationsPage"
    }
  }, {
    ordination: '03.05.05',
    name: 'Marcas',
    key: stockConfigPath + "/brands",
    routePath: stockConfigPath + "/brands",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "BrandsPage",
      import_path: "containers/brands/BrandsPage"
    }
  }, {
    ordination: '03.05.06',
    name: 'Tipos de embalagens',
    key: stockConfigPath + "/packing_kinds",
    routePath: stockConfigPath + "/packing_kinds",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "PackingKindsPage",
      import_path: "containers/packingKinds/PackingKindsPage"
    }
  }, {
    ordination: '03.05.07',
    name: 'Bombas de combustível',
    key: stockConfigPath + "/fuel_pumps",
    routePath: stockConfigPath + "/fuel_pumps",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "PackingKindsPage",
      import_path: "containers/packingKinds/PackingKindsPage"
    },
    contentProp: {
      fuelPump: true
    },
    dividerAbove: true
  }];
  const routes = [{
    ordination: '03.01',
    name: 'Dashboard',
    key: stockPath + "/dashboard",
    routePath: stockPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardStockPage",
      import_path: "containers/Dashboard"
    }
  }, {
    ordination: '03.02',
    name: 'Análise de estoque',
    key: stockPath + "/stock_analysis",
    routePath: stockPath + "/stock_analysis",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "StockAnalysisPage",
      import_path: "containers/stockAnalysis/stockAnalysisPage"
    }
  }, {
    ordination: '03.03',
    name: 'Movimentações',
    key: stockPath + "/movements",
    routePath: stockPath + "/movements",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "MovementsPage",
      import_path: "containers/movements/MovementsPage"
    },
    dividerAbove: true
  }, {
    ordination: '03.04',
    name: 'Produtos',
    key: stockPath + "/products",
    routePath: stockPath + "/products",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ProductsPage",
      import_path: "containers/products/ProductsPage"
    }
  }, {
    ordination: '03.05',
    name: 'Configurações',
    key: stockConfigPath,
    routePath: stockConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: stockConfigRoutes,
    dividerAbove: true
  }];
  return {
    ordination: '03',
    name: 'Estoque',
    key: stockPath,
    kind: 'module',
    status: 'active',
    routePath: stockPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'stock',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};