import { NATIONAL_EXCEPT_3_4_5_8 } from "../constants/taxProfile/TaxFieldsOptions";
import { MERCHANDISE_FOR_SALE, PRODUCED_ON_A_NON_RELEVANT_SCALE } from "../constants/products/FiscalProductsFieldsOptions";
import { productDefaultBuilder, productAtributesBuilder } from 'stock-front';
export const fiscalProductParamBuilder = (record, values) => {
  return {
    attributes: Object.assign({}, productAtributesBuilder(record, values), {
      kind: values.kind,
      goodsOrigin: values.goodsOrigin,
      productionScale: values.productionScale,
      destinationType: values.destinationType,
      ipiExceptionCode: values.ipiExceptionCode,
      nveCode: values.nveCode,
      codifCode: values.codifCode,
      anpProductDescription: values.anpProductDescription,
      anpCode: values.anpCode,
      glgnPercentage: values.glgnPercentage,
      glpPercentage: values.glpPercentage,
      importedGlgnPercentage: values.importedGlgnPercentage,
      recopiCode: values.recopiCode,
      productInfos: (values.productInfos || []).map(object => ({
        ...object,
        productId: (record || {}).id
      }))
    })
  };
};
export const fiscalProductDefaultBuilder = record => {
  return Object.assign({}, productDefaultBuilder(record), {
    kind: (record || {}).kind || MERCHANDISE_FOR_SALE,
    goodsOrigin: (record || {}).goodsOrigin || NATIONAL_EXCEPT_3_4_5_8,
    productionScale: (record || {}).productionScale || PRODUCED_ON_A_NON_RELEVANT_SCALE,
    destinationType: (record || {}).destinationType,
    ipiExceptionCode: (record || {}).ipiExceptionCode,
    nveCode: (record || {}).nveCode,
    codifCode: (record || {}).codifCode,
    anpProductDescription: (record || {}).anpProductDescription,
    anpCode: (record || {}).anpCode,
    glgnPercentage: (record || {}).glgnPercentage,
    importedGlgnPercentage: (record || {}).importedGlgnPercentage,
    glpPercentage: (record || {}).glpPercentage,
    recopiCode: (record || {}).recopiCode,
    productInfos: (record || {}).productInfos
  });
};