import gql from "graphql-tag";
import { fuelPumpFragment } from "../fragments/FuelPump";
export const GQL_FUEL_PUMPS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    fuelPumpsPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FuelPump
        }
      }
    }    
  }
  ${fuelPumpFragment}
`;
export const GQL_FUEL_PUMP_STORE = gql`
  mutation StoreFuelPump($attributes: FuelPumpAttributes!) {
    storeFuelPump(input: {attributes: $attributes}) { 
      fuelPump {
        ...FuelPump
      }, 
      errors 
    } 
  }
  ${fuelPumpFragment}   
`;
export const GQL_FUEL_PUMP_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Stock::FuelPump"}) {
      id,
      discardedAt
    }
  }
`;