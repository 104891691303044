import gql from "graphql-tag";
import { fiscalStockAnalysisFragment } from "../fragments/FiscalStockAnalysis";
export const GQL_FISCAL_STOCK_ANALYSIS = gql`
  query stockAnalysis($pageSize: Int, $cursor: String, $filter: FilterAnalysisProductUnit) {    
    stockAnalysisPaginated(first: $pageSize, after: $cursor, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FiscalStockAnalysis
        }
      }
    }    
  }
  ${fiscalStockAnalysisFragment}
`;