import gql from "graphql-tag";
import { cnaeFragment } from "../fragments/Cnae";
export const GQL_CNAE_SELECTION = gql`
  {
    cnaes {
      ...Cnae,
    }    
  }
  ${cnaeFragment}
`;
export const GQL_CNAE_LIST = gql`
  query Search($pageSize: Int, $cursor: String, $cnaeFilter: CnaeFilter) {    
    cnaesPaginated(first: $pageSize, after: $cursor, cnaeFilter: $cnaeFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Cnae
        }
      }
    }    
  }
  ${cnaeFragment}
`;
export const GQL_CNAE_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Fiscal::Cnae"}) {
      id,
      discardedAt 
    }
  }
`;
export const GQL_CNAE_STORE = gql`
  mutation StoreCnae($attributes: CnaeAttributes!) {
    storeCnae(input: {attributes: $attributes}) { 
      cnae { 
        ...Cnae,
      }, 
      errors 
    } 
  }
  ${cnaeFragment}  
`;
export const GQL_CNAE_ID_SEARCH = gql`
  query Get($id: ID) {    
    cnaeSearch(id: $id) {
      ...Cnae
    }
  }
  ${cnaeFragment}   
`;
export const GQL_ALL_CNAE_SEARCH = gql`
  query Get($text: String) {    
    cnaeSearch(all: $text) {
      ...Cnae
    }
  }
  ${cnaeFragment}    
`;