export const documentsThroughEmailParamBuilder = (record, values) => {
  return {
    attributes: {
      emails: values.emails.map(emailObj => emailObj.email),
      subject: values.subject,
      complement: values.complement,
      dfeId: record?.electronicDocument?.id
    }
  };
};
export const documentsThroughEmailDefaultBuilder = (record, values) => {
  return {
    emails: record?.person?.email ? [{
      email: record.person.email
    }] : [{
      email: ''
    }],
    subject: "Nota fiscal número " + record?.dfeNumber + ", série " + record?.dfeSeries,
    complement: "Segue em anexo os documentos."
  };
};