import gql from "graphql-tag";
import { printConfigFragment } from "../fragments/PrintConfig";
export const GQL_PRINT_CONFIG = gql`
  query PrintConfig {    
    printConfig {
      ...PrintConfig
    }
  }
  ${printConfigFragment}  
`;
export const GQL_PRINT_CONFIG_STORE = gql`
  mutation StorePrintConfig($attributes: PrintConfigAttributes!) {
    storePrintConfig(input: {attributes: $attributes}) { 
      printConfig {
        ...PrintConfig
      }, 
      errors 
    } 
  }
  ${printConfigFragment}   
`;
export const GQL_PRINT_CONFIG_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Fiscal::PrintConfig"}) {
      id,
      discardedAt
    }
  }
`;