import { CURRENCY_FORMAT, DATE_FORMAT, FLOAT_FORMAT, STRING_FORMAT } from "../../constants/Charts";
import { simpleDateFormat } from "../DateUtil";
import { formatFloat } from "../FloatUtil";
import { format as formatCurrency } from '../CurrencyUtil';
import { getLabelByValue } from "../ListUtil";
import { RED_BAR } from "../../constants/Colors";
export const buildVisualMapOptions = (data, valueField, valueDataType, config = {
  colorLow: '#D7E7C5',
  colorHigh: '#5D8038',
  seriesIndex: undefined
}) => {
  const max = data.length > 0 ? Math.max(...data.map(item => item[valueField])) : 0;
  let opts = {
    visualMap: {
      min: 0,
      max: max,
      type: 'piecewise',
      left: 'center',
      top: 70,
      inRange: {
        color: [config.colorLow || '#D7E7C5', config.colorHigh || '#5D8038']
      },
      orient: 'horizontal',
      showLabel: true,
      formatter: function (value, value2) {
        return formatter(value, valueDataType) + ' - ' + formatter(value2, valueDataType);
      }
    }
  };
  return config.seriesIndex ? {
    visualMap: {
      seriesIndex: config.seriesIndex,
      ...opts.visualMap
    }
  } : opts;
};
export const buildMarkLine = (markLineData, valueDataType, color = RED_BAR) => {
  let options = undefined;
  if (markLineData?.length > 0) options = {
    data: markLineData,
    label: {
      position: 'insideStartTop',
      formatter: (params, index) => '{a|' + formatter(params.value, valueDataType, params.name + ': ') + '}',
      rich: {
        a: {
          color: color,
          lineHeight: 10
        }
      }
    },
    lineStyle: {
      color: RED_BAR
    }
  };
  return options;
};
export const formatter = (value, dataType = FLOAT_FORMAT, prefix = '', dictionary = undefined, suffix = '') => {
  return (prefix !== '' ? prefix + ' ' : '') + (typeof value === STRING_FORMAT ? dictionary ? getLabelByValue(dictionary, value) : value : dataType === FLOAT_FORMAT ? formatFloat(value, 4) : dataType === CURRENCY_FORMAT ? formatCurrency(value) : dataType === DATE_FORMAT ? simpleDateFormat(value) : value) + (suffix !== '' ? ' ' + suffix : '');
};