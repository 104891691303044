import gql from "graphql-tag";
import { groupSimpleFragment } from "./GroupSimple";
export const groupFragment = gql`
  fragment Group on Group  {
    ...GroupSimple
    parent {
      ...GroupSimple
    },
  }
  ${groupSimpleFragment}
`;