import gql from "graphql-tag";
export const taxProfileIssFragment = gql`
  fragment TaxProfileIss on TaxProfileIss  { 
    id,
    rate,
    basisReductionPercentage,
    kind,
    taxIncentive,
    chargeabilityIndicator,
    specialTaxRegime,
    suspendedChargeCourtCaseNumber,
    continuousSupplyCode,
  }
`;