import gql from "graphql-tag";
import { personFragment } from 'admin-front';
import { productModelFragment } from "./ProductModel";
export const brandFragment = gql`
  fragment Brand on Brand  {
    id,
    name,
    discardedAt,
    person {
      ...Person
    },
    productModels {
      ...ProductModel
    },
  }
  ${personFragment}
  ${productModelFragment}
`;