import { personAtributesBuilder, personDefaultBuilder } from 'admin-front';
import { sanitizeBackend } from 'grifo-components';
export const customerParamBuilder = (record, values) => {
  return {
    attributes: Object.assign({}, personAtributesBuilder(record, values), {
      preferredDrivers: values.preferredDrivers,
      personBankings: (values?.personBankings || []).map(object => {
        return {
          id: object.id,
          bankId: object.bankId,
          branch: object.branch,
          description: object.description,
          kind: object.kind || 'checking_account',
          number: object.number
        };
      })
    })
  };
};
export const customerDefaultBuilder = record => {
  return Object.assign({}, personDefaultBuilder(record), {
    preferredDrivers: (record || {}).preferredDrivers,
    personBankings: record?.personBankings ? record?.personBankings.map(object => {
      return {
        ...object,
        implantationDate: sanitizeBackend(object.implantationDate)
      };
    }) : undefined
  });
};