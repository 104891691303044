export const REAL_PROFIT = 'real_profit';
export const PRESUMED_PROFIT = 'presumed_profit';
export const IMMUNE = 'immune';
export const NATIONAL_SIMPLE = 'national_simple';
export const HOMOLOGATION = 'homologation';
export const PRODUCTION = 'production';
export const ICMS_TAXPAYER = 'icms_taxpayer';
export const ICMS_TAXPAYER_EXEMPT = 'icms_taxpayer_exempt';
export const NON_ICMS_TAXPAYER = 'non_icms_taxpayer';
export const NORMAL_PRODUCER = 'normal_producer';
export const RURAL_PRODUCER = 'rural_producer';
export const NORMAL = 'normal';
export const CONTINGENCY_FS_IA = 'contingency_fs_ia';
export const CONTINGENCY_SCAN = 'contingency_scan';
export const CONTINGENCY_DPEC = 'contingency_dpec';
export const CONTINGENCY_FS_DA = 'contingency_fs_da';
export const CONTINGENCY_SVC_AN = 'contingency_svc_an';
export const CONTINGENCY_SVC_RS = 'contingency_svc_rs';
export const TAX_REGIME_OPTIONS = [{
  value: REAL_PROFIT,
  label: 'Lucro Real'
}, {
  value: PRESUMED_PROFIT,
  label: 'Lucro Presumido'
}, {
  value: IMMUNE,
  label: 'Imune'
}, {
  value: NATIONAL_SIMPLE,
  label: 'Simples Nacional'
}];
export const DFE_ISSUE_MODE_OPTIONS = [{
  value: NORMAL,
  label: 'Normal'
}, {
  value: CONTINGENCY_FS_IA,
  label: 'Contingência FS-IA',
  disabled: true
}, {
  value: CONTINGENCY_SCAN,
  label: 'Contingência SCAN',
  disabled: true
}, {
  value: CONTINGENCY_DPEC,
  label: 'Contingência DPEC',
  disabled: true
}, {
  value: CONTINGENCY_FS_DA,
  label: 'Contingência FS-DA',
  disabled: true
}, {
  value: CONTINGENCY_SVC_AN,
  label: 'Contingência SVC-AN'
}, {
  value: CONTINGENCY_SVC_RS,
  label: 'Contingência SVC-RS'
}];
export const DFE_ENVIRONMENT_OPTIONS = [{
  value: HOMOLOGATION,
  label: 'Homologação'
}, {
  value: PRODUCTION,
  label: 'Produção'
}];
export const CONTRIBUTE_ICMS_OPTIONS = [{
  value: ICMS_TAXPAYER,
  label: 'Contribuinte'
}, {
  value: ICMS_TAXPAYER_EXEMPT,
  label: 'ISENTO'
}, {
  value: NON_ICMS_TAXPAYER,
  label: 'Não Contribuinte'
}];
export const PERSON_PRODUCER_OPTIONS = [{
  value: NORMAL_PRODUCER,
  label: 'Normal'
}, {
  value: RURAL_PRODUCER,
  label: 'Rural'
}];