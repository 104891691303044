import gql from "graphql-tag";
import { ownerTrailerFragment } from "./OwnerTrailer";
import { vehicleBodyworkFragment } from "./VehicleBodywork";
export const trailerSimpleFragment = gql`
  fragment TrailerSimple on Trailer  { 
    id,
    licensingStateId,
    vehicleBodyworkId,
    vehicleBodywork {
      ...VehicleBodywork
    }
    plate,
    vin,
    renavamCode,
    manufactureYear,
    modelYear,
    identifier,
    height,
    width,
    length,
    maximumWeight,
    tareWeight,
    volumeCapacity,
    ownerTrailer{
      ...OwnerTrailer
    }
  }
  ${vehicleBodyworkFragment}
  ${ownerTrailerFragment}
`;