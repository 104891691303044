import gql from "graphql-tag";
import { stateFragment } from 'admin-front';
import { taxBenefitCodeFragment } from "./TaxBenefitCode";
export const taxProfileIcmsStateFragment = gql`
  fragment TaxProfileIcmsState on TaxProfileIcmsState  { 
    id,
    stateId,
    taxBenefitCodeId,
    kind,
    rate,
    fcpRate,    
    basisReductionPercentage,
    methodBasis,
    rateSt,
    fcpRateSt,    
    basisReductionPercentageSt,
    methodBasisSt,
    mvaRate,
    unencumberedReason,
    unencumberedRate,
    unencumberedBasisReduction,    
    deferralRate,
    taxBenefitCode {
      ...TaxBenefitCode
    },
    state { 
      ...State
    }  
  }
  ${stateFragment}
  ${taxBenefitCodeFragment}
`;