import gql from "graphql-tag";
import { taxableUnitFragment } from "./TaxableUnit";
import { NcmFragment } from "./Ncm";
import { productUnitTaxBenefitCodeFragment } from "./ProductUnitTaxBenefitCode";
import { commercialUnitFragment, batchFragment, packingKindFragment, productUnitSimpleFragment } from 'stock-front';
export const fiscalProductUnitFragment = gql`
  fragment FiscalProductUnit on FiscalProductUnit  {
    id,
    productId,
    packingKindId,
    commercialUnitId,  
    taxableUnitId,
    parentId,
    ncmId,
    barcode,
    multiplier,
    value,
    grossWeight,
    netWeight,
    height,
    width,
    length,
    minimum,
    maximum,
    code,
    discardedAt,
    productionTracking,
    commercialUnit {
      ...CommercialUnit
    }
    parent {
      ...ProductUnitSimple
    },    
    ncm {
      ...Ncm
    }
    taxableUnit {
      ...TaxableUnit
    }
    batches {
      ...Batch
    }
    packingKind {
      ...PackingKind
    }   
    taxBenefits {
      ...ProductUnitTaxBenefitCode
    } 
  }
  ${commercialUnitFragment}
  ${taxableUnitFragment}
  ${NcmFragment}  
  ${batchFragment}
  ${packingKindFragment}
  ${productUnitSimpleFragment}
  ${productUnitTaxBenefitCodeFragment}
`;