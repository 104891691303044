import gql from "graphql-tag";
export const tradeProductUnitInflationFragment = gql`
  fragment TradeProductUnitInflation on TradeProductUnitInflation {
    id,
    name,
    unit,
    min,
    max,
    first,
    last,
    average,
    invoicesCount,
    date,
  }
`;