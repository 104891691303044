import gql from "graphql-tag";
export const groupSimpleFragment = gql`
  fragment GroupSimple on Group {
    id,
    name,
    ancestry,
    fullPathName,
    parent {
      id
    },
    discardedAt,
  }
`;