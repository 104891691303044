import gql from "graphql-tag";
export const cnaeFragment = gql`
  fragment Cnae on Cnae {
    id,
    division,
    group,
    grade,
    code,
    description,
    discardedAt
  }
`;