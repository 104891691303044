import gql from 'graphql-tag';
export const GQL_IMPORT_XML = gql`
  mutation ImportXml($attributes: XmlImporterAttributes!) {
    importXml(input: { attributes: $attributes }) {
      result,
      products,
      payment,
      total,
      errors
    }
  }
`;