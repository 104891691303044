import { getSessionInfo } from 'grifo-components';
import { FISCO } from "../constants/taxProfile/TaxFieldsOptions";
export const additionalInformationParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      information: values.information,
      target: values.target,
      companyId: getSessionInfo().company.id
    }
  };
};
export const additionalInformationDefaultBuilder = record => {
  const object = record || {};
  return {
    information: object.information,
    target: object.target || FISCO
  };
};