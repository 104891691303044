import gql from "graphql-tag";
export const stockBalanceStatsFragment = gql`
  fragment StockBalanceStats on StockBalanceStats {
    id,
    total,
    withoutBalance,
    withoutBalancePercent,
    withBalance,
    withBalancePercent,
    almostWithoutBalance,
    almostWithoutBalancePercent,    
    balanceLessThanMinimum,
    balanceLessThanMinimumPercent,
    lowBalance,
    lowBalancePercent,
    moderateBalance,
    moderateBalancePercent,
    goodBalance,
    goodBalancePercent,
    balanceMoreThanMaximum,
    balanceMoreThanMaximumPercent,
  }
`;