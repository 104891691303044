import gql from "graphql-tag";
export const taxProfileCofinsFragment = gql`
  fragment TaxProfileCofins on TaxProfileCofins  { 
    id,
    rate,
    rateByValue,
    kind,
    taxSituation,    
    basisReductionPercentage
  }
`;