import moment from 'moment';
export const ipiIncidenceParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      rate: values.rate,
      start: values.start,
      finish: values.finish,
      ncmId: values.ncmId
    }
  };
};
export const ipiIncidenceDefaultBuilder = record => {
  return {
    rate: (record || {}).rate,
    ncm: (record || {}).ncm,
    ncmId: ((record || {}).ncm || {}).id,
    code: ((record || {}).ncm || {}).code,
    description: ((record || {}).ncm || {}).description,
    chapter: ((record || {}).ncm || {}).chapter,
    position: ((record || {}).ncm || {}).position,
    subposition: ((record || {}).ncm || {}).subposition,
    item: ((record || {}).ncm || {}).item,
    subitem: ((record || {}).ncm || {}).subitem,
    start: ((record || {}).ncm || {}).start ? moment(((record || {}).ncm || {}).start) : null,
    finish: ((record || {}).ncm || {}).finish ? moment(((record || {}).ncm || {}).finish) : null
  };
};