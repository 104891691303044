import { BLUE, GREEN, RED } from 'grifo-components';
export const CANCELLED = 'cancelled';
export const DENIED = 'denied';
export const ACCEPTED = 'accepted';
export const BIDDED = 'bidded';
export const CANCELLED_BY_COMPANY = 'cancelled_by_company';
export const BID_EVENTS = [{
  value: undefined,
  label: 'Em aberto'
}, {
  value: CANCELLED,
  label: 'Cancelado',
  color: RED
}, {
  value: DENIED,
  label: 'Rejeitado',
  color: RED
}, {
  value: ACCEPTED,
  label: 'Aceito',
  color: GREEN
}, {
  value: BIDDED,
  label: 'Proposta enviada',
  color: BLUE
}, {
  value: CANCELLED_BY_COMPANY,
  label: 'Cancelado pela empresa',
  color: RED
}];
export const BID_EVENTS_ON_OFFER = [{
  value: undefined,
  label: 'Em aberto'
}, {
  value: CANCELLED,
  label: 'Cancelado',
  color: RED
}, {
  value: DENIED,
  label: 'Rejeitado',
  color: RED
}, {
  value: ACCEPTED,
  label: 'Aceito',
  color: GREEN
}, {
  value: BIDDED,
  label: 'Proposta recebida',
  color: BLUE
}, {
  value: CANCELLED_BY_COMPANY,
  label: 'Cancelado pela empresa',
  color: RED
}];