export const personCnaesParamBuilder = (record, values) => {
  return {
    attributes: values.cnaes.map(object => ({
      ...object,
      personId: record.id
    }))
  };
};
export const personCnaesDefaultBuilder = record => {
  return {
    cnaes: (record || {}).cnaes
  };
};