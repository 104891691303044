export const cnaeParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      division: values.division,
      group: values.group,
      grade: values.grade,
      description: values.description
    }
  };
};
export const cnaeDefaultBuilder = record => {
  return {
    division: (record || {}).division,
    group: (record || {}).group,
    grade: (record || {}).grade,
    code: (record || {}).code,
    description: (record || {}).description
  };
};