import gql from "graphql-tag";
import { fiscalProductUnitWithoutTaxInfoFragment } from "./ProductUnitWithoutTaxInfo";
export const fiscalProductWithoutTaxInfoFragment = gql`
  fragment FiscalProductWithoutTaxInfo on FiscalProduct  {
    id,
    name,
    description,
    searchCode,
    discardedAt,
    kind,
    nveCode,
    anpCode,
    codifCode,
    recopiCode,
    goodsOrigin,
    glpPercentage,
    glgnPercentage,
    importedGlgnPercentage,
    destinationType,
    productionScale,
    ipiExceptionCode,
    anpProductDescription,
    productUnits {
      ...FiscalProductUnitWithoutTaxInfo
    }      
  }
  ${fiscalProductUnitWithoutTaxInfoFragment}
`;