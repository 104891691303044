import gql from "graphql-tag";
import { personCnaeFragment } from "../fragments/PersonCnae";
export const GQL_PERSON_CNAES_LIST = gql`
  {
    personCnaes {
      ...PersonCnae,
    }    
  }
  ${personCnaeFragment}
`;
export const GQL_PERSON_CNAES_STORE = gql`
  mutation StorePersonCnaes($attributes: PersonCnaesAttributes!, $personId: ID!) {
    storePersonCnaes(input: {attributes: $attributes, personId: $personId}) { 
      personCnaes { 
        ...PersonCnae,
      }, 
      errors 
    } 
  }
  ${personCnaeFragment}  
`;