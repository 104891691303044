import { getLabelByValue } from 'grifo-components';
import { STOCK_STATS_ABOVE_MAXIMUM, STOCK_STATS_ALMOST_ZERO, STOCK_STATS_BELOW_MINIMUM, STOCK_STATS_GOOD, STOCK_STATS_HIGH, STOCK_STATS_IN_STOCK, STOCK_STATS_LOW, STOCK_STATS_MODERATE, STOCK_STATS_OUT_OF_STOCK } from "../constants/analysis/Balance";
import { STOCK_ABOVE_MAXIMUM_COLOR, STOCK_ALMOST_ZERO_COLOR, STOCK_BELOW_MINIMUM_COLOR, STOCK_GOOD_COLOR, STOCK_HIGH_COLOR, STOCK_IN_COLOR, STOCK_LOW_COLOR, STOCK_MODERATE_COLOR, STOCK_OUT_COLOR } from "../constants/analysis/Colors";
export const getBalanceStatsColor = stat => {
  const colors = [{
    value: STOCK_STATS_OUT_OF_STOCK,
    color: STOCK_OUT_COLOR
  }, {
    value: STOCK_STATS_IN_STOCK,
    color: STOCK_IN_COLOR
  }, {
    value: STOCK_STATS_GOOD,
    color: STOCK_GOOD_COLOR
  }, {
    value: STOCK_STATS_MODERATE,
    color: STOCK_MODERATE_COLOR
  }, {
    value: STOCK_STATS_ALMOST_ZERO,
    color: STOCK_ALMOST_ZERO_COLOR
  }, {
    value: STOCK_STATS_BELOW_MINIMUM,
    color: STOCK_BELOW_MINIMUM_COLOR
  }, {
    value: STOCK_STATS_LOW,
    color: STOCK_LOW_COLOR
  }, {
    value: STOCK_STATS_HIGH,
    color: STOCK_HIGH_COLOR
  }, {
    value: STOCK_STATS_ABOVE_MAXIMUM,
    color: STOCK_ABOVE_MAXIMUM_COLOR
  }];
  return getLabelByValue(colors, stat, 'color');
};