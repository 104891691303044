import moment from 'moment';
export const driverVehicleParamBuilder = (record, values) => {
  return {
    attributes: values.driverVehicles,
    driverId: record.id
  };
};
export const driverVehicleDefaultBuilder = record => {
  return {
    driverVehicles: (record || []).map(object => ({
      ...object,
      ...object.vehicle,
      documents64: ((object.vehicle || {}).documents64 || []).map(file => ({
        name: file.filename,
        status: 'done',
        uid: file.id,
        key: file.key,
        url: file.key,
        filename: file.filename,
        contentType: file.contentType,
        data: file.data
      })),
      id: object.id,
      modelYear: (object.vehicle || {}).modelYear ? ((object.vehicle || {}).modelYear || '').toString() : ((object || {}).modelYear || '').toString(),
      manufactureYear: (object.vehicle || {}).manufactureYear ? ((object.vehicle || {}).manufactureYear || '').toString() : ((object || {}).manufactureYear || '').toString(),
      vehicleId: (object.vehicle || {}).id,
      tracker: (object.vehicle || {}).tracker,
      start: object.start ? moment(object.start) : null,
      finish: object.finish ? moment(object.finish) : null
    }))
  };
};