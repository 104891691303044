import gql from "graphql-tag";
export const stockMovementSubkindAnalysisFragment = gql`
  fragment StockMovementSubkindAnalysis on StockMovementSubkindAnalysis {
    id,
    content,
    date,
    percent,
    variation,
    quantity,
  }
`;