import gql from "graphql-tag";
import { electronicDocumentBatchFragment } from "./ElectronicDocumentBatch";
export const electronicDocumentFragment = gql`
  fragment ElectronicDocument on ElectronicDocument  { 
    id,
    companyId,
    invoiceId,
    waybillId,
    dfeModel,
    dfeSeries,
    dfeNumber,
    situation,
    environment,
    issuanceDate,
    issueMode,
    accessKey,
    qrCode,
    urlKey,
    xmlDownloadUrl,
    authorizationProtocol,
    printFormat,
    cancellationJustification,
    cancellationProtocol,
    denialProtocol,
    receiptCode,
    softwareVersion,
    layoutVersion,
    rejectionCode,
    rejectionMessage,
    contingencyDate,
    contingencyJustification,
    contingencyAccessKey,
    contingencyAccessKeyDigit,
    electronicDocumentBatchId,
    electronicDocumentBatch {
      ...ElectronicDocumentBatch
    }
  }
  ${electronicDocumentBatchFragment}
`;