import gql from "graphql-tag";
import { invoiceItemCofinsCalculationFragment } from "./InvoiceItemCofinsCalculation";
import { InvoiceItemIcmsCalculationFragment } from "./InvoiceItemIcmsCalculation";
import { InvoiceItemIcmsDestinationFragment } from "./InvoiceItemIcmsDestination";
import { InvoiceItemIcmsSharedFragment } from "./InvoiceItemIcmsShared";
import { invoiceItemIpiCalculationFragment } from "./InvoiceItemIpiCalculation";
import { invoiceItemPisCalculationFragment } from "./InvoiceItemPisCalculation";
export const invoiceItemTaxesCalculationFragment = gql`
  fragment InvoiceItemTaxesCalculation on InvoiceItemTaxesCalculation {
    errors,
    totalTaxes,
    pis {
      ...InvoiceItemPisCalculation
    },
    ipi {
      ...InvoiceItemIpiCalculation
    },
    cofins {
      ...InvoiceItemCofinsCalculation
    },
    icms {
      ...InvoiceItemIcmsCalculation
    },
    icmsShared {
      ...InvoiceItemIcmsShared
    },
    icmsDestination {
      ...InvoiceItemIcmsDestination
    }
  }
  ${invoiceItemPisCalculationFragment}
  ${invoiceItemIpiCalculationFragment}
  ${invoiceItemCofinsCalculationFragment}
  ${InvoiceItemIcmsCalculationFragment}
  ${InvoiceItemIcmsSharedFragment}
  ${InvoiceItemIcmsDestinationFragment}
`;