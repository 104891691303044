import gql from "graphql-tag";
import { packingKindFragment } from "../fragments/PackingKind";
export const GQL_PACKING_KINDS_SELECTION = gql`
  {
    packingKinds {
      ...PackingKind
    }
  }
  ${packingKindFragment}
`;
export const GQL_PACKING_KINDS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    packingKindsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...PackingKind
        }
      }
    }    
  }
  ${packingKindFragment}
`;
export const GQL_PACKING_KINDS_BY_ID = gql`
  query ById($id: ID) {
    packingKinds(id: $id) {
      ...PackingKind
    }
  }
  ${packingKindFragment} 
`;
export const GQL_PACKING_KIND_STORE = gql`
  mutation StorePackingKind($attributes: PackingKindAttributes!) {
    storePackingKind(input: {attributes: $attributes}) { 
      packingKind { 
        ...PackingKind
      }, 
      errors 
    } 
  }
  ${packingKindFragment}
`;
export const GQL_PACKING_KIND_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Stock::PackingKind"}) {
      id,
      discardedAt
    }
  }
`;