import { productUnitDefaultBuilder, productUnitParamAttributesBuilder } from 'stock-front';
export const fiscalProductUnitParamBuilder = (record, values, parentRecord = undefined) => {
  return {
    attributes: Object.assign({}, productUnitParamAttributesBuilder(record, values, parentRecord), {
      taxableUnitId: values.taxableUnitId,
      value: values.value,
      ncmId: values.ncmId,
      code: values.code,
      taxBenefits: values.taxBenefits || []
    }),
    productId: parentRecord.id
  };
};
export const fiscalProductDefaultBuilder = record => {
  const object = record || {};
  return Object.assign({}, productUnitDefaultBuilder(object), {
    taxableUnitId: object.taxableUnitId,
    value: object.value || 0,
    ncmId: object.ncmId,
    taxBenefits: object.taxBenefits,
    code: object.code
  });
};