import { sanitizeBackend } from 'grifo-components';
export const requestParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      paymentConditionId: values.paymentConditionId,
      paymentMethodId: values.paymentMethodId,
      pickupCompanyId: values.pickupCompany,
      currentEvent: values.currentEvent,
      currentDate: values.currentDate,
      requestProductUnits: values.requestProductUnits.map(object => ({
        quantity: object.quantity,
        productUnitId: object.productUnit[1]
      }))
    }
  };
};
export const requestDefaultBuilder = record => {
  const object = record || {};
  return {
    paymentConditionId: (object.paymentCondition || {}).id,
    paymentMethodId: (object.paymentMethod || {}).id,
    paymentCondition: object.paymentCondition || {},
    paymentMethod: object.paymentMethod || {},
    pickupCompany: (object.pickupCompany || {}).id,
    currentEvent: object.currentEvent,
    canEdit: object.canEdit,
    person: object.person,
    companyId: object.companyId,
    transactionId: object.transactionId || '',
    currentDate: sanitizeBackend(object.currentDate),
    requestProductUnits: object.requestProductUnits || [{}],
    requestIds: object.requestIds
  };
};
export const requesitionToInvoiceParamBuilder = record => {
  const object = record || {};
  return {
    requestId: object.id,
    paymentCondition: object.paymentCondition || {},
    paymentMethod: object.paymentMethod || {},
    pickupCompany: object.pickupCompany || {},
    currentEvent: object.currentEvent,
    canEdit: object.canEdit,
    person: object.person,
    companyId: object.companyId,
    transactionId: object.transactionId || '',
    currentDate: sanitizeBackend(object.currentDate),
    requestProductUnits: object.requestProductUnits || [{}],
    requestIds: object.requestIds
  };
};
export const loadProductSet = (record, form, index) => {
  if (!record && !form) return;
  let fieldsValue = form.getFieldsValue();
  if (!fieldsValue || !fieldsValue.requestProductUnits || !fieldsValue.requestProductUnits[index]) return;
  let productUnit = fieldsValue.requestProductUnits[index] || {};
  return {
    productUnitId: productUnit.productUnit ? productUnit.productUnit.id || productUnit.productUnit[1] || productUnit.productUnitId : productUnit.productUnitId ? productUnit.productUnitId : undefined,
    productId: productUnit.product ? productUnit.product.id || productUnit.productId : productUnit.productUnit ? productUnit.productUnit[0] : undefined
  };
};