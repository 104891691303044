import gql from "graphql-tag";
import { stockDailyBalanceFragment } from "./StockDailyBalance";
import { stockFlowMovementFragment } from "./StockFlowMovement";
export const stockFlowBatchFragment = gql`
  fragment StockFlowBatch on StockFlowBatch  {
    id,
    number,
    movements {
      ...StockFlowMovement
    }
    balance {
      ...StockDailyBalance
    }
  }
  ${stockFlowMovementFragment}
  ${stockDailyBalanceFragment}
`;