export const KIND_RULES = [{
  required: true,
  message: "Selecione o tipo"
}];
export const PRODUCTION_SCALE_RULES = [{
  required: true,
  message: "Selecione a escala de produção"
}];
export const GOODS_ORIGIN_RULES = [{
  required: true,
  message: "Selecione a origem"
}];