import gql from "graphql-tag";
export const GQL_DASHBOARD_PRODUCTS_COUNT = gql`
  query stockDashboard($productCount: Boolean) {    
    stockDashboard(productCount: $productCount) {
      productCount
    }
  }
`;
export const GQL_DASHBOARD_GROUP_INFO = gql`
  query stockDashboard($groupInfo: Boolean) {    
    stockDashboard(groupInfo: $groupInfo) {
      groupInfo {
        id, 
        name, 
        value
      }
    }
  }
`;