import gql from "graphql-tag";
import { electronicDocumentCorrectionFragment } from "../fragments/ElectronicDocumentCorrection";
import { reportFragment } from 'admin-front';
export const GQL_PRINT_CORRECTION = gql`
  query PrintCorrection($id: ID!) {    
    printCorrection(id: $id) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_ELECTRONIC_DOCUMENT_CORRECTIONS_LIST = gql`
  query GetByElectronicDocumentId($electronicDocumentId: ID!) {    
    electronicDocumentCorrections(electronicDocumentId: $electronicDocumentId) {
      ...ElectronicDocumentCorrection
    }
  }
  ${electronicDocumentCorrectionFragment}
`;
export const GQL_ELECTRONIC_DOCUMENT_CORRECTION_STORE = gql`
  mutation StoreElectronicDocumentCorrection($attributes: ElectronicDocumentCorrectionAttributes!) {
    storeElectronicDocumentCorrection(input: {attributes: $attributes}) { 
      electronicDocumentCorrection { 
        ...ElectronicDocumentCorrection,
      }, 
      errors 
    } 
  }
  ${electronicDocumentCorrectionFragment}  
`;