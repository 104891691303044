import { sanitize } from 'grifo-components';
import { MAIN, personAtributesBuilder, personDefaultBuilder } from 'admin-front';
export const shippingCompanyParamBuilder = (record, values) => {
  let params = sanitize(values, ["city", "contact"]);
  params.phone.naming = "main";
  params.address.naming = MAIN;
  let person = personAtributesBuilder(record?.person, params);
  person.address = (params || {}).address;
  person.phone = (params || {}).phone;
  return {
    attributes: Object.assign({}, {
      person: person
    }, {
      id: record ? record.id : null
    })
  };
};
export const shippingCompanyDefaultBuilder = record => {
  return Object.assign({}, personDefaultBuilder((record || {}).person), {
    address: (((record || {}).person || {}).addresses || [])[0] || ((record || {}).person || {}).mainAddress
  });
};