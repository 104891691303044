import { packObjectList } from 'grifo-components';
export const productBrandParamBuilder = (record, values) => {
  return {
    attributes: values.productBrands.map(object => ({
      ...object,
      productModels: packObjectList(object.productModels, "productModelId"),
      productId: record.id
    })),
    productId: record.id
  };
};
export const productBrandDefaultBuilder = record => {
  return {
    productBrands: (record || {}).productBrands
  };
};