export const stateRegistrationParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      stateRegistration: values.stateRegistration,
      stateId: values.stateId
    }
  };
};
export const stateRegistrationDefaultBuilder = record => {
  return {
    stateRegistrations: record
  };
};