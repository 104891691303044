import gql from "graphql-tag";
import { fiscalOperationFragment } from "./Operation";
export const parentFiscalOperationFragment = gql`
  fragment FiscalOperationParent on FiscalOperation {
    ...FiscalOperation,
    parent {
      ...FiscalOperation,
    },
    children {
      ...FiscalOperation,
      children {
        ...FiscalOperation,
        children {
          ...FiscalOperation,
          children {
            ...FiscalOperation,
            children {
              ...FiscalOperation,
            }
          }
        }
      }
    }
  }
  ${fiscalOperationFragment} 
`;