import gql from "graphql-tag";
import { resultCenterFragment } from "financial-front";
export const invoiceResultCenterFragment = gql`
  fragment InvoiceResultCenter on InvoiceResultCenter { 
    id,
    value,
    apportionment,
    resultCenterId,
    resultCenter{
      ...ResultCenter
    }
  }
  ${resultCenterFragment}
`;