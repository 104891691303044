import gql from "graphql-tag";
import { personFiscalInfoFragment } from "../fragments/PersonFiscalInfo";
export const GQL_PERSON_FISCAL_INFOS_LIST = gql`
  {
    personFiscalInfos {
      ...PersonFiscalInfo,
    }    
  }
  ${personFiscalInfoFragment}
`;
export const GQL_PERSON_FISCAL_INFOS_STORE = gql`
  mutation StorePersonFiscalInfos($attributes: PersonFiscalInfosAttributes!, $personId: ID!) {
    storePersonFiscalInfos(input: {attributes: $attributes, personId: $personId}) { 
        personFiscalInfos { 
        ...PersonFiscalInfo,
      }, 
      errors 
    } 
  }
  ${personFiscalInfoFragment}  
`;