export const TRUCK = 'truck';
export const STUMP = 'stump';
export const MECHANIC_HORSE = 'mechanic_horse';
export const VAN = 'van';
export const UTILITARY = 'utilitary';
export const OTHERS = 'others';
export const WHEEL_TYPE_OPTIONS = [{
  value: TRUCK,
  label: 'Truck'
}, {
  value: STUMP,
  label: 'Toco'
}, {
  value: MECHANIC_HORSE,
  label: 'Cavalo mecânico'
}, {
  value: VAN,
  label: 'Van'
}, {
  value: UTILITARY,
  label: 'Utilitário'
}, {
  value: OTHERS,
  label: 'Outros'
}];