import { LINE_CHART_TYPE } from "../../constants/Charts";
import { formatter } from "./GenericChartUtil";
export const buildAreaSeries = (series1Data, series2Data, valueField, valueDataType, seriesFieldName, series2FieldName, xAxisData, xAxisField) => {
  if (!series1Data) return [];
  let color = '#FE4A49';
  if (series2Data[series2Data.length - 1][valueField] >= series1Data[series1Data.length - 1][valueField]) color = '#009FB7';
  let series = [];
  series.push(buildSerie(series1Data, seriesFieldName, valueField, xAxisData, xAxisField, valueDataType, 'grey', 'dotted'));
  series.push(buildSerie(series2Data, series2FieldName, valueField, xAxisData, xAxisField, valueDataType, color));
  return series;
};
const buildSerie = (item, seriesFieldName, valueField, xAxisData, xAxisField, valueDataType, color, lineStyleType = 'solid') => {
  return {
    name: seriesFieldName || item,
    type: LINE_CHART_TYPE,
    smooth: true,
    itemStyle: {
      color: color
    },
    lineStyle: {
      type: lineStyleType
    },
    areaStyle: {
      opacity: 0.1,
      color: color
    },
    markPoint: {
      symbol: 'pin',
      symbolSize: 0,
      label: {
        position: 'top',
        fontWeight: 'bold',
        fontSize: 14,
        color: color,
        formatter: (params, index) => formatter(params.value, valueDataType)
      },
      data: [{
        type: 'max',
        name: 'Max'
      }]
    },
    emphasis: {
      focus: 'series'
    },
    data: buildXData(item, valueField, xAxisData, xAxisField),
    label: {
      formatter: (params, index) => formatter(params.value, valueDataType)
    }
  };
};
const buildXData = (records, valueField, xAxisData, xAxisField) => {
  let seriesData = [];
  xAxisData.map(data => {
    const day = records.filter(record => record[xAxisField] === data)[0];
    if (day) seriesData.push(day[valueField] || 0);
  });
  return seriesData;
};