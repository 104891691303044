import gql from "graphql-tag";
import { invoiceItemIpiFragment } from "./InvoiceItemIpi";
export const invoiceItemIpiCalculationFragment = gql`
  fragment InvoiceItemIpiCalculation on InvoiceItemIpiCalculation  { 
    errors,  
    status,  
    ipi {
      ...InvoiceItemIpi
    }
  }  
  ${invoiceItemIpiFragment}  
`;