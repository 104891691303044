import gql from "graphql-tag";
import { InvoiceVolumeSealFragment } from "./InvoiceVolumeSeal";
import { brandSimpleFragment, packingKindFragment } from 'stock-front';
export const InvoiceVolumeFragment = gql`
  fragment InvoiceVolume on InvoiceVolume { 
    id,
    quantity,
    number,
    netWeight,
    grossWeight,
    brandId,
    brand {
      ...BrandSimple
    }    
    packingKindId,
    packingKind {
      ...PackingKind
    }
    seals {
      ...InvoiceVolumeSeal
    }
  }
  ${InvoiceVolumeSealFragment}
  ${packingKindFragment}
  ${brandSimpleFragment}
`;