import gql from "graphql-tag";
export const vehicleTypeFragment = gql`
  fragment VehicleType on VehicleType  { 
    id,
    wheelType,
    name,
    description,
    weight,
    discardedAt
  }
`;