import gql from "graphql-tag";
import { ManifestFragment } from "../fragments/Manifest";
import { reportFragment } from 'admin-front';
export const GQL_MANIFEST_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    manifestsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Manifest
        }
      }
    }    
  }
  ${ManifestFragment}
`;
export const GQL_MANIFEST_STORE = gql`
  mutation StoreManifest($attributes: ManifestAttributes!) {
    storeManifest(input: {attributes: $attributes}) { 
      manifest {
        ...Manifest,
      }, 
      errors 
    } 
  }
  ${ManifestFragment}
`;
export const GQL_CREATE_DAMDFE = gql`
  mutation CreateDamdfe($manifestId: ID!) {
    createDamdfe(input: {manifestId: $manifestId}) { 
      manifest {
        ...Manifest,
      }, 
      errors 
    } 
  }
  ${ManifestFragment}
`;
export const GQL_GENERATE_DAMDFE = gql`
  query GenerateDamdfe($id: ID!) {    
    generateDamdfe(id: $id) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_CONSULTATION_DAMDFE = gql`
  mutation StoreManifestConsultation($id: ID!) {
    storeManifestConsultation(input:{ id: $id}) {
      manifest {
        ...Manifest,
      }, 
      errors
    }
  }
  ${ManifestFragment}
`;
export const GQL_CANCEL_MANIFEST_REQUEST = gql`
  mutation CancelManifestRequest($attributes: ManifestEventAttributes!) { 
    cancelManifestRequest(input: {attributes: $attributes}) {
      manifest {
        ...Manifest,
      },
      errors
    } 
  }
  ${ManifestFragment}
`;