import gql from "graphql-tag";
import { fiscalProductUnitFragment } from "../fragments/ProductUnit";
export const GQL_FISCAL_PRODUCT_UNIT_STORE = gql`
  mutation StoreFiscalProductUnit($attributes: FiscalProductUnitAttributes!, $productId: ID!) {
    storeFiscalProductUnit(input: {attributes: $attributes, productId: $productId}) { 
      productUnit {
        ...FiscalProductUnit
      }, 
      errors 
    } 
  }
  ${fiscalProductUnitFragment}   
`;
export const GQL_FISCAL_PRODUCT_UNITS_BY_PRODUCT = gql`
  query GetByProduct($product: ID) {    
    fiscalProductUnits(product: $product) { 
      ...FiscalProductUnit
    }
  }
  ${fiscalProductUnitFragment}   
`;
export const GQL_FISCAL_PRODUCT_UNIT_LOAD = gql`
  query Get($id: ID) {    
    fiscalProductUnit(id: $id) {
      ...FiscalProductUnit
    }
  }
  ${fiscalProductUnitFragment}   
`;