import gql from "graphql-tag";
import { stockOperationSimpleFragment } from "./OperationSimple";
export const stockOperationFragment = gql`
  fragment StockOperation on StockOperation  { 
      ...StockOperationSimple
      parent {
        ...StockOperationSimple
      },
  }
  ${stockOperationSimpleFragment}
`;