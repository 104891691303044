import gql from "graphql-tag";
import { driverFragment } from "./Driver";
export const driverVehicleFragment = gql`
  fragment DriverVehicle on DriverVehicle  { 
    id,
    start,
    finish,
    driverId,
    driver {
      ...Driver
    }
  }
  ${driverFragment}
`;