import gql from "graphql-tag";
import { addressFragment, phoneFragment } from 'admin-front';
export const fiscalPersonInvoiceListingFragment = gql`
  fragment FiscalPersonInvoiceListing on FiscalPerson {
    id,
    name,
    tradeName,
    document,
    naturalRegistration,
    naturalRegistrationStateId,
    naturalRegistrationAgencyId,
    email,
    gender,
    birth,
    closure,
    cadunicoCode,
    nisCode,
    discardedAt,
    contributeIcms,
    municipalRegistration,
    suframaRegistration,
    taxRegime,
    nfceToken,
    nfceId,
    environment,
    producer,
    juridical,
    natural,
    mainAddress {
      ...Address
    },
    phone {
      ...Phone
    },
    ofCompany {
      id,
    }
  }
  ${addressFragment}
  ${phoneFragment}
`;