import gql from "graphql-tag";
import { InvoiceFragment } from "../fragments/Invoice";
import { InvoiceEventFragment } from "../fragments/InvoiceEvent";
import { parentInvoiceFragment } from "../fragments/InvoiceParent";
export const GQL_INVOICE_EVENT_STORE = gql`
  mutation StoreInvoiceEvent($attributes: InvoiceEventAttributes!) {
    storeInvoiceEvent(input: {attributes: $attributes}) { 
      invoiceEvent {
        ...InvoiceEvent,
      }, 
      invoice {
        ...Invoice
      }
      errors 
    } 
  }
  ${InvoiceEventFragment}
  ${InvoiceFragment}  
`;
export const GQL_CANCEL_BILLED_INVOICE = gql`
  mutation CancelBilledInvoice($attributes: InvoiceEventAttributes!) {
    cancelBilledInvoice(input: {attributes: $attributes}) { 
      invoice {
        ...ParentInvoice,
      }, 
      errors 
    } 
  }
  ${parentInvoiceFragment}
`;
export const GQL_CANCEL_INVOICE_REQUEST = gql`
  mutation CancelInvoiceRequest($attributes: InvoiceEventAttributes!) { 
    cancelInvoiceRequest(input: {attributes: $attributes}) {
      invoice {
        ...Invoice,
      },
      errors
    } 
  }
  ${InvoiceFragment}
`;