import gql from "graphql-tag";
export const stockMovementProductUnitAnalysisFragment = gql`
  fragment StockMovementProductUnitAnalysis on StockMovementProductUnitAnalysis {
    id,
    parentId,
    unitName,
    total,
    loss,
    reversal,
    reserve,
    entry,
    exit
  }
`;