import gql from "graphql-tag";
import { taxProfileNcmFragment } from "../fragments/TaxProfileNcm";
export const GQL_TAX_PROFILE_NCM_STORE = gql`
  mutation StoreTaxProfileNcm($attributes: TaxProfileNcmAttributes!, $taxProfileId: ID!) {
    storeTaxProfileNcm(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      ncms {
        ...TaxProfileNcm
      }, 
      errors 
    } 
  }
  ${taxProfileNcmFragment}   
`;