import gql from "graphql-tag";
import { operationFragment, paymentMethodSimpleFragment } from "financial-front";
import { locationSimpleFragment, stockOperationSimpleFragment } from 'stock-front';
import { TaxProfileFragment } from './TaxProfile';
export const fiscalOperationSimpleFragment = gql`
  fragment FiscalOperationSimple on FiscalOperation {
    id,
    name,
    kind,
    subkind,
    generatesMovement,
    kindOfService,
    canActivate,
    canActivateNfce,
    dfeModel,
    dfeSeries,
    taxDocumentIssuer,
    isFor,
    onApproval,
    convenienceStoreMode,
    packingListQuantity,
    packingListUnity,
    packingListUnitaryValue,
    packingListTotalValue,
    administrateTaxAdditionalInfo,
    paymentMethods{
      ...PaymentMethodSimple
    },
    parentId,
    discardedAt,
    fullPathName,   
    stockLocationId,
    financialOperationId,
    financialOperationChangeId,
    financialOperationAdministrateTaxId,
    issuancePurpose,
    receiver,
    stockLocation{
      ...LocationSimple
    }
    financialOperationChange{
      ...Operation
    },
    financialOperationAdministrateTax{
      ...Operation
    },
    convenienceStoreStockOperation{
      ...StockOperationSimple
    }
    stockOperation{
      ...StockOperationSimple
    }
    financialOperation{
      ...Operation
    }
    operationTaxProfiles{
      id,
      sequenceNumber,
      taxProfileId,
      taxProfile {
        ...TaxProfile
      }
    }
  }
  ${stockOperationSimpleFragment}
  ${locationSimpleFragment}
  ${paymentMethodSimpleFragment}
  ${operationFragment}
  ${TaxProfileFragment}
`;