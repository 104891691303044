import gql from "graphql-tag";
import { groupFragment } from "./Group";
export const groupParentFragment = gql`
  fragment GroupParent on Group {
    ...Group
    children {
      ...Group,
      children {
        ...Group,
        children {
          ...Group,
          children {
            ...Group,
            children {
              ...Group,
            }
          }
        }
      }
    }
  }
  ${groupFragment} 
`;