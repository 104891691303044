import { InvoiceItemIcmsSharedFragment } from "fiscal-front/src/lib/graphql/fragments/InvoiceItemIcmsShared";
import gql from "graphql-tag";
import { invoiceItemCofinsCalculationFragment } from "../fragments/InvoiceItemCofinsCalculation";
import { InvoiceItemIcmsCalculationFragment } from "../fragments/InvoiceItemIcmsCalculation";
import { InvoiceItemIcmsDestinationFragment } from "../fragments/InvoiceItemIcmsDestination";
import { invoiceItemIpiCalculationFragment } from "../fragments/InvoiceItemIpiCalculation";
import { invoiceItemPisCalculationFragment } from "../fragments/InvoiceItemPisCalculation";
import { invoiceItemTaxesCalculationFragment } from "../fragments/InvoiceItemTaxesCalculation";
export const GQL_ITEM_ICMS_CALCULATION = gql`
  query Calculate($attributes: FilterInvoiceItemIcmsCalc) {    
    invoiceItemIcmsCalc(attributes: $attributes) {
      ...InvoiceItemIcmsCalculation
    }
  }
  ${InvoiceItemIcmsCalculationFragment}
`;
export const GQL_ITEM_COFINS_CALCULATION = gql`
  query Calculate($attributes: FilterInvoiceItemCofinsCalc) {    
    invoiceItemCofinsCalc(attributes: $attributes) {
      ...InvoiceItemCofinsCalculation
    }
  }
  ${invoiceItemCofinsCalculationFragment}
`;
export const GQL_ITEM_ICMS_DESTINATION_CALCULATION = gql`
  query Calculate($attributes: FilterInvoiceItemIcmsDestinationCalc) {    
    invoiceItemIcmsDestinationCalc(attributes: $attributes) {
      ...InvoiceItemIcmsDestination
    }
  }
  ${InvoiceItemIcmsDestinationFragment}
`;
export const GQL_ITEM_ICMS_SHARED_CALCULATION = gql`
  query Calculate($attributes: FilterInvoiceItemIcmsSharedCalc) {    
    invoiceItemIcmsSharedCalc(attributes: $attributes) {
      ...InvoiceItemIcmsShared
    }
  }
  ${InvoiceItemIcmsSharedFragment}
`;
export const GQL_ITEM_PIS_CALCULATION = gql`
  query Calculate($attributes: FilterInvoiceItemPisCalc) {    
    invoiceItemPisCalc(attributes: $attributes) {
      ...InvoiceItemPisCalculation
    }
  }
  ${invoiceItemPisCalculationFragment}
`;
export const GQL_ITEM_TAXES_CALCULATION = gql`
  query Calculate($attributes: FilterInvoiceItemTaxesCalc) {    
    invoiceItemTaxesCalc(attributes: $attributes) {
      ...InvoiceItemTaxesCalculation
    }
  }
  ${invoiceItemTaxesCalculationFragment}
`;
export const GQL_ITEM_IPI_CALCULATION = gql`
  query Calculate($attributes: FilterInvoiceItemIpiCalc) {    
    invoiceItemIpiCalc(attributes: $attributes) {
      ...InvoiceItemIpiCalculation
    }
  }
  ${invoiceItemIpiCalculationFragment}
`;