import gql from "graphql-tag";
export const InvoiceItemIcmsDestinationFragment = gql`
  fragment InvoiceItemIcmsDestination on InvoiceItemIcmsDestination  { 
    id,
    basis,
    rate,
    provisionalRate,
    interstateRate,    
    value,
    originValue,    
    fcpBasis,
    fcpRate,
    fcpValue,
  }  
`;