import { BLUE, GREEN, RED } from 'grifo-components';
export const MEMBERS = 'members';
export const NET = 'net';
export const EVERYONE = 'everyone';
export const PUBLISHED = 'published';
export const FINISHED = 'finished';
export const CANCELLED = 'cancelled';
export const PURCHASE_ORDER = 'purchase_order';
export const AVAILABILITY_OPTIONS = [{
  value: MEMBERS,
  label: 'Somente associados'
}, {
  value: NET,
  label: 'Somente rede'
}, {
  value: EVERYONE,
  label: 'Todos'
}];
export const OFFER_EVENTS = [{
  value: undefined,
  label: 'Em aberto'
}, {
  value: PUBLISHED,
  label: 'Publicado',
  color: GREEN
}, {
  value: FINISHED,
  label: 'Finalizado',
  color: BLUE
}, {
  value: CANCELLED,
  label: 'Cancelado',
  color: RED
}, {
  value: PURCHASE_ORDER,
  label: 'Pedido Realizado',
  color: GREEN
}];