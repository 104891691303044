import { personAtributesBuilder, personDefaultBuilder } from 'admin-front';
import { ICMS_TAXPAYER_EXEMPT, NORMAL_PRODUCER } from "../constants/personFiscal/PersonFiscalOptions";
import { sanitize, dateOrToday } from 'grifo-components';
export const fiscalPersonParamBuilder = (record, values) => {
  let certificate64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    attributes: Object.assign({}, personAtributesBuilder(record, values), {
      cnaes: values.cnaes,
      sts: values.sts,
      infos: values.infos ? values.infos.map(object => ({
        ...object,
        mandatory: object.mandatory || false
      })) : undefined,
      certificate: {
        documents64: certificate64,
        password: values.certificatePassword
      },
      fiscalStarts: values.fiscalStarts ? values.fiscalStarts.map(object => ({
        ...object,
        id: object.id ? object.id : undefined,
        dfeSeries: parseInt(object.dfeSeries),
        dfeNumber: parseInt(object.dfeNumber),
        dfeModel: object.dfeModel
      })) : undefined,
      contributeIcms: values.contributeIcms,
      stateRegistration: values.stateRegistration,
      municipalRegistration: values.municipalRegistration,
      suframaRegistration: values.suframaRegistration,
      taxRegime: values.taxRegime,
      nfceToken: values.nfceToken,
      nfceId: values.nfceId,
      environment: values.environment,
      issueMode: values.issueMode,
      producer: values.producer || NORMAL_PRODUCER,
      stateRegistrations: values.stateRegistrations || [],
      personBankings: (values?.personBankings || []).map(object => {
        return {
          id: object.id,
          bankId: object.bankId,
          branch: object.branch,
          description: object.description,
          kind: object.kind || 'checking_account',
          number: object.number,
          pixKey: object.pixKey,
          implantationDate: object.implantationDate
        };
      })
    })
  };
};
export const fiscalPersonDefaultBuilder = record => {
  const object = record || {};
  const cert = object.certificate?.documents64;
  let documents64 = null;
  if (cert) {
    documents64 = [{
      name: cert?.filename,
      status: 'done',
      uid: cert?.id,
      key: cert?.key,
      url: cert?.key,
      filename: cert?.filename,
      contentType: cert?.contentType,
      data: cert?.data
    }];
  } else {
    documents64 = null;
  }
  return Object.assign({}, personDefaultBuilder(record), {
    cnaes: object.cnaes || [],
    sts: object.sts || [],
    infos: object.infos || [],
    fiscalStarts: object.fiscalStarts || [],
    certificate: object.certificate,
    documents64: cert ? documents64 : null,
    contributeIcms: object.contributeIcms || ICMS_TAXPAYER_EXEMPT,
    stateRegistration: object.stateRegistration,
    municipalRegistration: object.municipalRegistration,
    suframaRegistration: object.suframaRegistration,
    taxRegime: object.taxRegime,
    nfceToken: object.nfceToken,
    nfceId: object.nfceId,
    environment: object.environment,
    issueMode: object.issueMode,
    producer: object.producer || NORMAL_PRODUCER,
    stateRegistrations: object.stateRegistrations,
    personBankings: object.personBankings ? object.personBankings.map((object, index) => {
      return {
        ...object,
        ...{
          id: object.id,
          implantationDate: dateOrToday(object.implantationDate)
        }
      };
    }) : object.personBankings
  });
};