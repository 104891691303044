import gql from "graphql-tag";
import { TaxProfileFragment } from "./TaxProfile";
import { stateFragment } from 'admin-front';
export const WaybillIcmsOriginFragment = gql`
  fragment WaybillIcmsOrigin on WaybillIcmsOrigin  { 
    id,
    taxSituation,
    basisReductionPercentage,
    basis,
    rate,
    value,
    taxProfileId,
    taxProfile {
      ...TaxProfile
    }
    stateId,
    state { 
      ...State
    }  
  }    
  ${TaxProfileFragment}  
  ${stateFragment}
`;