import gql from "graphql-tag";
import { TaxProfileFragment } from "./TaxProfile";
export const WaybillIcmsFragment = gql`
  fragment WaybillIcms on WaybillIcms  { 
    id,
    taxSituation,
    basisReductionPercentage,
    basis,
    rate,
    value,
    grantedCreditValue,
    taxProfileId,
    taxProfile {
      ...TaxProfile
    }
  }  
  ${TaxProfileFragment}  
`;