export const SELF = 'self';
export const OTHER = 'other';
export const CHARTERER = 'charterer';
export const DRIVER = 'driver';
export const SHIPPING_COMPANY = 'shipping_company';
export const VEHICLE_OWNER = [{
  value: SELF,
  label: 'Sou eu'
}, {
  value: OTHER,
  label: 'Outra pessoa'
}];