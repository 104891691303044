export const ETC = 'etc';
export const TAX = 'tax';
export const CTC = 'ctc';
export const RNTRC_CODE = [{
  value: ETC,
  label: 'ETC'
}, {
  value: TAX,
  label: 'TAX'
}, {
  value: CTC,
  label: 'CTC'
}];