import { WITHHOLDING } from "../constants/taxProfile/TaxFieldsOptions";
export const irpjlParamBuilder = (record, values) => {
  return {
    attributes: values.irpj.map(object => ({
      ...object,
      taxProfileId: record.id
    }))
  };
};
export const irpjDefaultBuilder = record => {
  return {
    rate: (record || {}).rate || 0,
    basisReductionPercentage: (record || {}).basisReductionPercentage || 0,
    kind: (record || {}).kind || WITHHOLDING
  };
};