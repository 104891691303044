import { ENTRY } from "../constants/operations/OperationsFieldsOptions";
import { getSessionInfo } from 'grifo-components';
export const movementParamBuilder = (record, values) => {
  return {
    attributes: (values.movements || []).map(object => ({
      companyId: getSessionInfo().company.id,
      transactionId: (record || {}).transactionId,
      operationId: values.operationId,
      productId: object.productUnit[0],
      productUnitId: object.productUnit[1],
      locationId: object.productUnit[2],
      batchId: object.productUnit[3],
      quantity: object.quantity
    }))
  };
};
export const movementDefaultBuilder = record => {
  return {
    kind: (record || {}).kind || ENTRY,
    operationId: (record || {}).operationId,
    transactionId: (record || {}).transactionId,
    movements: [{
      quantity: 1
    }]
  };
};