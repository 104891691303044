import gql from "graphql-tag";
import { fiscalPersonFragment } from "../fragments/Person";
export const GQL_FISCAL_PERSON_STORE = gql`
  mutation StoreFiscalPerson($attributes: FiscalPersonAttributes!) {
    storeFiscalPerson(input: {attributes: $attributes}) { 
      person { 
        ...FiscalPerson,
      }, 
      errors 
    } 
  }
  ${fiscalPersonFragment}   
`;
export const GQL_PEOPLE_STANDARD_CONSUMER = gql`
  query GetStandardConsumer($standardConsumer: Boolean) {    
    fiscalPeople(standardConsumer: $standardConsumer) {
      ...FiscalPerson
    }
  }
  ${fiscalPersonFragment}   
`;
export const GQL_FISCAL_PERSON_LOAD = gql`
  query Get($id: ID) {    
    fiscalPerson(byId: $id) {
      ...FiscalPerson
    }
  }
  ${fiscalPersonFragment}   
`;
export const GQL_FISCAL_PERSON_BY_DOCUMENT = gql`
  query GetByDocument($document: String) {    
    fiscalPeople(document: $document) {
      ...FiscalPerson
    }
  }
  ${fiscalPersonFragment}   
`;
export const GQL_FISCAL_PERSON_RURAL_PRODUCER = gql`
  query Get($producer: Boolean = true) {    
    fiscalPeople(producer: $producer) {
      ...FiscalPerson
    }
  }
  ${fiscalPersonFragment}   
`;