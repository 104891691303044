import { ACQUISITION } from 'grifo-components';
export const buildFeatures = () => {
  const acquisitionPath = "/" + ACQUISITION;
  const routes = [{
    ordination: '07.01',
    name: 'Dashboard',
    key: acquisitionPath + "/dashboard",
    routePath: acquisitionPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardPage",
      import_path: "containers/DashboardPage"
    }
  }, {
    ordination: '07.02',
    name: 'Compra coletiva',
    key: acquisitionPath + "/offer",
    routePath: acquisitionPath + "/offer",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "OffersPage",
      import_path: "containers/offers/OffersPage"
    },
    dividerAbove: true
  }, {
    ordination: '07.03',
    name: 'Ofertas disponíveis da rede',
    key: acquisitionPath + "/bids",
    routePath: acquisitionPath + "/bids",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "PublishedOffersPage",
      import_path: "containers/offers/PublishedOffersPage"
    }
  }, {
    ordination: '07.04',
    name: 'Requisições de compras',
    key: acquisitionPath + "/requests",
    routePath: acquisitionPath + "/requests",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "RequestsPage",
      import_path: "containers/requests/RequestsPage"
    }
  }, {
    ordination: '07.05',
    name: 'Análise de requisições',
    key: acquisitionPath + "/requisition_analysis",
    routePath: acquisitionPath + "/requisition_analysis",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "RequisitionAnalyzesPage",
      import_path: "containers/requests/RequisitionAnalyzesPage"
    },
    dividerAbove: true
  }];
  return {
    ordination: '07',
    name: 'Aquisição',
    key: acquisitionPath,
    kind: 'module',
    status: 'active',
    routePath: acquisitionPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'acquisition',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};
export const buildAssociatedFeatures = () => {
  const acquisitionPath = "/" + ACQUISITION;
  const associatedAcquisitionRoutes = [{
    ordination: '07.70',
    name: 'Ofertas disponíveis da rede',
    key: acquisitionPath + "/bids",
    routePath: acquisitionPath + "/bids",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "PublishedOffersPage",
      import_path: "containers/offers/PublishedOffersPage"
    },
    dividerAbove: true
  }, {
    ordination: '07.71',
    name: 'Requisições de compras',
    key: acquisitionPath + "/requests",
    routePath: acquisitionPath + "/requests",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "RequestsPage",
      import_path: "containers/requests/RequestsPage"
    },
    contentProp: {
      associatedAccess: true
    }
  }];
  return {
    ordination: '07',
    name: 'Aquisição',
    key: acquisitionPath,
    kind: 'module',
    status: 'active',
    routePath: acquisitionPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'acquisition',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: associatedAcquisitionRoutes
  };
};