export const invoiceItemAdditionalInfoParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      information: values.information,
      productInfoId: values.productInfoId,
      mandatory: values.mandatory || false
    }
  };
};
export const invoiceItemAdditionalInfoDefaultBuilder = record => {
  return {
    additionalInformations: record
  };
};