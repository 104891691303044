import gql from "graphql-tag";
import { addressFragment, phoneFragment } from 'admin-front';
import { fiscalPersonFragment } from "./Person";
import { personCnaeFragment } from "./PersonCnae";
import { personFiscalInfoFragment } from "./PersonFiscalInfo";
import { personFiscalStFragment } from "./PersonFiscalSt";
import { personFiscalStartFragment } from "./PersonFiscalStart";
export const fiscalCompanyFragment = gql`
  fragment FiscalCompany on FiscalCompany {
    id,
    kind,
    isHeadOffice,
    person {
      ...FiscalPerson
    },
    address {
      ...Address
    },
    phone {
      ...Phone
    },
    cnaes {
      ...PersonCnae
    },
    sts {
      ...PersonFiscalSt
    },
    infos {
      ...PersonFiscalInfo
    },
    currentInfos {
      ...PersonFiscalInfo
    },
    fiscalStarts {
      ...PersonFiscalStart
    }    
  }
  ${fiscalPersonFragment}
  ${addressFragment}
  ${phoneFragment}
  ${personCnaeFragment}
  ${personFiscalStFragment}
  ${personFiscalInfoFragment}
  ${personFiscalStartFragment}  
`;