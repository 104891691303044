import { ENTRY, EXIT, SALES, POINT_OF_SALES, OTHER, PURCHASE, THIRD_PARTY_TAX_DOCUMENT, DOES_NOT_GENERATE_TAX_DOCUMENT } from "../constants/operations/OperationsFieldsOptions";
import { sanitize } from 'grifo-components';
import { ANYONE, NFE, NORMAL_DFE } from "../constants/invoices/InvoiceFieldsOptions";
export const operationParamBuilder = (record, values) => {
  let params = sanitize(values, ["taxProfile"]);
  return {
    attributes: {
      id: record ? record.id : null,
      name: params.name,
      kind: params.kind,
      subkind: params.subkind,
      generatesMovement: params.generatesMovement,
      kindOfService: params.kindOfService,
      parentId: params.parentId,
      convenienceStoreStockOperationId: params.convenienceStoreStockOperationId,
      stockOperationId: params.stockOperationId,
      financialOperationId: params.financialOperationId,
      operationTaxProfiles: params.operationTaxProfiles,
      issuancePurpose: params.issuancePurpose,
      receiver: params.receiver,
      dfeModel: params.dfeModel,
      onApproval: params.onApproval || false,
      dfeSeries: parseInt(params.dfeSeries),
      isFor: values.isFor ? POINT_OF_SALES : OTHER,
      convenienceStoreMode: params.convenienceStoreMode,
      taxDocumentIssuer: params.taxDocumentIssuer,
      stockLocationId: params.stockLocationId,
      financialPaymentMethodId: params.financialPaymentMethodId,
      financialOperationChangeId: params.financialOperationChangeId,
      financialOperationAdministrateTaxId: params.financialOperationAdministrateTaxId,
      financialPaymentConditionId: params.financialPaymentConditionId,
      paymentMethods: params.paymentMethods,
      packingListUnity: params.packingListUnity,
      packingListQuantity: params.packingListQuantity,
      packingListUnitaryValue: params.packingListUnitaryValue,
      packingListTotalValue: params.packingListTotalValue,
      administrateTaxAdditionalInfo: params.administrateTaxAdditionalInfo
    }
  };
};
export const operationDefaultBuilder = record => {
  let generatesMovement = record?.generatesMovement == undefined ? true : record?.generatesMovement;
  return {
    name: record?.name,
    kind: record?.kind,
    generatesMovement: generatesMovement,
    kindOfService: record?.kindOfService,
    parentId: (record?.parent || {}).id,
    convenienceStoreStockOperationId: (record?.convenienceStoreStockOperation || {}).id,
    stockOperationId: (record?.stockOperation || {}).id,
    stockLocationId: (record?.stockLocation || {}).id,
    financialPaymentMethodId: (record?.financialPaymentMethod || {}).id,
    financialOperationChangeId: (record?.financialOperationChange || {}).id,
    financialOperationAdministrateTaxId: (record?.financialOperationAdministrateTax || {}).id,
    financialPaymentConditionId: (record?.financialPaymentCondition || {}).id,
    financialOperationId: (record?.financialOperation || {}).id,
    operationTaxProfiles: record?.operationTaxProfiles,
    dfeModel: record?.dfeModel,
    onApproval: record?.onApproval || false,
    dfeSeries: record?.dfeSeries,
    issuancePurpose: record?.issuancePurpose || NORMAL_DFE,
    receiver: record?.receiver || ANYONE,
    taxDocumentIssuer: record?.taxDocumentIssuer || DOES_NOT_GENERATE_TAX_DOCUMENT,
    packingListUnity: record?.packingListUnity,
    packingListQuantity: record?.packingListQuantity,
    packingListUnitaryValue: record?.packingListUnitaryValue,
    packingListTotalValue: record?.packingListTotalValue,
    administrateTaxAdditionalInfo: record?.administrateTaxAdditionalInfo
  };
};
export const operationEntryDefaultBuilder = record => {
  let data = operationDefaultBuilder(record);
  data.kind = record?.kind || ENTRY;
  data.subkind = record?.subkind || PURCHASE;
  data.dfeModel = record?.dfeModel;
  data.taxDocumentIssuer = record?.taxDocumentIssuer || THIRD_PARTY_TAX_DOCUMENT;
  return data;
};
export const operationExitDefaultBuilder = record => {
  let data = operationDefaultBuilder(record);
  data.kind = record?.kind || EXIT, data.subkind = record?.subkind || SALES, data.isFor = record?.isFor == POINT_OF_SALES;
  data.convenienceStoreMode = record?.convenienceStoreMode;
  data.paymentMethods = (record?.paymentMethods || []).map(object => ({
    id: object.id
  }));
  return data;
};