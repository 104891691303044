import { BAR_CHART_TYPE, LINE_CHART_TYPE } from "../../constants/Charts";
import { getLabelByValue, uniq } from "../ListUtil";
import { buildMarkLine, formatter } from "./GenericChartUtil";
export const buildLineBarSeries = (data, seriesField, valueField, valueDataType, seriesFieldDictionary, chartType, xAxisData, xAxisField, markLineData, value2Field, value2DataType, seriesFieldName, series2FieldName) => {
  let series = [];
  uniq(data, seriesField).map(item => {
    series.push(buildSeries(data, item, seriesField, seriesFieldName, seriesFieldDictionary, valueField, valueDataType, chartType, xAxisData, xAxisField, markLineData));
    if (value2Field) series.push(buildSeries(data, item, seriesField, series2FieldName, seriesFieldDictionary, value2Field, value2DataType, chartType === BAR_CHART_TYPE ? LINE_CHART_TYPE : BAR_CHART_TYPE, xAxisData, xAxisField, [], 1));
  });
  return series;
};
export const buildSeries = (data, item, field, fieldName, dictionary, valueField, dataType, chartType, xAxisData, xAxisField, markLineData = [], yIndex = 0) => {
  return {
    name: dictionary ? getLabelByValue(dictionary, item) : fieldName ? fieldName : item,
    type: chartType,
    smooth: true,
    yAxisIndex: yIndex,
    data: buildXData(data, field, valueField, xAxisData, xAxisField, item),
    label: {
      formatter: (params, index) => formatter(params.value, dataType, params.seriesName + ': \n')
    },
    tooltip: {
      valueFormatter: value => formatter(value, dataType)
    },
    endLabel: {
      show: true,
      align: 'left',
      position: 'top',
      fontWeight: 'bold',
      fontSize: 12,
      overflow: 'breakAll',
      offset: [-100, -10]
    },
    markLine: buildMarkLine(markLineData, dataType)
  };
};
const buildXData = (data, seriesField, valueField, xAxisData, xAxisField, item) => {
  let seriesData = [];
  const records = data.filter(record => record[seriesField] === item);
  xAxisData.map(data => {
    const value = records.filter(record => record[seriesField] === item && record[xAxisField] === data)[0]?.[valueField] || 0;
    seriesData.push(value);
  });
  return seriesData;
};