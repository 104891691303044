import gql from "graphql-tag";
import { dailyBalanceFragment } from "grifo-components";
export const tradeDailyMovementFragment = gql`
  fragment TradeDailyMovement on TradeDailyMovement {
    id,
    inputs {
      ...DailyBalance
    },
    outputs {
      ...DailyBalance
    },
  }
  ${dailyBalanceFragment}  
`;