import gql from "graphql-tag";
import { NcmFragment } from "../fragments/Ncm";
export const GQL_NCMS_LIST = gql`
  query Search($pageSize: Int, $cursor: String, $ncmFilter: NcmFilter) {    
    ncmsPaginated(first: $pageSize, after: $cursor, ncmFilter: $ncmFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Ncm
        }
      }
    }    
  }
  ${NcmFragment}
`;
export const GQL_NCM_STORE = gql`
  mutation StoreNcm($attributes: NcmAttributes!) {
    storeNcm(input: {attributes: $attributes}) { 
      ncm { 
        ...Ncm,
      }, 
      errors 
    } 
  }
  ${NcmFragment}  
`;
export const GQL_ALL_NCM_SEARCH = gql`
  query Get($text: String) {    
    ncmSearch(all: $text) {
      ...Ncm
    }
  }
  ${NcmFragment}    
`;
export const GQL_NCM_ID_SEARCH = gql`
  query Get($id: ID) {    
    ncmSearch(id: $id) {
      ...Ncm
    }
  }
  ${NcmFragment}   
`;
export const GQL_IMPORT_NCMS = gql`
  mutation ImportNcms($attributes: NcmsImporterAttributes!) {
    importNcms(input: { attributes: $attributes }) {
      ncms {
        ...Ncm
      },
      errors
    }
  }
  ${NcmFragment}
`;