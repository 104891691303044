export const printConfigParams = (record, values) => {
  let name = values.name;
  if (!values.id) {
    if (record.name == values.name) name = values.name + ' duplicado';
  }
  return {
    attributes: {
      id: values.id,
      quantity: values?.quantity,
      name: name,
      width: values?.width,
      height: values?.height,
      fontSize: values?.fontSize,
      quantity: values?.quantity,
      columns: values?.columns,
      marginTop: values?.marginTop,
      marginRight: values?.marginRight,
      default: values?.default,
      showCode: values?.showCode
    }
  };
};
export const printBarcodeDefaultBuilder = record => {
  return {
    id: (record || {}).id,
    width: 1.2,
    height: 20,
    fontSize: 6,
    quantity: 80,
    columns: 4,
    marginTop: 4,
    marginRight: 40,
    showCode: true,
    default: false
  };
};