import gql from "graphql-tag";
import { nozzleFragment } from "../fragments/Nozzle";
export const GQL_NOZZLE_STORE = gql`
  mutation StoreNozzle($attributes: [NozzleAttributes!], $fuelPumpId: ID!) {
    storeNozzle(input: {attributes: $attributes, fuelPumpId: $fuelPumpId}) { 
      nozzles {
        ...Nozzle
      }, 
      errors 
    } 
  }
  ${nozzleFragment}   
`;