import gql from "graphql-tag";
import { addressFragment } from 'admin-front';
import { InvoiceAdditionalInfoFragment } from "./InvoiceAdditionalInfo";
import { InvoiceItemFragment } from "./InvoiceItem";
import { paymentFragment } from "./payment";
import { InvoiceVolumeFragment } from "./InvoiceVolume";
import { fiscalOperationFragment } from "./Operation";
import { fiscalPersonFragment } from "./Person";
import { InvoiceTaxFragment } from "./InvoiceTax";
import { InvoiceTaxWithheldFragment } from "./InvoiceTaxWithheld";
import { electronicDocumentFragment } from "./ElectronicDocument";
import { driverFragment } from "fleet-front";
import { vehicleSimpleFragment } from "fleet-front";
import { invoiceResultCenterFragment } from "./InvoiceResultCenter";
import { invoiceListingFragment } from "./InvoiceListing";
export const InvoiceFragment = gql`
  fragment Invoice on Invoice  { 
    id,
    ancestry,
    siblingsCount,
    differentSituation,
    differentParentSituation,
    situationBeforeGrouped,
    fromRequest,
    transactionId,
    precisionType,
    contractId,
    operationType,
    operationDestination,
    finalCostumerMethod,
    buyerPresenceIndicator,
    brokerIndicator,
    taxDocumentIssuer,
    issuancePurpose,    
    contributeIcms,
    generatedBy,
    date,
    issuanceDate,
    departureDate,
    deliveryDate,
    expirationDate,
    accessKey,
    itemsTotal,
    totalTaxes,
    freightValue,
    insuranceValue,
    discountValue,
    otherValue,
    administrateTax,
    total,
    deliveryMethod,
    pickupMethod,
    freightMethod,
    situation,
    invoiceNumber,
    dfeSeries,
    dfeModel,
    dfeNumber,
    canCancel,
    invoiceTaxWithheld {
      ...InvoiceTaxWithheld
    }
    operationId,
    operation {
      ...FiscalOperation
    }
    personId,
    person {
      ...FiscalPerson
    }
    address {
      ...Address
    }     
    receiver {
      ...FiscalPerson
    }
    pickupLocation {
      ...Address
    }    
    dispatcher {
      ...FiscalPerson
    }
    deliveryPlace {
      ...Address
    }
    shipper {
      ...Driver
    }
    vehicle {
      ...VehicleSimple
    }
    items {
      ...InvoiceItem
    }
    payments {
      ...Payment
    }
    infos {
      ...InvoiceAdditionalInfo
    }
    volumes {
      ...InvoiceVolume
    } 
    invoiceTax {
      ...InvoiceTax
    }   
    electronicDocument {
      ...ElectronicDocument
    }
    resultCenters {
      ...InvoiceResultCenter
    }
    children {
    ...InvoiceListing
    }
  }
  ${electronicDocumentFragment}
  ${InvoiceTaxWithheldFragment}
  ${fiscalPersonFragment}
  ${addressFragment}
  ${fiscalOperationFragment}  
  ${InvoiceItemFragment}
  ${paymentFragment}
  ${InvoiceAdditionalInfoFragment}
  ${InvoiceVolumeFragment}
  ${InvoiceTaxFragment}
  ${invoiceListingFragment}
  ${driverFragment}
  ${vehicleSimpleFragment}
  ${invoiceResultCenterFragment}
`;