export const vehicleBrandParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      vehicleModels: values.vehicleModels
    }
  };
};
export const vehicleBrandDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    vehicleModels: (record || {}).vehicleModels
  };
};