import gql from "graphql-tag";
export const InvoiceAdditionalInfoFragment = gql`
  fragment InvoiceAdditionalInfo on InvoiceAdditionalInfo { 
    id,
    target,
    origin,
    info,
    additionalInformationId,    
    personInformationId,    
  }
`;