export const fiscalProductPricingLogsParamBuilder = (record, values) => {
  return {
    attributes: {}
  };
};
export const fiscalProductPricingLogsDefaultBuilder = record => {
  return {
    id: (record || {}).id,
    datetime: (record || {}).datetime,
    averageCost: (record || {}).averageCost,
    productUnitId: (record || {}).productUnitId
  };
};