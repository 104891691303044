export const CUBIC_FOOTAGE = 'm3';
export const KILOGRAM = 'kg';
export const TONNE = 'ton';
export const UNIT = 'unit';
export const LITER = 'liter';
export const MILLION_BTU = 'mmbtu';
export const UNIT_FOR_TRANSPORT_OPTIONS = [{
  value: CUBIC_FOOTAGE,
  label: 'Metragem cúbica'
}, {
  value: KILOGRAM,
  label: 'Quilograma'
}, {
  value: TONNE,
  label: 'Tonelada'
}, {
  value: UNIT,
  label: 'Unidade'
}, {
  value: LITER,
  label: 'Litros'
}, {
  value: MILLION_BTU,
  label: 'Milhão de BTU'
}];