export const fuelPumpSupplierParamBuilder = (record, values) => {
  return {
    attributes: values.fuelPumpSuppliers.map(object => ({
      ...object,
      fuelPumpId: record.id
    })),
    fuelPumpId: record.id
  };
};
export const fuelPumpSupplierDefaultBuilder = record => {
  return {
    fuelPumpSuppliers: (record || {}).fuelPumpSuppliers
  };
};