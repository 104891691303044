import gql from "graphql-tag";
import { fiscalOperationSelectionFragment } from "./OperationSelection";
import { InvoiceItemListingFragment } from "./InvoiceItemListing";
import { fiscalPersonInvoiceListingFragment } from "./PersonInvoiceListing";
import { electronicDocumentFragment } from "./ElectronicDocument";
import { paymentFragment } from "./payment";
export const invoiceListingFragment = gql`
  fragment InvoiceListing on Invoice  { 
    id,
    ancestry,
    siblingsCount,
    differentSituation,
    differentParentSituation,
    situationBeforeGrouped,
    fromRequest,
    transactionId,
    operationType,
    operationDestination,
    finalCostumerMethod,
    buyerPresenceIndicator,
    brokerIndicator,
    taxDocumentIssuer,
    issuancePurpose,    
    contributeIcms,
    generatedBy,
    date,
    issuanceDate,
    departureDate,
    itemsTotal,
    totalTaxes,
    freightValue,
    insuranceValue,
    discountValue,
    otherValue,
    total,
    deliveryMethod,
    pickupMethod,
    freightMethod,
    situation,
    invoiceNumber,
    dfeSeries,
    dfeModel,
    dfeNumber,
    canCancel,
    receiverId,
    addressId,
    pickupLocationId, 
    dispatcherId,
    deliveryPlaceId,
    operationId,
    administrateTax,
    contractId,
    contract{
      number
    }
    electronicDocument {
      ...ElectronicDocument
    },   
    operation {
      ...FiscalOperationSelection
    }
    personId,
    person {
      ...FiscalPersonInvoiceListing
    }
    items {
      ...InvoiceItemListing
    }
    payments {
      ...Payment
    }
  }
  ${paymentFragment}
  ${electronicDocumentFragment}
  ${fiscalPersonInvoiceListingFragment}
  ${fiscalOperationSelectionFragment}  
  ${InvoiceItemListingFragment}
`;