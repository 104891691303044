import gql from "graphql-tag";
import { addressFragment, phoneFragment, userStoreSimpleFragment, contactFragment } from 'admin-front';
import { memberRegistrationFragment } from "cooperative-front";
import { personBankingFragment } from "financial-front";
import { personDocumentFragment } from "./PersonDocument";
export const fleetPersonSimpleFragment = gql`
  fragment FleetPersonSimple on FleetPerson {
    id,
    name,
    tradeName,
    document,
    email,
    gender,
    birth,
    closure,
    cadunicoCode,
    rntrcCode,
    nisCode,
    discardedAt,
    memberRegistration {
      ...MemberRegistration
    }
    mainAddress {
      ...Address
    },
    addresses {
      ...Address
    },
    phone {
      ...Phone
    },
    phones {
      ...Phone
    },    
    user {
      ...UserStoreSimple
    },
    ofCompany {
      id,
    }
    contacts {
      ...Contact
    },
    personBankings {
      ...PersonBanking
    },
    personDocuments {
      ...PersonDocument
    }
  }
  ${memberRegistrationFragment}
  ${addressFragment}
  ${phoneFragment}
  ${userStoreSimpleFragment}
  ${contactFragment}
  ${personBankingFragment}
  ${personDocumentFragment}
`;