export const FISCO = 'fisco';
export const TAXPAYER = 'tax_payer';
export const COMPANY = 'company';
export const PERSON = 'person';
export const TAX_PROFILE = 'tax_payer';
export const GENERAL = 'general';
export const TARGET_INTEREST = [{
  value: FISCO,
  label: 'Fisco'
}, {
  value: TAXPAYER,
  label: 'Contribuinte'
}];
export const INFO_ORIGIN = [{
  value: COMPANY,
  label: 'Informação adicional da Empresa'
}, {
  value: PERSON,
  label: 'Informação adicional da pessoa'
}, {
  value: TAX_PROFILE,
  label: 'Informação adicional do perfil tributário'
}, {
  value: GENERAL,
  label: 'Informação adicional sem origem específica'
}];