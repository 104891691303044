import gql from "graphql-tag";
import { FiscalServiceFragment } from "../fragments/FiscalService";
export const GQL_FISCAL_SERVICE_LIST = gql`
  query Search($pageSize: Int, $cursor: String, $serviceFilter: ServiceFilter) {
    fiscalServicesPaginated(first: $pageSize, after: $cursor, serviceFilter: $serviceFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FiscalService
        }
      }
    }    
  }
  ${FiscalServiceFragment}
`;
export const GQL_FISCAL_SERVICE_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Fiscal::FiscalService"}) {
      id,
      discardedAt
    }
  }
`;
export const GQL_FISCAL_SERVICE_STORE = gql`
  mutation StoreFiscalService($attributes: FiscalServiceAttributes!) {
    storeFiscalService(input: {attributes: $attributes}) { 
      fiscalService { 
        ...FiscalService,
      }, 
      errors 
    } 
  }
  ${FiscalServiceFragment}  
`;