import gql from "graphql-tag";
import { InvoiceFragment } from 'fiscal-front';
export const requestInvoiceOrderFragment = gql`
  fragment RequestInvoiceOrder on RequestInvoiceOrder  { 
    requestId,
    invoice {
      ...Invoice
    },
  }
  ${InvoiceFragment}
`;