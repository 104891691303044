import { MAIN, companyDefaultBuilder } from 'admin-front';
import { sanitize } from 'grifo-components';
export const fiscalCompanyParamBuilder = (record, values) => {
  let params = sanitize(values, ["city", "contact"]);
  params.phone.naming = "main";
  params.address.naming = MAIN;
  return {
    attributes: {
      id: record ? record.id : null,
      person: {
        name: params.name,
        tradeName: params.tradeName,
        document: params.document,
        photo64: params.photo64,
        email: params.email,
        phone: params.phone,
        address: params.address,
        personCnaes: params.cnaes,
        personFiscalSts: params.sts,
        personFiscalInfos: ((values || {}).infos || []).map(object => ({
          ...object,
          mandatory: object.mandatory === undefined ? true : object.mandatory
        })),
        personFiscalStart: params.fiscalStarts
      }
    }
  };
};
export const fiscalCompanyDefaultBuilder = record => {
  return Object.assign({}, companyDefaultBuilder(record), {
    personCnaes: (record || {}).cnaes,
    personFiscalSts: (record || {}).sts,
    personFiscalInfos: (record || {}).infos,
    personFiscalStart: (record || {}).fiscalStarts
  });
};