import gql from "graphql-tag";
import { commercialUnitFragment } from "./CommercialUnit";
export const productUnitSimpleForSearchFragment = gql`
  fragment ProductUnitSimpleForSearch on ProductUnit  {
    id,
    name,
    productId,
    commercialUnitId,
    parentId,
    barcode,
    multiplier,
    grossWeight,
    netWeight,
    height,
    width,
    length,
    discardedAt,
    packingKindId,
    productionTracking,
    commercialUnit {
      ...CommercialUnit
    }
  }
  ${commercialUnitFragment} 
`;