import gql from "graphql-tag";
import { vehicleModelFragment } from "./VehicleModel";
export const vehicleBrandFragment = gql`
  fragment VehicleBrand on VehicleBrand  { 
    id,
    name,
    discardedAt,
    vehicleModels {
      ...VehicleModel
    },
  }
  ${vehicleModelFragment}
`;