import gql from "graphql-tag";
import { paymentConditionFragment, paymentMethodSimpleFragment } from "financial-front";
export const paymentFragment = gql`
  fragment Payment on Payment  { 
    id,
    condition,
    methodKind,
    time, 
    timeKind, 
    installment,
    gracePeriod,
    valueToPay,
    fees,
    discount,
    total,
    methodFees,
    conditionFees,
    methodDiscount,
    conditionDiscount,
    methodFeesRate,
    conditionFeesRate,
    methodDiscountRate,
    conditionDiscountRate,
    paymentMethodId,
    paymentMethod {
      ...PaymentMethodSimple
    },
    paymentConditionId,
    paymentCondition {
      ...PaymentCondition
    },
  }
  ${paymentMethodSimpleFragment} 
  ${paymentConditionFragment}
`;