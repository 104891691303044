import gql from "graphql-tag";
import { retentionFragment } from "../fragments/Retention";
export const GQL_RETENTION_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    retentionsPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Retention
        }
      }
    }    
  }
  ${retentionFragment}
`;
export const GQL_RETENTION_STORE = gql`
  mutation StoreRetention($attributes: RetentionAttributes!) {
    storeRetention(input: {attributes: $attributes}) { 
      retention { 
        ...Retention,
      }, 
      errors 
    } 
  }
  ${retentionFragment}  
`;
export const GQL_RETENTION_SEARCH = gql`
  {
    retentions {
      ...Retention,
    }    
  }
  ${retentionFragment}
`;