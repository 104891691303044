import gql from "graphql-tag";
import { commercialUnitFragment, packingKindFragment, productFragment } from 'stock-front';
import { WaybillDocumentFragment } from "./WaybillDocument";
export const WaybillProductFragment = gql`
  fragment WaybillProduct on WaybillProduct  { 
    id,
    quantity,
    prevalent,
    measureType,
    unitForTransport,
    productId,
    product {
      ...Product
    }
    commercialUnitId,
    commercialUnit {
      ...CommercialUnit
    }
    packingKindId,
    packingKind {
      ...PackingKind
    }
    documents {
      ...WaybillDocument
    }   
  }
  ${productFragment}
  ${commercialUnitFragment}  
  ${packingKindFragment}
  ${WaybillDocumentFragment}  
`;