import gql from "graphql-tag";
import { fiscalCompanyFragment } from "../fragments/Company";
export const GQL_FISCAL_COMPANIES_LIST = gql`
  {
    fiscalCompanies {
      ...FiscalCompany,
      headOffice {
        ...FiscalCompany,      
      },
      branches {
        ...FiscalCompany,
      }      
    }    
  }
  ${fiscalCompanyFragment}
`;
export const GQL_FISCAL_COMPANY_LOAD = gql`
  query Get($id: ID) {    
    fiscalCompany(id: $id) {
      ...FiscalCompany
    }
  }
  ${fiscalCompanyFragment}   
`;
export const GQL_COMPANY_STORE = gql`
  mutation StoreFiscalCompany($attributes: FiscalCompanyAttributes!) {
    storeFiscalCompany(input: {attributes: $attributes}) { 
      fiscalCompany { 
        ...FiscalCompany,
      }, 
      errors 
    } 
  }
  ${fiscalCompanyFragment}  
`;