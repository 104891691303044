import gql from "graphql-tag";
import { locationFragment } from "./Location";
export const locationParentFragment = gql`
  fragment LocationParent on Location {
    ...Location
    children {
      ...Location,
      children {
        ...Location,
        children {
          ...Location,
          children {
            ...Location,
            children {
              ...Location,
            }
          }
        }
      }
    }
  }
  ${locationFragment} 
`;