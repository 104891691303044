import gql from "graphql-tag";
import { groupFragment } from "./Group";
import { fuelPumpBrandFragment } from "./FuelPumpBrand";
import { fuelPumpSupplierFragment } from "./FuelPumpSupplier";
import { fuelPumpSealFragment } from "./FuelPumpSeal";
import { nozzleFragment } from "./Nozzle";
export const fuelPumpFragment = gql`
  fragment FuelPump on FuelPump  {
    id,
    name,
    description,
    searchCode,
    measurement,
    discardedAt,
    groups {
      ...Group
    }
    fuelPumpBrands {
      ...FuelPumpBrand
    }
    fuelPumpSuppliers {
      ...FuelPumpSupplier
    }
    nozzles {
      ...Nozzle
    }
    fuelPumpSeals {
      ...FuelPumpSeal
    }
  }
  ${groupFragment}
  ${fuelPumpBrandFragment}
  ${nozzleFragment}
  ${fuelPumpSupplierFragment}  
  ${fuelPumpSealFragment}  
`;