import moment from 'moment';
import { dateOrToday, cloneObject, sanitize, getSessionInfo, sanitizeBackend } from 'grifo-components';
import { accountPaymentBuilder } from "financial-front";
import { COMPANY_LOCATION, GOODS_RETURN_DFE, NFE, NORMAL_DFE, OPENED, PERSON_LOCATION, WITHOUT_FREIGHT, ONLY_PREVIEW, ROUNDER, COSTUMER_FINAL, FINISHED } from "../constants/invoices/InvoiceFieldsOptions";
import { ICMS_TAXPAYER_EXEMPT } from "../constants/personFiscal/PersonFiscalOptions";
import { DEVOLUTION, INSIDE_STATE, IN_PERSON, MAKE_UP_TOTAL, NORMAL, WITHOUT_BROKER } from "../constants/taxProfile/TaxFieldsOptions";
import { invoiceItemParamBuilder } from "./InvoiceItemBuilder";
import { DOES_NOT_GENERATE_TAX_DOCUMENT, FINAL_COSTUMER } from '../constants/operations/OperationsFieldsOptions';
const finalCostumer = values => {
  if (values.finalCostumerMethod != undefined) return values.finalCostumerMethod;
  return values.operationSubkind == FINAL_COSTUMER ? COSTUMER_FINAL : NORMAL;
};
export const invoiceParamBuilder = (record, values) => {
  let situation = values.action || values.situation || record.situation;
  let totalTaxes = values?.totalTaxes;
  if (values?.invoiceTax?.totalTaxes > totalTaxes) totalTaxes = values.invoiceTax?.totalTaxes;
  if ((values.referencedDfe || values.referencedDfeNumber || values.referencedDfeSeries || values.referencedDfeDate) && values.operationSubkind == DEVOLUTION) {
    values = cloneObject(values, ['items', 'payments', 'infos', 'volumes', 'invoiceTax', 'electronicDocument', 'invoiceTaxWithheld']);
  }
  values.resultCenters = values.resultCenters ? values.resultCenters.filter(rc => rc.apportionment > 0) : undefined;
  values.resultCenters = values.resultCenters ? sanitize(values.resultCenters, ['resultCenter', 'value', 'description', 'name', 'children', 'parent', 'discardedAt']) : undefined;
  return {
    attributes: {
      id: record?.id,
      contractId: values.contractId,
      referencedDfe: values.referencedDfe,
      referencedDfeNumber: values.referencedDfeNumber,
      referencedDfeSeries: values.referencedDfeSeries,
      referencedDfeDate: values.referencedDfeDate,
      referencedDocumentId: values.referencedDocumentId,
      resultCenters: values.resultCenters ? values.resultCenters.map(object => ({
        ...object,
        companyId: getSessionInfo().company.id
      })) : undefined,
      invoiceNumber: values.invoiceNumber,
      dfeNumber: values.dfeNumber,
      dfeSeries: values.dfeSeries,
      dfeModel: values.dfeModel,
      accessKey: values.accessKey,
      precisionType: values.precisionType,
      currentAssetId: values.currentAssetId,
      document: values.document,
      change: values.change,
      personId: values.personId,
      addressId: values.addressId,
      pickupMethod: values.pickupMethod || COMPANY_LOCATION,
      receiverId: values.receiverId,
      pickupLocationId: values.pickupLocationId,
      deliveryMethod: values.deliveryMethod || PERSON_LOCATION,
      freightMethod: values.freightMethod || WITHOUT_FREIGHT,
      dispatcherId: values.dispatcherId,
      deliveryPlaceId: values.deliveryPlaceId,
      shipperId: values.shipperId,
      vehicleId: values.vehicleId,
      operationId: values.operationId,
      transactionId: values.transactionId,
      situation: situation,
      autoSendInvoice: values.autoSendInvoice,
      date: values.date ? values.date.format("YYYY-MM-DD") : undefined,
      issuanceDate: values.issuanceDate ? values.issuanceDate.format("YYYY-MM-DDTHH:mm:ss") : undefined,
      departureDate: values.departureDate ? values.departureDate.format("YYYY-MM-DDTHH:mm:ss") : undefined,
      deliveryDate: values.deliveryDate ? values.deliveryDate.format("YYYY-MM-DDTHH:mm:ss") : undefined,
      expirationDate: values.expirationDate ? values.expirationDate.format("YYYY-MM-DDTHH:mm:ss") : undefined,
      operationDestination: values.operationDestination || INSIDE_STATE,
      contributeIcms: values.contributeIcms || ICMS_TAXPAYER_EXEMPT,
      finalCostumerMethod: finalCostumer(values),
      buyerPresenceIndicator: values.buyerPresenceIndicator || IN_PERSON,
      brokerIndicator: values.brokerIndicator || WITHOUT_BROKER,
      taxDocumentIssuer: values.taxDocumentIssuer,
      issuancePurpose: values.issuancePurpose,
      itemsTotal: values.itemsTotal,
      amount: values.amount || values.itemsTotal,
      administrateTax: values.administrateTax,
      totalTaxes: totalTaxes,
      freightValue: values.freightValue,
      insuranceValue: values.insuranceValue,
      discountValue: 0,
      invoiceTax: values.invoiceTax || {
        freightValue: values.freightValue,
        insuranceValue: values.insuranceValue,
        itemsTotal: values.itemsTotal,
        icmsBasis: values.icmsBasis || 0,
        icmsValue: values.icmsValue || 0,
        icmsWithheldValue: values.icmsWithheldValue || 0,
        fcpValueStateDestination: values.fcpValueStateDestination || 0,
        icmsValueStateDestination: values.icmsValueStateDestination || 0,
        icmsValueStateOrigin: values.icmsValueStateOrigin || 0,
        fcpValue: values.fcpValue || 0,
        icmsBasisSt: values.icmsBasisSt || 0,
        icmsValueSt: values.icmsValueSt || 0,
        fcpWithheldValue: values.fcpWithheldValue || 0,
        pisValue: values.pisValue || 0,
        cofinsValue: values.cofinsValue || 0,
        ipiValue: values.ipiValue || 0,
        ipiReturnedValue: values.ipiReturnedValue || 0,
        iiValue: values.iiValue || 0,
        retentions: values.retentions || [],
        otherValue: values.otherValue || 0,
        freightValue: values.freightValue || 0,
        insuranceValue: values.insuranceValue || 0,
        discountValue: 0,
        total: values.total || 0,
        totalTaxes: totalTaxes || 0,
        itemsTotal: values.itemsTotal || 0
      },
      invoiceTaxWithheld: {
        pisValue: values.pisValue,
        cofinsValue: values.cofinsValue,
        csllValue: values.csllValue,
        irrfBasis: values.irrfBasis,
        irrfValue: values.irrfValue,
        socialSecurityBasis: values.socialSecurityBasis,
        socialSecurityValue: values.socialSecurityValue
      },
      otherValue: values.otherValue,
      total: values.total || 0,
      infos: values.infos || [],
      volumes: values.volumes || [],
      items: (values.items || []).filter(obj => obj.quantity > 0 || !values.contractId).map((object, index) => {
        return invoiceItemParamBuilder(object, index, situation);
      }),
      payments: values.payments,
      origin: values.origin
    }
  };
};
export const invoiceDefaultBuilder = (record, props = {}) => {
  const deliveryDate = (record || {}).deliveryDate;
  const expirationDate = (record || {}).expirationDate;
  const object = record || {};
  if (props.isCopy) {
    delete object.id;
    delete object.date;
    delete object.situation;
    delete object.transactionId;
    delete object.totalTaxes;
    delete object.invoiceTax;
    delete object.contractId;
    delete object.retentions;
    delete props.contract;
  }
  if (props.contract) {
    object.contractId = props.contract.id;
    object.operationId = props.contract.operation?.id;
    object.personId = props.contract.person?.id;
    object.payments = accountPaymentBuilder(object);
    object.address = props.contract.address;
    object.addressId = props.contract.address?.id;
    object.administrateTax = props.contract.administrateTax;
    object.infos = props.contract.infos ? props.contract.infos.map(object => {
      return {
        ...object,
        id: undefined
      };
    }) : [];
    object.items = (props.contract.products || []).filter(item => item.balance > 0).map(item => {
      return {
        productId: item.productId,
        productUnitId: item.productUnitId,
        invoiceIndex: item.contractIndex,
        quantity: 0,
        balance: item.balance,
        unitaryValue: item.unitaryValue,
        grossTotal: 0,
        locationId: item.locationId,
        batchId: undefined,
        discountValue: 0,
        freightValue: 0,
        insuranceValue: 0,
        otherExpenses: 0,
        totalInvoiceMethod: MAKE_UP_TOTAL,
        icms: {},
        productLabel: item.productName,
        total: 0,
        administrateTax: item.administrateTax
      };
    });
    object.categoryItems = (props.contract.categories || []).flatMap(category => (category.categoryItems || []).map(item => ({
      productId: item.productId,
      categoryName: category.name,
      categoryBalance: category.quantityBalance > 0 ? "Saldo Qtde: " + category.quantityBalance : "Saldo Valor: " + category.valueBalance,
      productUnitId: item.productUnitId,
      invoiceIndex: item.contractIndex || 0,
      quantity: 0,
      balance: item.balance,
      unitaryValue: item.unitaryValue || 0,
      grossTotal: 0,
      locationId: item.locationId || 1,
      batchId: undefined,
      discountValue: 0,
      freightValue: 0,
      insuranceValue: 0,
      otherExpenses: 0,
      totalInvoiceMethod: MAKE_UP_TOTAL,
      icms: {},
      productLabel: item.productName,
      total: 0,
      administrateTax: item.administrateTax
    })));
    let categoryItems = object.categoryItems;
    object.items = object.items.concat(categoryItems);
    object.itemsTotal = object.items.reduce((accumulator, item) => {
      return accumulator + item.total;
    }, 0);
    object.total = object.itemsTotal;
  }
  let data = {
    referencedDfe: object?.electronicDocument?.accessKey || object.referencedDfe,
    contractId: object.contractId,
    invoiceNumber: object.invoiceNumber,
    dfeNumber: object.dfeNumber,
    changeInvoiceTax: false,
    autoSendInvoice: true,
    dfeSeries: object.dfeSeries,
    precisionType: object.precisionType || ROUNDER,
    dfeModel: object.dfeModel || props.dfeModel || NFE,
    accessKey: object.accessKey,
    personId: object.personId,
    addressId: object.addressId || object?.address?.id,
    address: object?.address,
    receiverId: (object.receiver || {}).id,
    pickupLocationId: (object.pickupLocation || {}).id,
    dispatcherId: (object.dispatcher || {}).id,
    deliveryPlaceId: (object.deliveryPlace || {}).id,
    pickupMethod: object.pickupMethod || COMPANY_LOCATION,
    deliveryMethod: object.deliveryMethod || PERSON_LOCATION,
    freightMethod: object.freightMethod || WITHOUT_FREIGHT,
    shipperId: (object.shipper || {}).id,
    vehicleId: (object.vehicle || {}).id,
    operationId: object.operationId,
    transactionId: object.transactionId,
    situation: object.situation || OPENED,
    action: props.action,
    operationType: object.operationType,
    operationDestination: object.operationDestination || INSIDE_STATE,
    contributeIcms: object.contributeIcms || ICMS_TAXPAYER_EXEMPT,
    finalCostumerMethod: object.finalCostumerMethod,
    buyerPresenceIndicator: object.buyerPresenceIndicator || IN_PERSON,
    brokerIndicator: object.brokerIndicator || WITHOUT_BROKER,
    date: dateOrToday(object.date),
    issuanceDate: dateOrToday(object.issuanceDate),
    departureDate: dateOrToday(object.departureDate),
    deliveryDate: sanitizeBackend(deliveryDate),
    expirationDate: sanitizeBackend(expirationDate),
    issuancePurpose: object.issuancePurpose || NORMAL_DFE,
    taxDocumentIssuer: object.taxDocumentIssuer || DOES_NOT_GENERATE_TAX_DOCUMENT,
    itemsTotal: object.itemsTotal || 0,
    administrateTax: object.administrateTax,
    totalTaxes: object.totalTaxes || 0,
    freightValue: object.freightValue || 0,
    insuranceValue: object.insuranceValue || 0,
    discountValue: object.discountValue || 0,
    otherValue: object.otherValue || 0,
    change: object.change || 0,
    totalBeforePayment: (object.freightValue || 0) + (object.insuranceValue || 0) + (object.change || 0) + (object.otherValue || 0) + (object.itemsTotal || 0) - (object.discountValue || 0),
    total: object.total || 0,
    infos: props.isCopy ? cloneObject(object.infos) : object.infos || [],
    invoiceTaxWithheld: object.invoiceTaxWithheld || {
      pisValue: 0,
      cofinsValue: 0,
      csllValue: 0,
      irrfBasis: 0,
      irrfValue: 0,
      socialSecurityBasis: 0,
      socialSecurityValue: 0
    },
    generatedBy: object.generatedBy,
    volumes: object.volumes || [],
    items: props.isCopy ? (() => {
      const clonedItems = cloneObject(object.items);
      if (clonedItems) {
        const deleteAttibutes = clonedItems.map(item => {
          delete item.totalTaxes;
          delete item.icms;
          delete item.cofins;
          delete item.pis;
          delete item.ipi;
          delete item.retentions;
          return item;
        });
        return deleteAttibutes;
      }
      return clonedItems || [];
    })() : object.items || [{
      quantity: 1,
      productUnitId: undefined,
      invoiceIndex: object?.items?.length || 0 + 1 || 1,
      discountValue: 0,
      freightValue: 0,
      insuranceValue: 0,
      otherExpenses: 0,
      totalInvoiceMethod: MAKE_UP_TOTAL,
      icms: {}
    }],
    payments: props.isCopy ? (() => {
      const clonedPayment = cloneObject(object.payments);
      if (clonedPayment) {
        const deleteAttibutes = clonedPayment.map(payment => {
          delete payment.paymentMethod;
          delete payment.paymentCondition;
          return payment;
        });
        return deleteAttibutes;
      }
      return clonedPayment || [];
    })() : object.children?.length > 0 && object.situation == FINISHED ? (() => {
      const cpayments = object.children.map(invoicec => {
        const deleteAttibutes = invoicec.payments.map(payment => {
          delete payment.paymentMethod;
          delete payment.paymentCondition;
          return payment;
        });
        return deleteAttibutes;
      });
      return [].concat(...cpayments);
    })() : accountPaymentBuilder(object),
    invoiceIds: object.invoiceIds,
    resultCenters: object.resultCenters
  };
  return data;
};
export const referencedInvoiceBuilder = (record, props = {}) => {
  const object = record || {};
  return {
    referencedDfe: object?.electronicDocument?.accessKey || object.referencedDfe,
    referencedDocumentId: object.referencedDocumentId || object.id,
    invoiceNumber: object.invoiceNumber,
    dfeNumber: object.dfeNumber,
    dfeSeries: object.dfeSeries,
    dfeModel: object.dfeModel || props.dfeModel || NFE,
    personId: object.personId,
    precisionType: object.precisionType,
    addressId: (object.address || {}).id,
    receiverId: (object.receiver || {}).id,
    pickupLocationId: (object.pickupLocation || {}).id,
    dispatcherId: (object.dispatcher || {}).id,
    deliveryPlaceId: (object.deliveryPlace || {}).id,
    pickupMethod: object.pickupMethod || COMPANY_LOCATION,
    deliveryMethod: object.deliveryMethod || PERSON_LOCATION,
    freightMethod: object.freightMethod || WITHOUT_FREIGHT,
    shipperId: (object.shipper || {}).id,
    vehicleId: (object.vehicle || {}).id,
    operationId: object.operationId,
    transactionId: object.transactionId,
    situation: object.situation || OPENED,
    action: props.action,
    operationType: object.operationType,
    operationDestination: object.operationDestination || INSIDE_STATE,
    contributeIcms: object.contributeIcms || ICMS_TAXPAYER_EXEMPT,
    finalCostumerMethod: object.finalCostumerMethod || NORMAL,
    buyerPresenceIndicator: object.buyerPresenceIndicator || IN_PERSON,
    brokerIndicator: object.brokerIndicator || WITHOUT_BROKER,
    date: moment(),
    issuanceDate: moment(),
    departureDate: moment(),
    deliveryDate: moment(),
    expirationDate: moment(),
    issuancePurpose: GOODS_RETURN_DFE,
    itemsTotal: object.itemsTotal || 0,
    totalTaxes: object.totalTaxes || 0,
    freightValue: object.freightValue || 0,
    insuranceValue: object.insuranceValue || 0,
    discountValue: object.discountValue || 0,
    otherValue: object.otherValue || 0,
    change: object.change || 0,
    totalBeforePayment: (object.freightValue || 0) + (object.insuranceValue || 0) + (object.change || 0) + (object.otherValue || 0) + (object.itemsTotal || 0) - (object.discountValue || 0),
    total: object.total || 0,
    infos: object.infos || [],
    invoiceTaxWithheld: object.invoiceTaxWithheld,
    generatedBy: object.generatedBy,
    volumes: object.volumes || [],
    items: object.items.map(item => {
      item.originalQuantity = item.quantity;
      delete item.icms?.taxBenefitCode;
      return {
        ...item
      };
    }) || [{
      quantity: 1,
      productUnitId: undefined,
      invoiceIndex: (object?.items?.length || 0) + 1,
      discountValue: 0,
      freightValue: 0,
      insuranceValue: 0,
      otherExpenses: 0,
      totalInvoiceMethod: MAKE_UP_TOTAL,
      icms: {}
    }],
    payments: accountPaymentBuilder(object),
    invoiceIds: object.invoiceIds
  };
};
export const invoiceConsultationParamsBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null
    }
  };
};
export const invoiceConsultationdefaultValuesBuilder = record => {
  return {};
};