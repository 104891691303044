import gql from "graphql-tag";
export const InvoiceTaxFragment = gql`
  fragment InvoiceTax on InvoiceTax { 
    id,
    icmsBasis,
    icmsValue,
    icmsWithheldValue,
    fcpValueStateDestination,
    icmsValueStateDestination,
    icmsValueStateOrigin,
    fcpValue,
    icmsBasisSt,
    icmsValueSt,
    fcpWithheldValue,
    itemsTotal,
    freightValue,
    insuranceValue,
    discountValue,
    iiValue,
    ipiValue,
    ipiReturnedValue,
    pisValue,
    cofinsValue,
    otherValue,
    total,
    totalTaxes
  }
`;