import gql from "graphql-tag";
import { taxProfileCofinsFragment } from "../fragments/TaxProfileCofins";
export const GQL_TAX_PROFILE_COFINS_STORE = gql`
  mutation StoreTaxProfileCofins($attributes: TaxProfileCofinsAttributes!, $taxProfileId: ID!) {
    storeTaxProfileCofins(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      cofins {
        ...TaxProfileCofins
      }, 
      errors 
    } 
  }
  ${taxProfileCofinsFragment}   
`;