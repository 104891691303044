import gql from "graphql-tag";
import { batchFragment, locationSimpleFragment } from 'stock-front';
import { fiscalProductInvoiceListingFragment } from "./ProductInvoiceListing";
import { fiscalProductUnitInvoiceListingFragment } from "./ProductUnitInvoiceListing";
export const InvoiceItemListingFragment = gql`
  fragment InvoiceItemListing on InvoiceItem  { 
    id,
    totalInvoiceMethod,
    cooperativeAct,
    invoiceIndex,
    quantity,
    totalReturned,
    returnedPercentage,
    unitaryValue,
    grossTotal,
    taxableQuantity,
    taxableUnitaryValue,
    taxableGrossTotal,
    freightValue,
    insuranceValue,
    discountValue,
    otherExpenses,
    totalTaxes,
    total,
    manufacturingDate,  
    productId,
    administrateTax,
    product {
      ...FiscalProductInvoiceListing
    }
    productUnitId,
    productUnit {
      ...FiscalProductUnitInvoiceListing
    }
    batchId,
    batch {
      ...Batch
    }
    locationId,
    location {
      ...LocationSimple
    }
  }
  ${fiscalProductInvoiceListingFragment}
  ${fiscalProductUnitInvoiceListingFragment}
  ${batchFragment}  
  ${locationSimpleFragment}  
`;