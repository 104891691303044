import gql from "graphql-tag";
import { ipiLegalFrameworkFragment } from "./IpiLegalFramework";
export const taxProfileIpiFragment = gql`
  fragment TaxProfileIpi on TaxProfileIpi  { 
    id,
    rate,
    rateByValue,
    kind,
    taxSituation,
    calculationMethod,
    ipiLegalFrameworkId,
    ipiLegalFramework {
      ...IpiLegalFramework
    }
  }
  ${ipiLegalFrameworkFragment}
`;