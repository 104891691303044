export const groupParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      parentId: values.parentId
    }
  };
};
export const groupDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    parentId: ((record || {}).parent || {}).id
  };
};