import gql from "graphql-tag";
import { WaybillIcmsFragment } from "./WaybillIcms";
import { WaybillIcmsDestinationFragment } from "./WaybillIcmsDestination";
import { WaybillIcmsOriginFragment } from "./WaybillIcmsOrigin";
export const waybillTaxesCalculationFragment = gql`
  fragment WaybillTaxesCalculation on WaybillTaxesCalculation {
    errors,
    totalTaxes,
    icms {
      ...WaybillIcms
    }
    icmsOrigin {
      ...WaybillIcmsOrigin
    }
    icmsDestination {
      ...WaybillIcmsDestination
    }
  }
  ${WaybillIcmsFragment}
  ${WaybillIcmsOriginFragment}
  ${WaybillIcmsDestinationFragment}
`;