import gql from "graphql-tag";
export const InvoiceTaxWithheldFragment = gql`
  fragment InvoiceTaxWithheld on InvoiceTaxWithheld  { 
    id,
    pisValue,
    cofinsValue,
    csllValue,
    irrfBasis,
    irrfValue,
    socialSecurityBasis,
    socialSecurityValue,
  }
`;