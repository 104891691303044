import gql from "graphql-tag";
import { reportFragment } from 'admin-front';
import { WaybillFragment } from "../fragments/Waybill";
import { waybillTaxesCalculationFragment } from "../fragments/WaybillTaxesCalculation";
export const GQL_WAYBILL_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $waybillFilter: WaybillFilter) {    
    waybillsPaginated(first: $pageSize, after: $cursor, waybillFilter: $waybillFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Waybill
        }
      }
    }    
  }
  ${WaybillFragment}
`;
export const GQL_WAYBILL_STORE = gql`
  mutation StoreWaybill($attributes: WaybillAttributes!) {
    storeWaybill(input: {attributes: $attributes}) { 
      waybill {
        ...Waybill,
      }, 
      errors 
    } 
  }
  ${WaybillFragment}
`;
export const GQL_WAYBILL_SEARCH = gql`
  query Search($filter: WaybillFilter) {    
    waybills(filter: $filter) {
      ...Waybill
    }
  }
  ${WaybillFragment}
`;
export const GQL_WAYBILL_FROM_VEHICLE = gql`
  query Search($byVehicle: ID) {    
    waybills(byVehicle: $byVehicle) {
      ...Waybill
    }
  }
  ${WaybillFragment}
`;
export const GQL_WAYBILL_BY_ACCESS_KEY = gql`
  query GetByAccessKey($accessKey: ID!) {
    waybill(accessKey: $accessKey) {
      ...Waybill
    }
  }
  ${WaybillFragment}
`;
export const GQL_GENERATE_DACTE = gql`
  query GenerateDacte($id: ID!) {    
    generateDacte(id: $id) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_PRINT_DACTE = gql`
  query PrintDacte($id: ID!) {    
    printDacte(id: $id) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_CREATE_DACTE = gql`
  mutation CreateDacte($waybillId: ID!) {
    createDacte(input: {waybillId: $waybillId}) { 
      waybill {
        ...Waybill,
      }, 
      errors 
    } 
  }
  ${WaybillFragment}
`;
export const GQL_CONSULTATION_DACTE = gql`
  mutation StoreWaybillConsultation($id: ID!, $kind: String) {
    storeWaybillConsultation(input:{ id: $id, kind: $kind}) {
      waybill {
        ...Waybill,
      }, 
      errors
    }
  }
  ${WaybillFragment}
`;
export const GQL_WAYBILL_TAXES_CALCULATION = gql`
  query Calculate($attributes: FilterWaybillTaxesCalc) {    
    waybillTaxesCalc(attributes: $attributes) {
      ...WaybillTaxesCalculation
    }
  }
  ${waybillTaxesCalculationFragment}
`;