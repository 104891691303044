import { NORMAL } from "../constants/operations/OperationsSubkindFieldsOptions";
export const operationParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      kind: values.kind,
      subkind: values.subkind,
      generatesMovement: values.generatesMovement,
      subkind: values.subkind,
      internal: values.internal,
      parentId: values.parentId
    }
  };
};
export const operationDefaultBuilder = record => {
  const object = record || {};
  let generatesMovement = object.generatesMovement == undefined ? true : object.generatesMovement;
  return {
    name: object.name,
    kind: object.kind,
    subkind: object.subkind,
    generatesMovement: generatesMovement,
    subkind: object.subkind || NORMAL,
    internal: object.internal || false,
    parentId: object.parent?.id
  };
};