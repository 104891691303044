import gql from "graphql-tag";
import { trailerFragment } from 'fleet-front';
export const WaybillTrailerFragment = gql`
  fragment WaybillTrailer on WaybillTrailer  { 
    id,
    unitType,
    trailerId,
    trailer {
      ...Trailer
    },
  }
  ${trailerFragment}
`;