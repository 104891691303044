import { DONUT_CHART_TYPE, FLOAT_FORMAT, PIE_CHART_TYPE } from "../../constants/Charts";
import { getLabelByValue, uniq } from "../ListUtil";
import { formatter } from "./GenericChartUtil";
export const buildPieSeries = (data, seriesField, valueField, valueDataType, seriesFieldDictionary, chartType, heading) => {
  return [{
    name: heading,
    type: PIE_CHART_TYPE,
    selectedMode: 'multiple',
    radius: ['30%', '50%'],
    center: chartType === DONUT_CHART_TYPE ? ['50%', '70%'] : ['50%', '50%'],
    startAngle: chartType === DONUT_CHART_TYPE ? 180 : 0,
    roseType: false,
    itemStyle: {
      borderRadius: 8
    },
    data: buildSeriesData(data, seriesField, valueField, valueDataType, seriesFieldDictionary, chartType)
  }];
};
const buildSeriesData = (data, seriesField, valueField, valueDataType, seriesFieldDictionary, chartType) => {
  let total = 0;
  let seriesData = [];
  uniq(data, seriesField).map(fieldValue => {
    const value = data.filter(item => item[seriesField] === fieldValue).reduce(function (acc, obj) {
      return acc + obj[valueField];
    }, 0);
    seriesData.push({
      name: seriesFieldDictionary ? getLabelByValue(seriesFieldDictionary, fieldValue) : fieldValue,
      value: value,
      label: {
        show: true,
        formatter(param) {
          return formatter(param.value, valueDataType, param.name + ': ') + '\n (' + formatter(param.percent * (chartType === DONUT_CHART_TYPE ? 2 : 1), FLOAT_FORMAT) + '%)';
        }
      }
    });
    total += value;
  });
  if (chartType === DONUT_CHART_TYPE) seriesData.push(buildDonutFakeSeries(total));
  return seriesData;
};
const buildDonutFakeSeries = total => {
  return {
    value: total,
    itemStyle: {
      color: 'none',
      decal: {
        symbol: 'none'
      }
    },
    label: {
      show: false
    }
  };
};