import gql from "graphql-tag";
export const InvoiceItemIcmsSharedFragment = gql`
  fragment InvoiceItemIcmsShared on InvoiceItemIcmsShared  { 
    id,
    stateId,
    goodsOrigin,
    taxSituation,
    methodBasis,
    basis,
    value,
    basisReductionPercentage,
    rate,
    methodBasisSt,
    mvaRate,
    basisReductionPercentageSt,
    basisSt,    
    rateSt,
    valueSt,    
  }  
`;