import gql from "graphql-tag";
import { productBrandFragment } from "../fragments/ProductBrand";
export const GQL_PRODUCT_BRAND_STORE = gql`
  mutation StoreProductBrand($attributes: [ProductBrandAttributes!], $productId: ID!) {
    storeProductBrand(input: {attributes: $attributes, productId: $productId}) { 
      productBrands {
        ...ProductBrand
      }, 
      errors 
    } 
  }
  ${productBrandFragment}   
`;