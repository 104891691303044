export const personFiscalStartParamBuilder = (record, values) => {
  return {
    attributes: values.personFiscalStart.map(object => ({
      ...object,
      personId: record.id
    }))
  };
};
export const personFiscalStartDefaultBuilder = record => {
  const object = record || {};
  return {
    fiscalStarts: object.fiscalStarts || []
  };
};