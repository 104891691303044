import moment from "moment";
export const fuelPumpSealParamBuilder = (record, values) => {
  return {
    attributes: ((values || {}).fuelPumpSeals || []).map(object => ({
      ...object,
      fuelPumpId: record.id
    })),
    fuelPumpId: record.id
  };
};
export const fuelPumpSealDefaultBuilder = record => {
  return {
    fuelPumpSeals: ((record || {}).fuelPumpSeals || []).map(object => ({
      ...object,
      date: moment(object.date)
    }))
  };
};