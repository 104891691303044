import gql from "graphql-tag";
import { fuelPumpSupplierFragment } from "../fragments/FuelPumpSupplier";
export const GQL_FUEL_PUMP_SUPPLIER_STORE = gql`
  mutation StoreFuelPumpSupplier($attributes: [FuelPumpSupplierAttributes!], $fuelPumpId: ID!) {
    storeFuelPumpSupplier(input: {attributes: $attributes, fuelPumpId: $fuelPumpId}) { 
      fuelPumpSuppliers {
        ...FuelPumpSupplier
      }, 
      errors 
    } 
  }
  ${fuelPumpSupplierFragment}   
`;