export const vehicleModelParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      brandId: values.brandId
    }
  };
};
export const vehicleModelDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    brand: (record || {}).brand,
    brandId: ((record || {}).brand || {}).id
  };
};