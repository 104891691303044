export const vehicleBodyworkParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      description: values.description,
      axis: values.axis,
      integer: values.integer,
      tires: values.tires,
      weight: values.weight,
      float: values.float,
      width: values.width,
      height: values.height,
      length: values.length
    }
  };
};
export const vehicleBodyworkDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    description: (record || {}).description,
    axis: (record || {}).axis,
    integer: (record || {}).integer,
    tires: (record || {}).tires,
    weight: (record || {}).weight,
    float: (record || {}).float,
    width: (record || {}).width,
    height: (record || {}).height,
    length: (record || {}).length
  };
};