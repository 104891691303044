import gql from "graphql-tag";
export const electronicDocumentCorrectionFragment = gql`
  fragment ElectronicDocumentCorrection on ElectronicDocumentCorrection {
    id,
    sequential,
    protocol,
    correction,
    cstat,
    returnedMessage,
    accessKey
  }
`;