import { sanitizeBackend } from 'grifo-components';
export const personFiscalInfosParamBuilder = (record, values) => {
  return {
    attributes: values.infos.map(object => ({
      ...object,
      personId: record.id
    }))
  };
};
export const personFiscalInfosDefaultBuilder = record => {
  return {
    infos: ((record || {}).infos || []).map(object => ({
      ...object,
      start: sanitizeBackend(object.start),
      finish: sanitizeBackend(object.finish)
    }))
  };
};