import gql from "graphql-tag";
import { TaxProfileFragment } from "./TaxProfile";
export const invoiceItemPisFragment = gql`
  fragment InvoiceItemPis on InvoiceItemPis  { 
    id,
    taxSituation,
    basis,
    rate,
    quantity,
    rateByValue,
    value,
    basisReductionPercentage,
    taxProfile {
      ...TaxProfile
    }
  }  
  ${TaxProfileFragment}  
`;