import gql from "graphql-tag";
import { productPricingLogFragment } from "../fragments/ProductPricingLog";
export const GQL_SEARCH_PRODUCT_PRICING_LOGS = gql`
query ProductPricingLog($productUnitId: ID) {    
  productPricingLog(productUnitId: $productUnitId) {
    ...ProductPricingLog
  }
}
${productPricingLogFragment}

`;