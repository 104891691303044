import gql from "graphql-tag";
import { bidFragment } from "../fragments/Bid";
export const GQL_SEARCH_BIDS = gql`
  query GetByName($name: String) {    
    bids(name: $name) { 
        ...Bid
    }
  }
  ${bidFragment}   
`;
export const GQL_BIDS_LIST = gql`
  {
    bids { 
      ...Bid
    }
  }
  ${bidFragment}   
`;
export const GQL_CANCEL_BID = gql`
mutation CancelBid($id: ID!) { 
  cancelBid(input: {id: $id}) {
    id,
    errors
  }
}
`;
export const GQL_ACCEPT_BID = gql`
mutation AcceptBid($id: ID!) { 
  acceptBid(input: {id: $id}) {
    id,
    errors
  }
}
`;
export const GQL_CANCEL_BY_COMPANY_BID = gql`
mutation CancelBidByCompany($id: ID!, $reason: String) { 
  cancelBidByCompany(input: {id: $id, reason: $reason}) {
    id,
    errors
  }
}
`;
export const GQL_DENY_BID = gql`
mutation DenyBid($id: ID!, $reason: String) { 
  denyBid(input: {id: $id, reason: $reason}) {
    id,
    errors
  }
}
`;
export const GQL_BID_STORE = gql`
  mutation StoreBid($attributes: BidAttributes!) {
    storeBid(input: {attributes: $attributes}) { 
      bid {
        ...Bid
    }, 
      errors 
    } 
  }
  ${bidFragment}   
`;
export const GQL_BID_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Acquisition::Bid"}) {
      id,
      discardedAt
    }
  }
  ${bidFragment}   
`;