import { HEATMAP_CHART_TYPE } from "../../constants/Charts";
import { getLabelByValue } from "../ListUtil";
import { formatter } from "./GenericChartUtil";
export const buildHeatmapSeries = (seriesData, valueField, valueDataType, xAxisField, yAxisField, yAxisFieldDictionary) => {
  const data = seriesData.map(item => {
    return [item[xAxisField], yAxisFieldDictionary ? getLabelByValue(yAxisFieldDictionary, item[yAxisField]) : item[yAxisField], item[valueField] || '-'];
  });
  return [{
    name: 'Vendas',
    type: HEATMAP_CHART_TYPE,
    data: data,
    label: {
      show: true,
      formatter: (params, index) => {
        return formatter(params.value[2], valueDataType);
      }
    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  }];
};