import gql from "graphql-tag";
import { InvoiceItemAdditionalInfoFragment } from "../fragments/InvoiceItemAdditionalInfo";
export const GQL_INVOICE_ITEM_ADDITIONAL_INFO_LIST = gql`
  {
    invoiceItemAdditionalInfo {
      ...InvoiceItemAdditionalInfo,
    }
  }
  ${InvoiceItemAdditionalInfoFragment}
`;
export const GQL_INVOICE_ITEM_ADDITIONAL_INFO_STORE = gql`
  mutation StoreInvoiceItemAdditionalInfo($attributes: InvoiceItemAdditionalInfoAttributes!) {
    storeInvoiceItemAdditionalInfo(input: {attributes: $attributes}) {
      invoiceItemAdditionalInfo {
        ...InvoiceItemAdditionalInfo,
      },
      errors
    }
  }
  ${InvoiceItemAdditionalInfoFragment}
`;