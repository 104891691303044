export { default as BrandPage } from './brands/BrandPage';
export { default as BrandsPage } from './brands/BrandsPage';
export { default as CommercialUnitPage } from './commercialUnits/CommercialUnitPage';
export { default as CommercialUnitsPage } from './commercialUnits/CommercialUnitsPage';
export { default as GroupPage } from './groups/GroupPage';
export { default as GroupsPage } from './groups/GroupsPage';
export { default as LocationPage } from './locations/LocationPage';
export { default as LocationsPage } from './locations/LocationsPage';
export { default as MovementPage } from './movements/MovementPage';
export { default as MovementsPage } from './movements/MovementsPage';
export { default as OperationPage } from './operations/OperationPage';
export { default as StockOperationsPage } from './operations/OperationsPage';
export { default as PackingKindPage } from './packingKinds/PackingKindPage';
export { default as PackingKindsPage } from './packingKinds/PackingKindsPage';
export { default as ProductBrandsPage } from './products/ProductBrandsPage';
export { default as ProductPage } from './products/ProductPage';
export { default as ProductsPage } from './products/ProductsPage';
export { default as ProductSuppliersPage } from './products/ProductSuppliersPage';
export { default as ProductUnitPage } from './products/ProductUnitPage';
export { default as ProductUnitsPage } from './products/ProductUnitsPage';
export { default as DashboardStockPage } from './Dashboard';