import gql from "graphql-tag";
import { customerFragment } from "../fragments/Customer";
export const GQL_CUSTOMERS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    customersPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Customer
        }
      }
    }    
  }
  ${customerFragment}
`;
export const GQL_CUSTOMER_STORE = gql`
  mutation StoreCustomer($attributes: FleetPersonAttributes!) {
    storeCustomer(input: {attributes: $attributes}) { 
      customer {
        ...Customer
    }, 
      errors 
    } 
  }
  ${customerFragment}   
`;
export const GQL_CUSTOMER_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: { id: $id, className: "Fleet::Person" }) {
      id,
      discardedAt
    }
  }
`;