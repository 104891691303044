import gql from "graphql-tag";
import { requestFragment } from "./Request";
export const parentRequestFragment = gql`
  fragment ParentRequest on Request {
    ...Request,
    parent {
      ...Request,
    },
    children {
      ...Request,
      children {
        ...Request,
        children {
          ...Request,
          children {
            ...Request,
            children {
              ...Request,
            }
          }
        }
      }
    }    
  }
  ${requestFragment}
`;