import gql from "graphql-tag";
export const fiscalProductInvoiceListingFragment = gql`
  fragment FiscalProductInvoiceListing on FiscalProduct  {
    id,
    name,
    description,
    searchCode,
    discardedAt,
    kind,
    nveCode,
    anpCode,
    codifCode,
    recopiCode,
    goodsOrigin,
    glpPercentage,
    glgnPercentage,
    importedGlgnPercentage,
    destinationType,
    productionScale,
    ipiExceptionCode,
    anpProductDescription,
  }
`;