import moment from 'moment';
export const personVehicleParamBuilder = (record, values) => {
  return {
    attributes: values.driverVehicles,
    personId: record.id
  };
};
export const personVehicleDefaultBuilder = record => {
  return {
    driverVehicles: ((record || {}).driverVehicles || []).map(object => ({
      ...object,
      id: object.id,
      vehicleId: ((object || {}).vehicle || {}).id,
      onwer: (object || {}).owner,
      turnOwner: !(object || {}).owner || true,
      start: object.start ? moment(object.start) : null,
      finish: object.finish ? moment(object.finish) : null,
      renavamCode: ((object || {}).vehicle || {}).renavamCode,
      plate: ((object || {}).vehicle || {}).plate,
      vin: ((object || {}).vehicle || {}).vin,
      vehicleBrandId: ((object || {}).vehicle || {}).vehicleBrandId,
      vehicleModelId: ((object || {}).vehicle || {}).vehicleModelId,
      vehicleTypeId: ((object || {}).vehicle || {}).vehicleTypeId,
      tracker: ((object || {}).vehicle || {}).tracker || true,
      owner: ((object || {}).vehicle || {}).owner || true,
      manufactureYear: (((object || {}).vehicle || {}).manufactureYear || {}).toString() || null,
      modelYear: (((object || {}).vehicle || {}).modelYear || {}).toString() || null,
      identifier: ((object || {}).vehicle || {}).identifier
    }))
  };
};