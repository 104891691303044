import moment from 'moment';
import { sanitize } from 'grifo-components';
export const trailerParamBuilder = (record, values) => {
  return {
    attributes: trailerAttributesBuilder(record, values)
  };
};
export const trailerAttributesBuilder = (record, values) => {
  const owner = values.owner;
  const turnOwner = values.turnOwner;
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    id: record ? record.id : null,
    start: values.start,
    finish: values.finish,
    trailer: {
      id: values.trailerId,
      turnOwner: turnOwner === false ? turnOwner : turnOwner === true ? turnOwner : owner,
      manufactureYear: values.manufactureYear ? parseInt(values.manufactureYear) : undefined,
      modelYear: values.modelYear ? parseInt(values.modelYear) : undefined,
      identifier: values.identifier,
      renavamCode: values.renavamCode,
      plate: values.plate,
      vin: values.vin,
      vehicleBodyworkId: values.vehicleBodyworkId,
      bodyType: values.bodyType,
      height: values.height,
      width: values.width,
      bodyType: values.bodyType,
      length: values.length,
      netWeight: values.netWeight,
      maximumWeight: values.maximumWeight,
      tareWeight: values.tareWeight,
      volumeCapacity: values.volumeCapacity,
      documents64: documents64,
      licensingStateId: values.licensingStateId,
      origin: values.origin || 0
    }
  };
};
export const trailerSimpleAttributesBuilder = (record, values) => {
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    attributes: {
      id: values.trailerId,
      ownerId: values.ownerId,
      manufactureYear: values.manufactureYear ? parseInt(values.manufactureYear) : undefined,
      modelYear: values.modelYear ? parseInt(values.modelYear) : undefined,
      identifier: values.identifier,
      renavamCode: values.renavamCode,
      plate: values.plate,
      vin: values.vin,
      vehicleBodyworkId: values.vehicleBodyworkId,
      height: values.height,
      width: values.width,
      bodyType: values.bodyType,
      length: values.length,
      netWeight: values.netWeight,
      bodyType: values.bodyType,
      maximumWeight: values.maximumWeight,
      tareWeight: values.tareWeight,
      volumeCapacity: values.volumeCapacity,
      documents64: documents64,
      licensingStateId: values.licensingStateId,
      origin: values.origin || 0
    }
  };
};
export const trailerDefaultBuilder = record => {
  return {
    id: (record || {}).id,
    trailerId: ((record || {}).trailer || {}).id,
    owner: (record || {}).owner ? (record || {}).owner || false : undefined,
    start: (record || {}).start ? moment(record.start) : undefined,
    finish: (record || {}).finish ? moment(record.finish) : undefined,
    renavamCode: ((record || {}).trailer || {}).renavamCode,
    plate: ((record || {}).trailer || {}).plate,
    vin: ((record || {}).trailer || {}).vin,
    vehicleBodyworkId: ((record || {}).trailer || {}).vehicleBodyworkId,
    manufactureYear: (record || {}).trailer ? (((record || {}).trailer || {}).manufactureYear || {}).toString() : undefined,
    modelYear: (record || {}).trailer ? (((record || {}).trailer || {}).modelYear || {}).toString() : undefined,
    identifier: ((record || {}).trailer || {}).identifier,
    height: ((record || {}).trailer || {}).height || 0,
    width: ((record || {}).trailer || {}).width || 0,
    length: ((record || {}).trailer || {}).length || 0,
    netWeight: ((record || {}).trailer || {}).netWeight || 0,
    bodyType: record?.bodyType,
    maximumWeight: ((record || {}).trailer || {}).maximumWeight || 0,
    bodyType: ((record || {}).trailer || {}).bodyType,
    tareWeight: ((record || {}).trailer || {}).tareWeight || 0,
    volumeCapacity: ((record || {}).trailer || {}).volumeCapacity || 0,
    documents64: ((record || {}).trailer || {}).documents64.map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    })),
    licensingStateId: ((record || {}).trailer || {}).licensingStateId
  };
};
export const trailerSimpleDefaultBuilder = record => {
  return {
    id: (record || {}).id,
    trailerId: (record || {}).id,
    ownerId: (record || {}).ownerTrailer?.personId,
    renavamCode: (record || {}).renavamCode,
    plate: (record || {}).plate,
    vin: (record || {}).vin,
    vehicleBodyworkId: (record || {}).vehicleBodyworkId,
    manufactureYear: (record || {}).manufactureYear ? ((record || {}).manufactureYear || {}).toString() : undefined,
    modelYear: (record || {}).modelYear ? ((record || {}).modelYear || {}).toString() : undefined,
    identifier: (record || {}).identifier,
    height: (record || {}).height || 0,
    width: (record || {}).width || 0,
    length: (record || {}).length || 0,
    bodyType: (record || {}).bodyType,
    netWeight: (record || {}).netWeight || 0,
    bodyType: record?.bodyType,
    maximumWeight: (record || {}).maximumWeight || 0,
    tareWeight: (record || {}).tareWeight || 0,
    volumeCapacity: (record || {}).volumeCapacity || 0,
    documents64: (record || {}).documents64 ? (record || {}).documents64.map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    })) : undefined,
    licensingStateId: (record || {}).licensingStateId
  };
};