import { sanitize } from 'grifo-components';
import { MAIN } from 'admin-front';
import { vehicleAttributesBuilder, vehicleDefaultBuilder } from './VehicleBuilder';
import { trailerAttributesBuilder, trailerDefaultBuilder } from './TrailerBuilder';
export const driverParamBuilder = (record, values) => {
  let params = sanitize(values, ["city", "contact"]);
  if (params.phone) params.phone.naming = "main";
  if (params.address) params.address.naming = MAIN;
  return {
    attributes: {
      id: record ? record.id : null,
      personId: values.personId
    }
  };
};
export const driverDefaultBuilder = record => {
  return {
    person: (record || {}).person,
    personId: ((record || {}).person || {}).id,
    phone: (record || {}).phone,
    address: (record || {}).address,
    name: ((record || {}).person || {}).name,
    tradeName: ((record || {}).person || {}).tradeName,
    document: ((record || {}).person || {}).document,
    driverVehicles: ((record || {}).driverVehicles || []).map(object => vehicleDefaultBuilder(object)),
    driverTrailers: ((record || {}).driverVehicles || []).vehicleTrailers?.map(object => trailerDefaultBuilder(object.trailer))
  };
};