import { precisionType, bind } from 'grifo-components';
import { calculatePaymentValue } from "financial-front";
export const calculateItemsTotal = form => {
  if (!form) return;
  const items = form.getFieldsValue().items;
  const otherValue = items.reduce(function (previous, current) {
    return previous + (current.otherExpenses || 0);
  }, 0);
  const discountValue = items.reduce(function (previous, current) {
    return previous + (current.discountValue || 0);
  }, 0);
  const itemsTotal = items.reduce(function (previous, current) {
    return previous + (current.total || 0);
  }, 0);
  const totalTaxes = items.reduce(function (previous, current) {
    return previous + (current.totalTaxes || 0);
  }, 0);
  form.setFields([{
    name: ['otherValue'],
    value: otherValue
  }, {
    name: ['discountValue'],
    value: discountValue
  }, {
    name: ['itemsTotal'],
    value: itemsTotal
  }, {
    name: ['totalTaxes'],
    value: totalTaxes
  }]);
  calculateTotalBeforePayment(undefined, undefined, form);
};
const getValueByKind = (value, precision, form) => {
  return precisionType(value, precision, form.getFieldsValue().precisionType);
};
export const calculateTotalBeforePayment = (value, field, form) => {
  if (!form) return;
  const fields = form.getFieldsValue();
  const freightValue = fields.freightValue || 0;
  const insuranceValue = fields.insuranceValue || 0;
  const itemsTotal = fields.itemsTotal || 0;
  const change = fields.change || 0;
  let total = getValueByKind(freightValue + change + insuranceValue + itemsTotal, 2, form);
  form.setFields([{
    name: ['totalBeforePayment'],
    value: total
  }]);
  const payments = fields.payments;
  if ((payments || {}).length === 1) {
    payments[0].valueToPay = total;
    bind(form, total, ['payments', 0, 'valueToPay']);
    calculatePaymentValue(total, 0, form, fields.date);
  }
  calculateTotal(form);
};
export const calculateTotal = form => {
  if (!form) return;
  const fields = form.getFieldsValue();
  bind(form, getValueByKind(fields.totalBeforePayment, 2, form), ['total']);
};
export const calculateInvoiceTaxes = form => {
  if (!form) return;
  const invoice = form.getFieldsValue();
  let path = ['invoiceTax'];
  const items = invoice.items || [];
  let invoiceTaxes = invoice.invoiceTax || {};
  invoiceTaxes.discountValue = 0.00;
  invoiceTaxes.otherValue = invoice.otherValue || 0.00;
  invoiceTaxes.freightValue = invoice.freightValue || 0.00;
  invoiceTaxes.insuranceValue = invoice.insuranceValue || 0.00;
  invoiceTaxes.total = invoice.total || 0.00;
  invoiceTaxes.iiValue = 0.00;
  invoiceTaxes.ipiReturnedValue = 0.00;
  invoiceTaxes.totalTaxes = items.reduce((total, obj) => (obj?.totalTaxes || 0) + total, 0) || 0.00;
  invoiceTaxes.itemsTotal = items.reduce((total, obj) => (obj?.total || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsBasis = items.reduce((total, obj) => (obj?.icms?.basis || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.basisSt || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.withheldBasis || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.effectiveBasis || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.unemcumberedBasis || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsValue = items.reduce((total, obj) => (obj?.icms?.value || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.valueSt || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.withheldValue || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.effectiveValue || 0) + total, 0) + items.reduce((total, obj) => (obj?.icms?.unemcumberedValue || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsWithheldValue = items.reduce((total, obj) => (obj?.icms?.withheldValue || 0) + total, 0) || 0.00;
  invoiceTaxes.fcpValueStateDestination = items.reduce((total, obj) => (obj?.icms?.fcpValueStateDestination || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsValueStateDestination = items.reduce((total, obj) => (obj?.icmsDestination?.value || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsValueStateOrigin = items.reduce((total, obj) => (obj?.icms?.valueStateOrigin || 0) + total, 0) || 0.00;
  invoiceTaxes.fcpValue = items.reduce((total, obj) => (obj?.icms?.fcpValue || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsBasisSt = items.reduce((total, obj) => (obj?.icms?.basisSt || 0) + total, 0) || 0.00;
  invoiceTaxes.icmsValueSt = items.reduce((total, obj) => obj?.icms?.valueSt || 0 + total, 0) || 0.00;
  invoiceTaxes.fcpWithheldValue = items.reduce((total, obj) => (obj?.icms?.fcpWithheldValue || 0) + total, 0) || 0.00;
  invoiceTaxes.ipiValue = items.reduce((total, obj) => (obj?.ipi?.value || 0) + total, 0) || 0.00;
  invoiceTaxes.pisValue = items.reduce((total, obj) => (obj?.pis?.value || 0) + total, 0) || 0.00;
  invoiceTaxes.cofinsValue = items.reduce((total, obj) => (obj?.cofins?.value || 0) + total, 0) || 0.00;
  let values = Object.entries(invoiceTaxes).map(e => ({
    [e[0]]: e[1]
  }));
  bind(form, values, path);
  return invoiceTaxes;
};
export const setDfeSeriesNumber = (operationDfeSeries, companyFiscalStarts, dfeModel) => {
  if (operationDfeSeries) return operationDfeSeries;
  if (companyFiscalStarts.length === 0) return 1;
  let dfeSeries = companyFiscalStarts.filter(obj => obj.dfeModel == dfeModel);
  let seriesNumber = dfeSeries.map(obj => obj.dfeSeries);
  return Math.max(...seriesNumber);
};