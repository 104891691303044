export const CONVENTIONAL = 'conventional';
export const SUBCONTRACTING = 'subcontracting';
export const REDEPLOYMENT = 'redeployment';
export const INTERMEDIATE_REDEPLOYMENT = 'intermediate_redeployment';
export const MULTIMODAL_SERVICE = 'multimodal_service';
export const ROAD = 'road';
export const AERIAL = 'aerial';
export const WATERWAY = 'waterway';
export const RAIL = 'rail';
export const PIPELINE = 'pipeline';
export const MULTIMODAL = 'multimodal';
export const PENDING_SUBMISSION = 'pending_submission';
export const PROCESSING = 'processing';
export const SENT = 'sent';
export const AUTHORIZED = 'authorized';
export const DENIED = 'denied';
export const REJECTED = 'rejected';
export const PROCESSING_CANCELLATION = 'processing_cancellation';
export const CANCELED = 'canceled';
export const DEFAULT_PRODUCT_MEASURE_DESCRIPTION = 'PESO BASE DE CÁLCULO';
export const RECEIVER_WITHDRAW_YES = 'receiver_withdraw_yes';
export const RECEIVER_WITHDRAW_NO = 'receiver_withdraw_no';
export const SENDER = 'sender';
export const DISPATCHER = 'dispatcher';
export const RECEIVER = 'receiver';
export const RECIPIENT = 'recipient';
export const OTHERS = 'others';
export const WAYBILL = 'waybill';
export const FREIGHT = 'freight';
export const BATCH = 'batch';
export const CTE_NORMAL = 'normal';
export const CTE_COMPLEMENT = 'complement';
export const CTE_ANNULMENT = 'annulment';
export const CTE_REPLACEMENT = 'replacement';
export const SERVICE_TAKER_IDENTIFIER_OPTIONS = [{
  value: SENDER,
  label: 'Remetente'
}, {
  value: DISPATCHER,
  label: 'Expedidor'
}, {
  value: RECEIVER,
  label: 'Recebedor'
}, {
  value: RECIPIENT,
  label: 'Destinatário'
}, {
  value: OTHERS,
  label: 'Outro'
}];
export const MODAL_OPTIONS = [{
  value: ROAD,
  label: 'Rodoviário'
}, {
  value: AERIAL,
  label: 'Aéreo'
}, {
  value: WATERWAY,
  label: 'Aquaviário'
}, {
  value: RAIL,
  label: 'Ferroviário'
}, {
  value: PIPELINE,
  label: 'Dutoviário'
}, {
  value: MULTIMODAL,
  label: 'Multimodal'
}];
export const SERVICE_TYPE_OPTIONS = [{
  value: CONVENTIONAL,
  label: 'Convencional'
}, {
  value: SUBCONTRACTING,
  label: 'Subcontratação'
}, {
  value: REDEPLOYMENT,
  label: 'Redespacho'
}, {
  value: INTERMEDIATE_REDEPLOYMENT,
  label: 'Redespacho intermediário'
}, {
  value: MULTIMODAL_SERVICE,
  label: 'Multimodal'
}];
export const CTE_TYPE_OPTIONS = [{
  value: CTE_NORMAL,
  label: 'CT-e Normal'
}, {
  value: CTE_COMPLEMENT,
  label: 'CT-e de Complemento'
}, {
  value: CTE_ANNULMENT,
  label: 'CT-e de Anulação'
}, {
  value: CTE_REPLACEMENT,
  label: 'CT-e de Substituição'
}];