import gql from "graphql-tag";
import { fuelPumpModelFragment } from "./FuelPumpModel";
export const fuelPumpBrandFragment = gql`
  fragment FuelPumpBrand on FuelPumpBrand  {
    id,
    fuelPumpId,
    brandId,
    code,
    fuelPumpModels {
      ...FuelPumpModel
    },
  }
  ${fuelPumpModelFragment}
`;