export const vehicleTypeParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      wheelType: values.wheelType,
      name: values.name,
      description: values.description,
      weight: values.weight
    }
  };
};
export const vehicleTypeDefaultBuilder = record => {
  return {
    wheelType: (record || {}).wheelType,
    name: (record || {}).name,
    description: (record || {}).description,
    weight: (record || {}).weight
  };
};