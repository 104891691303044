import { sanitize } from 'grifo-components';
export const ncmImporterParamBuilder = (record, values) => {
  let documents64 = sanitize((values || {}).documents64, ['name', 'url']);
  return {
    attributes: {
      documents64: (documents64 || [])[0]
    }
  };
};
export const ncmImporterDefaultBuilder = record => {
  return {
    documents64: (record || {}).documents64
  };
};