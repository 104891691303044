import gql from "graphql-tag";
import { productSupplierFragment } from "../fragments/ProductSupplier";
export const GQL_PRODUCT_SUPPLIER_STORE = gql`
  mutation StoreProductSupplier($attributes: [ProductSupplierAttributes!], $productId: ID!) {
    storeProductSupplier(input: {attributes: $attributes, productId: $productId}) { 
      productSuppliers {
        ...ProductSupplier
      }, 
      errors 
    } 
  }
  ${productSupplierFragment}   
`;