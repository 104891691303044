import gql from "graphql-tag";
import { trailerSimpleFragment } from "./TrailerSimple";
export const vehicleTrailerFragment = gql`
  fragment VehicleTrailer on VehicleTrailer  { 
    id,
    trailerId,
    trailer {
      ...TrailerSimple
    }
  }
  ${trailerSimpleFragment}
`;