import { round, bind } from 'grifo-components';
export const calculateTotal = form => {
  const payments = form.getFieldsValue().payments;
  if (!payments) return;
  let paymentFees = payments.reduce(function (previous, current) {
    return previous + (current.fees || 0);
  }, 0);
  let paymentDiscounts = payments.reduce(function (previous, current) {
    return previous + (current.discount || 0);
  }, 0);
  const value = form.getFieldsValue().value;
  let total = round(value + paymentFees - paymentDiscounts);
  bind(form, [{
    total: total
  }]);
};