import gql from "graphql-tag";
export const vehicleBodyworkFragment = gql`
  fragment VehicleBodywork on VehicleBodywork  { 
    id,
    name,
    description,
    axis,
    tires,
    weight,
    width,
    height,
    length,
    discardedAt
  }
`;