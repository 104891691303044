import gql from "graphql-tag";
export const locationSimpleFragment = gql`
  fragment LocationSimple on Location {
    id,
    name,
    area,
    identifier,
    description,
    ancestry,
    fullPathName,
    parentId,
    discardedAt,
  }
`;