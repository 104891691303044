export const nozzleParamBuilder = (record, values) => {
  return {
    attributes: ((values || {}).nozzles || []).map(object => ({
      ...object,
      fuelPumpId: record.id
    })),
    fuelPumpId: record.id
  };
};
export const nozzleDefaultBuilder = record => {
  return {
    nozzles: (record || {}).nozzles
  };
};