import gql from "graphql-tag";
import { productUnitFragment, productFragment } from 'stock-front';
export const requestProductUnitFragment = gql`
  fragment RequestProductUnit on RequestProductUnit  { 
    id,
    requestId,
    quantity,
    productUnit {
      ...ProductUnit
    },
    product {
      ...Product
    },
    suggestedProductUnit {
      ...ProductUnit
    },
    suggestedProduct {
      ...Product
    },
  }
  ${productUnitFragment}
  ${productFragment}
`;