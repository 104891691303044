export const taxableUnitParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      initials: values.initials,
      fiscalInitials: values.fiscalInitials
    }
  };
};
export const taxableUnitDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    initials: (record || {}).initials,
    fiscalInitials: (record || {}).fiscalInitials
  };
};