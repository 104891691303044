import gql from "graphql-tag";
import { batchFragment, locationSimpleFragment } from "stock-front";
import { fiscalProductFragment } from "./Product";
import { fiscalProductUnitFragment } from "./ProductUnit";
export const productSearchFragment = gql`
  fragment FiscalProductSearch on FiscalProductSearch  {
    id,
    balance,
    product {
      ...FiscalProduct
    }
    productUnit {
      ...FiscalProductUnit
    }
    location {
      ...LocationSimple
    }
    batch {
      ...Batch
    }
  }
  ${fiscalProductFragment}
  ${fiscalProductUnitFragment}
  ${locationSimpleFragment}  
  ${batchFragment}  
`;