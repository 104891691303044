import gql from "graphql-tag";
export const taxBenefitCodeFragment = gql`
  fragment TaxBenefitCode on TaxBenefitCode {
    id,
    code,
    state {
      id
      name
      initials
    }
    description,
    observation,
    start,
    finish,
    taxSituation,
    
  }
`;