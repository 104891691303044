import gql from "graphql-tag";
import { commercialUnitFragment } from "../fragments/CommercialUnit";
export const GQL_COMMERCIAL_UNITS_SELECTION = gql`
  {
    commercialUnits {
      ...CommercialUnit
    }
  }
  ${commercialUnitFragment}   
`;
export const GQL_COMMERCIAL_UNITS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $commercialUnitFilter: CommercialUnitFilter) {    
    commercialUnitsPaginated(first: $pageSize, after: $cursor, commercialUnitFilter: $commercialUnitFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...CommercialUnit
        }
      }
    }    
  }
  ${commercialUnitFragment}
`;
export const GQL_COMMERCIAL_UNIT_STORE = gql`
  mutation StoreCommercialUnit($attributes: CommercialUnitAttributes!) {
    storeCommercialUnit(input: {attributes: $attributes}) { 
      commercialUnit {
        ...CommercialUnit
      }, 
      errors 
    } 
  }
  ${commercialUnitFragment}   
`;
export const GQL_COMMERCIAL_UNIT_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Stock::CommercialUnit"}) {
      id,
      discardedAt
    }
  }
`;