import gql from "graphql-tag";
import { taxProfileInssFragment } from "../fragments/TaxProfileInss";
export const GQL_TAX_PROFILE_INSS_STORE = gql`
  mutation StoreTaxProfileInss($attributes: TaxProfileIrpjAttributes!, $taxProfileId: ID!) {
    storeTaxProfileInss(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      inss {
        ...TaxProfileInss
      }, 
      errors 
    } 
  }
  ${taxProfileInssFragment}   
`;