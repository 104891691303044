import { sanitize } from 'grifo-components';
const buildProducts = products => {
  if (!products.length) return;
  return products.map(product => {
    product['xmlIndex'] = product['item']['nItem'];
    product['skipped'] = !Boolean(product?.product_id && product?.product_unit_id);
    return {
      ...product
    };
  });
};
export const xmlImporterParamBuilder = (record, values) => {
  let documents64 = sanitize((values || {}).documents64, ['name', 'url']);
  let products = buildProducts((values || {}).products || []);
  return {
    attributes: {
      products: products,
      documents64: (documents64 || [])[0],
      operationId: (values || {}).operationId,
      currentAssetId: (values || {}).currentAssetId,
      payments: values.payments
    }
  };
};
export const xmlImporterDefaultBuilder = record => {
  return {
    documents64: (record || {}).documents64,
    operationId: (record || {}).operationId,
    currentAssetId: (record || {}).currentAssetId,
    products: (record || {}).products || []
  };
};
export const loadProductSet = (record, form, index) => {
  if (!record && !form) return;
  let fieldsValue = record ? record : form ? form.getFieldsValue() : {};
  if (!fieldsValue || !fieldsValue.products || !fieldsValue.products[index]) return;
  let productUnit = fieldsValue.products[index] || {};
  return {
    productUnitId: (productUnit?.productUnit || [])[1] || undefined,
    productId: (productUnit?.productUnit || [])[0] || undefined
  };
};