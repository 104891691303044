import gql from "graphql-tag";
import { vehicleFragment } from "../fragments/Vehicle";
export const GQL_VEHICLES_SEARCH = gql`
  query GetVehicle($text: String){
    vehicles(vehicleFilter: {search: $text}) { 
      ...Vehicle
    }
  }
  ${vehicleFragment}   
`;
export const GQL_VEHICLES_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $vehicleFilter: VehicleFilter) {    
    vehiclesPaginated(first: $pageSize, after: $cursor, vehicleFilter: $vehicleFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Vehicle
        }
      }
    }    
  }
  ${vehicleFragment}
`;
export const GQL_VEHICLE_LOAD = gql`
  query Get($id: ID) {    
    vehicle(id: $id) {
      ...Vehicle
    }
  }
  ${vehicleFragment}   
`;
export const GQL_VEHICLES_STORE = gql`
  mutation StoreVehicle($attributes: VehicleAttributes!) {
    storeVehicle(input: {attributes: $attributes}) { 
      vehicle {
        ...Vehicle
    }, 
      errors 
    } 
  }
  ${vehicleFragment}   
`;
export const GQL_VEHICLES_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Fleet::Vehicle"}) {
      id,
      discardedAt
    }
  }
`;