import gql from "graphql-tag";
import { taxProfilePisFragment } from "../fragments/TaxProfilePis";
export const GQL_TAX_PROFILE_PIS_STORE = gql`
  mutation StoreTaxProfilePis($attributes: TaxProfilePisAttributes!, $taxProfileId: ID!) {
    storeTaxProfilePis(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      pis {
        ...TaxProfilePis
      }, 
      errors 
    } 
  }
  ${taxProfilePisFragment}   
`;