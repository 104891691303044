import gql from "graphql-tag";
import { tradeDailyMovementFragment } from "../fragments/TradeDailyMovement";
export const GQL_TRADE_DAILY_MOVEMENT = gql`
  query GetTradeDailyMovement($filter: TradeDailyMovementFilter) {    
    tradeDailyMovement(filter: $filter) { 
      ...TradeDailyMovement
    }
  }
  ${tradeDailyMovementFragment}
`;
export const GQL_FISCAL_PRODUCT_DAILY_MOVEMENT = gql`
  query GetTradeDailyMovement($filter: ProductUnitDailyMovementFilter) {    
    tradeDailyMovement(filterByProduct: $filter) { 
      ...TradeDailyMovement
    }
  }
  ${tradeDailyMovementFragment}
`;