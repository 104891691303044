import gql from "graphql-tag";
import { taxableUnitFragment } from "../fragments/TaxableUnit";
export const GQL_TAXABLE_UNITS_SELECTION = gql`
  {
    taxableUnits {
      ...TaxableUnit
    }
  }
  ${taxableUnitFragment}   
`;
export const GQL_TAXABLE_UNITS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $taxableUnitFilter: TaxableUnitFilter) {    
    taxableUnitsPaginated(first: $pageSize, after: $cursor, taxableUnitFilter: $taxableUnitFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...TaxableUnit
        }
      }
    }    
  }
  ${taxableUnitFragment}
`;
export const GQL_TAXABLE_UNIT_STORE = gql`
  mutation StoreTaxableUnit($attributes: TaxableUnitAttributes!) {
    storeTaxableUnit(input: {attributes: $attributes}) { 
      taxableUnit {
        ...TaxableUnit
      }, 
      errors 
    } 
  }
  ${taxableUnitFragment}   
`;
export const GQL_TAXABLE_UNIT_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Fiscal::TaxableUnit"}) {
      id,
      discardedAt
    }
  }
`;