import gql from "graphql-tag";
import { parentFiscalOperationFragment } from "../fragments/FiscalOperationParent";
import { fiscalOperationAllFragment } from "../fragments/Operation";
import { fiscalOperationSelectionFragment } from "../fragments/OperationSelection";
import { fiscalPdvOperationFragment } from "../fragments/PdvOperation";
export const GQL_SEARCH_OPERATION = gql`
  query GetByName($name: String) {    
    fiscalOperations(name: $name) { 
      ...FiscalOperationParent
    }
  }
  ${parentFiscalOperationFragment}   
`;
export const GQL_SELECTION_OPERATION_BY_KIND = gql`
  query GetByKind($kind: String) {    
    fiscalOperations(kind: $kind) { 
      ...FiscalOperationParent
    }
  }
  ${parentFiscalOperationFragment}
`;
export const GQL_SEARCH_OPERATION_BY_KIND = gql`
  query GetByKind($pageSize: Int, $cursor: String, $filter: FiscalOperationFilter) {    
    fiscalOperationsPaginated(first: $pageSize, after: $cursor, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FiscalOperationParent
        }
      }
    }    
  }
  ${parentFiscalOperationFragment}
`;
export const GQL_SEARCH_OPERATION_BY_KIND_WITH_FILTER = gql`
  query GetByKind($pageSize: Int, $cursor: String, $filter: FiscalOperationFilter) {    
    fiscalOperationsPaginated(first: $pageSize, after: $cursor, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FiscalOperation
        }
      }
    }    
  }
  ${fiscalOperationAllFragment}
`;
export const GQL_SEARCH_OPERATION_BY_ID = gql`
  query GetById($id: String) {    
    fiscalOperations(id: $id) { 
      ...FiscalOperationSelection
    }
  }
  ${fiscalOperationSelectionFragment}   
`;
export const GQL_POINT_OF_SALES_OPERATION = gql`
query GetByObjective($isFor: String) {    
  fiscalOperations(isFor: $isFor) { 
    ...FiscalPdvOperation
  }
}
${fiscalPdvOperationFragment}   
`;
export const GQL_ALL_AVAILABLE_OPERATION = gql`
  query GetAll($all: Boolean) {    
    fiscalOperations(all: $all) {
      ...FiscalOperationSelection
    }
  }
  ${fiscalOperationSelectionFragment}
`;
export const GQL_MOVEMENT_AVAILABLE_OPERATION = gql`
  query GetMovement($movement: Boolean) {    
    fiscalOperations(movement: $movement) {
      ...FiscalOperationSelection
    }
  }
  ${fiscalOperationSelectionFragment}
`;
export const GQL_ON_APPROVAL_OPERATION = gql`
  query GetConditional($onApproval: Boolean) {    
    fiscalOperations(onApproval: $onApproval) {
      ...FiscalOperationSelection
    }
  }
  ${fiscalOperationSelectionFragment}
`;
export const GQL_DFE_MODEL_FILTER_OPERATIONS = gql`
  query GetDfeModelFilter($filter: FiscalOperationFilter) {    
    fiscalOperations(dfeFilter: $filter) {
      ...FiscalOperationSelection
    }
  }
  ${fiscalOperationSelectionFragment}
`;
export const GQL_OPERATION_STORE = gql`
  mutation StoreFiscalOperation($attributes: FiscalOperationAttributes!) {
    storeFiscalOperation(input: {attributes: $attributes}) { 
      operation {
        ...FiscalOperationParent
      }, 
      errors 
    } 
  }
  ${parentFiscalOperationFragment}   
`;
export const GQL_OPERATION_INACTIVATE = gql`
  mutation InactivateFiscalOperation($id: ID!) { 
    inactivateFiscalOperation(input: {id: $id, className: "Fiscal::Operation"}) {
      id,
      discardedAt
    }
  }
`;