import _ from "lodash";
import { MONTH } from "financial-front";
import { BROKER_INDICATOR, BUYER_PRESENCE_INDICATOR, COMPANY_LOCATION, COSTUMER_FINAL, FINISHED, FROM_OFFER, OUTSIDE_STATE, PERSON_LOCATION, WITHOUT_FREIGHT, MAKE_UP_TOTAL } from 'fiscal-front';
export const invoiceBidParamBuilder = record => {
  const offer = record || {};
  const acceptedBids = (offer.bids || []).filter(object => object.accepted) || [];
  let data = acceptedBids.map(bid => ({
    origin: FROM_OFFER,
    personId: bid.person.id,
    bidId: bid.id,
    date: new Date(),
    dfeModel: record.dfeModel,
    dfeSeries: record.dfeSeries,
    addressId: (((bid.person || {}).addresses || [])[0] || {}).id,
    operationId: (offer.operation || {}).id,
    finalCostumerMethod: COSTUMER_FINAL,
    operationDestination: OUTSIDE_STATE,
    buyerPresenceIndicator: BUYER_PRESENCE_INDICATOR,
    brokerIndicator: BROKER_INDICATOR,
    discountValue: 0,
    itemsTotal: _.sumBy(acceptedBids, 'quantity') * offer.price,
    totalTaxes: 0,
    freightValue: 0,
    insuranceValue: 0,
    otherValue: 0,
    total: _.sumBy(acceptedBids, 'quantity') * offer.price,
    pickupMethod: COMPANY_LOCATION,
    deliveryMethod: PERSON_LOCATION,
    freightMethod: WITHOUT_FREIGHT,
    situation: FINISHED,
    invoiceNumber: undefined,
    items: [{
      totalInvoiceMethod: MAKE_UP_TOTAL,
      invoiceIndex: 1,
      unitaryValue: offer.price,
      grossTotal: bid.quantity * offer.price,
      taxableQuantity: bid.quantity,
      taxableUnitaryValue: offer.price,
      taxableGrossTotal: bid.quantity * offer.price,
      total: bid.quantity * offer.price,
      discountValue: 0,
      freightValue: 0,
      insuranceValue: 0,
      otherExpenses: 0,
      quantity: bid.quantity,
      productId: (offer.productUnit || {}).productId,
      productUnitId: offer.productUnitId,
      locationId: offer.locationId,
      batchId: offer.batchId
    }],
    payments: bid.payments.map(payment => ({
      conditionDiscount: 0,
      conditionDiscountRate: 0,
      conditionFees: 0,
      conditionFeesRate: 0,
      discount: 0,
      fees: 0,
      gracePeriod: 0,
      installment: 1,
      installments: [{
        dueDate: new Date(),
        value: bid.quantity * offer.price
      }],
      methodDiscount: 0,
      methodDiscountRate: 0,
      methodFees: 0,
      methodFeesRate: 0,
      paymentConditionId: payment.paymentConditionId,
      paymentMethodId: payment.paymentMethodId,
      time: 1,
      timeKind: MONTH,
      total: bid.quantity * offer.price,
      valueToPay: bid.quantity * offer.price
    }))
  }));
  return {
    attributes: {
      invoices: data,
      offerId: offer.id
    }
  };
};