export const suggestedProductParamBuilder = (record, values) => {
  return {
    attributes: {
      requestId: (record || {}).id,
      suggestedProductUnits: values.requestProductUnits.map(obj => ({
        requestProductUnitId: obj.id,
        suggestedProductUnitId: obj.suggestedProductUnitId[1]
      }))
    }
  };
};