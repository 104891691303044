import gql from "graphql-tag";
import { AdditionalInformationFragment } from "../fragments/AdditionalInformation";
export const GQL_ADDITIONAL_INFORMATIONS_SELECTION = gql`
  {
    additionalInformations {
      ...AdditionalInformation,
    }    
  }
  ${AdditionalInformationFragment}
`;
export const GQL_ADDITIONAL_INFORMATIONS_SELECTION_BY_TARGET = gql`
query additionalInformationsByTarget($target: String) {    
    additionalInformations(target: $target) {
      ...AdditionalInformation,
    }    
  }
  ${AdditionalInformationFragment}
`;
export const GQL_ADDITIONAL_INFORMATIONS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $additionalInfoFilter: AdditionalInfoFilter) {    
    additionalInformationsPaginated(first: $pageSize, after: $cursor, additionalInfoFilter: $additionalInfoFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...AdditionalInformation
        }
      }
    }    
  }
  ${AdditionalInformationFragment}
`;
export const GQL_ADDITIONAL_IFORMATION_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Fiscal::AdditionalInformation"}) {
      id,
      discardedAt
    }
  }
`;
export const GQL_ADDITIONAL_INFORMATIONS_STORE = gql`
  mutation StoreAdditionalInformation($attributes: AdditionalInformationAttributes!) {
    storeAdditionalInformation(input: {attributes: $attributes}) { 
      additionalInformation { 
        ...AdditionalInformation,
      }, 
      errors 
    } 
  }
  ${AdditionalInformationFragment}  
`;