import gql from "graphql-tag";
import { tradePeopleRankingFragment } from "../fragments/TradePeopleRanking";
export const GQL_TRADE_PEOPLE_RANKING = gql`
  query GetTradePeopleRanking($filter: TradePeopleRankingFilter) {    
    tradePeopleRanking(filter: $filter) { 
      ...TradePeopleRanking
    }
  }
  ${tradePeopleRankingFragment}
`;
export const GQL_FISCAL_PRODUCT_PEOPLE_RANKING = gql`
  query GetTradePeopleRanking($filter: ProductPeopleRankingFilter) {    
    tradePeopleRanking(filterByProduct: $filter) { 
      ...TradePeopleRanking
    }
  }
  ${tradePeopleRankingFragment}
`;