import gql from "graphql-tag";
import { paymentConditionFragment, paymentMethodFragment } from "financial-front";
export const bidPaymentFragment = gql`
  fragment BidPayment on BidPayment  { 
    id,
    paymentMethodId,
    paymentConditionId,
    paymentMethod {
      ...PaymentMethod
    },
    paymentCondition {
      ...PaymentCondition
    },
  }
  ${paymentMethodFragment}
  ${paymentConditionFragment}
`;