import gql from "graphql-tag";
import { personFiscalStFragment } from "../fragments/PersonFiscalSt";
export const GQL_PERSON_FISCAL_STS_LIST = gql`
  {
    personFiscalSts {
      ...PersonFiscalSt,
    }    
  }
  ${personFiscalStFragment}
`;
export const GQL_PERSON_FISCAL_STS_STORE = gql`
  mutation StorePersonStateSts($attributes: PersonStateStsAttributes!, $personId: ID!) {
    storePersonStateSts(input: {attributes: $attributes, personId: $personId}) { 
      personStateSts { 
        ...PersonStateSt,
      }, 
      errors 
    } 
  }
  ${personFiscalStFragment}  
`;