import gql from "graphql-tag";
import { fuelPumpSealFragment } from "../fragments/FuelPumpSeal";
export const GQL_FUEL_PUMP_SEAL_STORE = gql`
  mutation StoreFuelPumpSeal($attributes: [FuelPumpSealAttributes!], $fuelPumpId: ID!) {
    storeFuelPumpSeal(input: {attributes: $attributes, fuelPumpId: $fuelPumpId}) { 
      fuelPumpSeals {
        ...FuelPumpSeal
      }, 
      errors 
    } 
  }
  ${fuelPumpSealFragment}   
`;