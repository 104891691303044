import gql from "graphql-tag";
import { companyFragment, personFragment } from 'admin-front';
import { InvoiceFragment } from 'fiscal-front';
import { bidEventFragment } from './BidEvent';
import { bidPaymentFragment } from "./BidPayment";
export const bidFragment = gql`
  fragment Bid on Bid  { 
    id,
    quantity,
    offerId
    canAccept,
    canCancel,
    canEdit,
    unavailablePaymentMethodIds,
    accepted,
    company {
      ...Company
    }
    person {
      ...Person
    }
    currentEvent {
      ...BidEvent
    }
    payments {
      ...BidPayment
    }
    invoice {
      ...Invoice
    }
  }
  ${personFragment}
  ${bidEventFragment}
  ${companyFragment}
  ${bidPaymentFragment}
  ${InvoiceFragment}
`;