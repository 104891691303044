import gql from "graphql-tag";
import { locationSimpleFragment } from "./LocationSimple";
import { companyFragment } from 'admin-front';
export const locationFragment = gql`
  fragment Location on Location  {
    ...LocationSimple
    parent {
      ...LocationSimple
    },
    company {
      ...Company
    },
  }
  ${locationSimpleFragment}
  ${companyFragment}
`;