import gql from "graphql-tag";
import { fleetPersonFragment } from "./FleetPerson";
export const fleetProfileFragment = gql`
  fragment FleetProfile on FleetProfile { 
    id,
    isDriver,
    isAssociated,
    person {
      ...FleetPerson
    }
  }
  ${fleetPersonFragment}
`;