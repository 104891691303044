import moment from 'moment';
import { getLabelByValue } from 'grifo-components';
import { AVAILABILITY_OPTIONS } from '../../constants/offers/offerFieldsOptions';
export const offerDecorator = record => {
  record = record || {};
  return {
    start: record.start ? moment(record.start).format("DD/MM/YYYY") : '',
    finish: record.finish ? moment(record.finish).format("DD/MM/YYYY") : '',
    availability: getLabelByValue(AVAILABILITY_OPTIONS, record.availability),
    availableQuantity: record.showAvailable ? record.availableQuantity : ' - '
  };
};