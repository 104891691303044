export const ipiLegalFrameworkParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      code: values.code,
      cstGroup: values.cstGroup,
      description: values.description
    }
  };
};
export const ipiLegalFrameworkDefaultBuilder = record => {
  return {
    code: (record || {}).code,
    cstGroup: (record || {}).cstGroup,
    description: (record || {}).description
  };
};