export const productSupplierParamBuilder = (record, values) => {
  return {
    attributes: values.productSuppliers.map(object => ({
      ...object,
      productId: record.id
    })),
    productId: record.id
  };
};
export const productSupplierDefaultBuilder = record => {
  return {
    productSuppliers: (record || {}).productSuppliers
  };
};