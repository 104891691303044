import gql from "graphql-tag";
import { tradeMovementByAssociatedFragment } from "../fragments/TradeMovementByAssociated";
export const GQL_TRADE_MOVEMENT_BY_ASSOCIATED = gql`
  query GetTradeMovementByAssociated($filter: MovementByAssociatedFilter) {    
    tradeMovementByAssociated(filter: $filter) { 
      ...TradeMovementByAssociated
    }
  }
  ${tradeMovementByAssociatedFragment}
`;
export const GQL_FISCAL_PRODUCT_MOVEMENT_BY_ASSOCIATED = gql`
  query GetTradeMovementByAssociated($filter: ProductMovementByAssociatedFilter) {    
    tradeMovementByAssociated(filterByProduct: $filter) { 
      ...TradeMovementByAssociated
    }
  }
  ${tradeMovementByAssociatedFragment}
`;