import gql from 'graphql-tag';
import { driverFragment } from '../fragments/Driver';
import { vehicleFragment } from '../fragments/Vehicle';
import { trailerFragment } from '../fragments/Trailer';
export const GQL_DRIVER_SEARCH = gql`
  query GetDriver($text: String) {
    driverInfo(search: $text) { 
      id,
      discardedAt,
      name
    }
  }
`;
export const GQL_DRIVER_ID_SEARCH = gql`
  query Get($id: ID) {    
    driverInfo(id: $id) {
      id,
      name
    }
  }
`;
export const GQL_DRIVERS_LIST_BY_PROFILE = gql`
  query GetByProfile($pageSize: Int, $cursor: String, $profile: String, $driverFilter: DriverFilter) {    
    driversPaginated(first: $pageSize, after: $cursor, profile: $profile, driverFilter: $driverFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Driver
        }
      }
    }    
  }
  ${driverFragment}
`;
export const GQL_DRIVERS_BY_PLATE_OR_VIN_VEHICLE = gql`
  query GetByPlate($code: String) {
    plateOrVinVehicle(code: $code) {
      ...Vehicle
    }
  }
  ${vehicleFragment}
`;
export const GQL_DRIVERS_BY_PLATE_OR_VIN_TRAILER = gql`
  query GetByPlate($code: String) {
    plateOrVinTrailer(code: $code) {
      ...Trailer
    }
  }
  ${trailerFragment}
`;
export const GQL_DRIVER_STORE = gql`
  mutation StoreDriver($attributes: DriverAttributes!) {
    storeDriver(input: { attributes: $attributes }) {
      driver {
        ...Driver
      }
      errors
    }
  }
  ${driverFragment}
`;
export const GQL_ORGANIZATION_MEMBER_DRIVER_STORE = gql`
  mutation StoreOrganizationMemberDriver($attributes: OrganizationMemberDriverAttributes!) {
    storeOrganizationMemberDriver(input: { attributes: $attributes }) {
      driver {
        ...Driver
      }
      errors
    }
  }
  ${driverFragment}
`;
export const GQL_DRIVER_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: { id: $id, className: "Fleet::Driver" }) {
      id,
      discardedAt
    }
  }
`;