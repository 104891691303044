import gql from "graphql-tag";
import { addressFragment, contactFragment, phoneFragment, userStoreSimpleFragment } from 'admin-front';
import { preferredDriverFragment } from "./PreferredDriver";
import { personBankingFragment } from "financial-front";
import { personDocumentFragment } from "./PersonDocument";
export const customerFragment = gql`
  fragment Customer on FleetPerson  { 
    id,
    name,
    document,
    photo64,
    email,
    gender,
    birth,
    closure,
    cadunicoCode,
    rntrcCode,
    nisCode,
    discardedAt,
    mainAddress {
      ...Address
    },
    addresses {
      ...Address
    },
    phone {
      ...Phone
    },
    phones {
      ...Phone
    },    
    user {
      ...UserStoreSimple
    },
    ofCompany {
      id,
    },
    contacts {
      ...Contact
    },
    preferredDrivers {
      ...PreferredDriver
    },
    personBankings {
      ...PersonBanking
    },
    personDocuments {
      ...PersonDocument
    }
  }
  ${addressFragment}
  ${phoneFragment}
  ${userStoreSimpleFragment}
  ${contactFragment}
  ${preferredDriverFragment}
  ${personBankingFragment}
  ${personDocumentFragment}
`;