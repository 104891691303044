import gql from "graphql-tag";
import { stockMovementProductUnitAnalysisFragment } from "./StockMovementProductUnitAnalysis";
export const stockMovementProductAnalysisFragment = gql`
  fragment StockMovementProductAnalysis on StockMovementProductAnalysis {
    id,
    productName,
    ranking,
    total,
    loss,
    reversal,
    reserve,
    entry,
    exit,
    children {
      ...StockMovementProductUnitAnalysis
    }
  }
  ${stockMovementProductUnitAnalysisFragment}
`;