export const productModelParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      code: values.code,
      brandId: values.brandId
    }
  };
};
export const productModelDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    code: (record || {}).code,
    brandId: ((record || {}).brand || {}).id
  };
};