export const OTHERS = 'others';
export const MERCHANDISE_FOR_SALE = 'merchandise_for_sale';
export const RAW_MATERIAL = 'raw_material';
export const PACKAGE = 'package';
export const PRODUCT_IN_PROCESS = 'product_in_process';
export const FINISHED_PRODUCT = 'finished_product';
export const BYPRODUCT = 'byproduct';
export const INTERMEDIATE_PRODUCT = 'intermediate_product';
export const USE_AND_CONSUMPTION_MATERIAL = 'use_and_consumption_material';
export const ACTIVE_IMMOBILIZED = 'active_immobilized';
export const SERVICES = 'services';
export const PRODUCED_ON_A_RELEVANT_SCALE = 'produced_on_a_relevant_scale';
export const PRODUCED_ON_A_NON_RELEVANT_SCALE = 'produced_on_a_non_relevant_scale';
export const KIND_OPTIONS = [{
  value: OTHERS,
  label: 'Outros'
}, {
  value: MERCHANDISE_FOR_SALE,
  label: 'Mercadoria para Revenda'
}, {
  value: RAW_MATERIAL,
  label: 'Matéria-prima'
}, {
  value: PACKAGE,
  label: 'Embalagem'
}, {
  value: PRODUCT_IN_PROCESS,
  label: 'Produto em Processo'
}, {
  value: FINISHED_PRODUCT,
  label: 'Produto Finalizado'
}, {
  value: BYPRODUCT,
  label: 'Subproduto'
}, {
  value: INTERMEDIATE_PRODUCT,
  label: 'Produto Intermediário'
}, {
  value: USE_AND_CONSUMPTION_MATERIAL,
  label: 'Material de Uso e Consumo'
}, {
  value: ACTIVE_IMMOBILIZED,
  label: 'Ativo Imobilizado'
}, {
  value: SERVICES,
  label: 'Serviços'
}];
export const PRODUCTION_SCALE_OPTIONS = [{
  value: PRODUCED_ON_A_RELEVANT_SCALE,
  label: 'Produzido em Escala Relevante'
}, {
  value: PRODUCED_ON_A_NON_RELEVANT_SCALE,
  label: 'Produzido em Escala Não Relevante'
}];