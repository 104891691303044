import { dateOrToday, getSessionInfo } from 'grifo-components';
import { CTC, ETC, LOAD, MDFE, ROAD, TON, TRANSPORT_PROVIDER, UNLOAD } from '../constants/manifests/ManifestFieldsOptions';
import { OPENED } from '../constants/invoices/InvoiceFieldsOptions';
export const manifestParamBuilder = (record, values) => {
  let id = values.isWaybill ? null : record?.id || null;
  return {
    attributes: {
      id: id,
      code: values.code,
      issuerType: values.issuerType,
      carrierType: values.carrierType,
      modal: values.modal,
      dfeModel: values.dfeModel,
      dfeSeries: values.dfeSeries,
      dfeNumber: values.dfeNumber,
      cargoValue: values.cargoValue,
      tollValue: values.tollValue,
      measureType: values.measureType,
      insuranceValue: values.insuranceValue,
      issuanceDate: values.issuanceDate ? values.issuanceDate.format("YYYY-MM-DD") : undefined,
      cargoGrossWeight: values.cargoGrossWeight,
      ownerId: values.ownerId,
      vehicleId: values.vehicleId,
      operationId: values.operationId,
      driverId: values.driverId,
      manifestCities: values.manifestCities,
      manifestAccessKeys: values.manifestAccessKeys,
      situation: values.situation || OPENED,
      manifestTrailers: values.manifestTrailers.map(manifestTrailer => {
        return {
          id: manifestTrailer.id,
          trailerId: manifestTrailer.id
        };
      })
    }
  };
};
export const manifestDefaultBuilder = (record, props = {}) => {
  // const isWaybill = props?.isWaybill
  const session = getSessionInfo() || {};
  const company = session.company || {};
  let isCooperative = company?.isCooperative == undefined ? false : company?.isCooperative;
  const object = record || {};
  let carrierType = isCooperative ? CTC : ETC;
  object.payments = object.payments || [{
    valueToPay: object.value
  }];
  let loadCity = 0;
  let unloadCity = 0;
  let driverId = object.driverId;
  let ownerId = object.vehicleOwnerId;
  let cargoGrossWeight = object.cargoGrossWeight;
  let cargoValue = object.cargoValue;
  // let manifestWayBills = []
  let manifestTrailers = [];
  // if (isWaybill) {
  //   loadCity = record.cityOriginId
  //   unloadCity = record.cityDestinationId
  //   driverId = record.vehicle.driverVehicle.driverId
  //   object.dfeModel = MDFE
  //   cargoGrossWeight = record.totalWeight
  //   cargoGrossWeight = record.totalWeight
  //   manifestWayBills = [record.id]

  //   for (let index = 0; index < record?.vehicle?.vehicleTrailers.length; index++) {
  //     const element = record?.vehicle?.vehicleTrailers[index];
  //     manifestTrailers.push(element)
  //   } 
  // }
  const cities = [{
    cityId: loadCity,
    actionKind: LOAD
  }, {
    cityId: unloadCity,
    actionKind: UNLOAD
  }];
  return {
    issuanceDate: dateOrToday(object.issuanceDate),
    company: object.company,
    // isWaybill: isWaybill,
    loadingState: object.loadingState,
    unloadingState: object.unloadingState,
    code: object.code,
    issuerType: object.issuerType || TRANSPORT_PROVIDER,
    carrierType: object.carrierType || carrierType,
    modal: object.modal || ROAD,
    dfeModel: object.dfeModel || MDFE,
    cargoValue: cargoValue,
    measureType: object.measureType || TON,
    cargoGrossWeight: cargoGrossWeight,
    ownerId: ownerId,
    driverId: driverId,
    vehicleId: object.vehicleId,
    manifestTrailers: manifestTrailers,
    vehicleOwnerType: object.vehicleOwnerType,
    manifestCities: cities,
    manifestAccessKeys: object.manifestAccessKeys || [{
      accessKey: undefined,
      dfeModel: undefined
    }],
    situation: object.situation || OPENED
  };
};
export const manifestConsultationParamsBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null
    }
  };
};
export const manifestConsultationdefaultValuesBuilder = record => {
  return {};
};