import gql from "graphql-tag";
import { taxProfileIrpjFragment } from "../fragments/TaxProfileIrpj";
export const GQL_TAX_PROFILE_IRPJ_STORE = gql`
  mutation StoreTaxProfileIrpj($attributes: TaxProfileIrpjAttributes!, $taxProfileId: ID!) {
    storeTaxProfileIrpj(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      irpj {
        ...TaxProfileIrpj
      }, 
      errors 
    } 
  }
  ${taxProfileIrpjFragment}   
`;