import gql from "graphql-tag";
import { fleetPersonSimpleFragment } from "./FleetPersonSimple";
export const driverFragment = gql`
  fragment Driver on Driver  { 
    id,
    personId,
    person {
      ...FleetPersonSimple
    },
    discardedAt
  }
  ${fleetPersonSimpleFragment}
`;