import gql from "graphql-tag";
import { taxBenefitCodeFragment } from "./TaxBenefitCode";
import { TaxProfileFragment } from "./TaxProfile";
export const InvoiceItemIcmsFragment = gql`
  fragment InvoiceItemIcms on InvoiceItemIcms  { 
    id,
    taxSituation,
    goodsOrigin,
    methodBasis,
    basisReductionPercentage,
    basis,
    rate,
    value,
    fcpBasis,
    fcpRate,
    fcpValue,
    methodBasisSt,
    basisReductionPercentageSt,
    basisSt,
    rateSt,
    valueSt,
    fcpBasisSt,
    fcpRateSt,
    fcpValueSt,
    mvaRate,
    withheldBasis,
    withheldValue,
    fcpWithheldBasis,
    fcpWithheldRate,
    fcpWithheldValue,
    finalCostumerRate,
    substituteOwnValue,
    effectiveBasisReductionPercentage,
    effectiveBasis,
    effectiveRate,
    effectiveValue,
    unencumberedReason,
    unencumberedRate,
    unencumberedValue,
    unencumberedBasis,
    unencumberedBasisReduction,
    deferralRate,
    deferralValue,
    valueWithDeferral,
    rateSn,
    valueSn,
    taxProfile {
      ...TaxProfile
    }
    taxBenefitCode {
      ...TaxBenefitCode
    }
    cestId
    taxBenefitCodeId
    ncmIds
  }  
  ${TaxProfileFragment}  
  ${taxBenefitCodeFragment}  
`;