export const preferredDriverParamBuilder = (record, values) => {
  return {
    attributes: values.preferredDrivers.map(object => ({
      ...object,
      personId: record.id
    })),
    personId: record.id
  };
};
export const preferredDriverDefaultBuilder = record => {
  return {
    preferredDrivers: (record || {}).preferredDrivers
  };
};