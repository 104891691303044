export const productInfosParamBuilder = (record, values) => {
  return {
    attributes: values.productInfos.map(object => ({
      ...object,
      productId: record.id
    })),
    productId: record.id
  };
};
export const productInfosDefaultBuilder = record => {
  return {
    productInfos: (record || {}).productInfos || record
  };
};