import gql from "graphql-tag";
import { taxProfileIcmsFragment } from "../fragments/TaxProfileIcms";
export const GQL_TAX_PROFILE_ICMS_STORE = gql`
  mutation StoreTaxProfileIcms($attributes: TaxProfileIcmsAttributes!, $taxProfileId: ID!) {
    storeTaxProfileIcms(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      icms {
        ...TaxProfileIcms
      }, 
      errors 
    } 
  }
  ${taxProfileIcmsFragment}   
`;