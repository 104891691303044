import gql from "graphql-tag";
import { invoiceItemPisFragment } from "./InvoiceItemPis";
export const invoiceItemPisCalculationFragment = gql`
  fragment InvoiceItemPisCalculation on InvoiceItemPisCalculation  { 
    errors,
    status,  
    pis {
      ...InvoiceItemPis
    }
  }  
  ${invoiceItemPisFragment}  
`;