import { dateOrToday, TON } from 'grifo-components';
import { accountPaymentBuilder } from "financial-front";
import { CTE } from '../constants/invoices/InvoiceFieldsOptions';
import { ICMS_TAXPAYER_EXEMPT } from '../constants/personFiscal/PersonFiscalOptions';
import { INSIDE_STATE, NORMAL } from '../constants/taxProfile/TaxFieldsOptions';
import { CONVENTIONAL, CTE_NORMAL, DEFAULT_PRODUCT_MEASURE_DESCRIPTION, RECEIVER_WITHDRAW_NO, RECEIVER_WITHDRAW_YES, ROAD, SENDER } from '../constants/waybills/WaybillFieldsOptions';
export const waybillParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record?.id || null,
      transactionId: values.transactionId,
      operationId: values.operationId,
      taxProfileId: values.taxProfileId,
      cfopId: values.cfopId,
      issuancePurpose: values.issuancePurpose,
      dfeModel: values.dfeModel,
      dfeSeries: values.dfeSeries,
      contributeIcms: values.contributeIcms,
      finalCostumerMethod: values.finalCostumerMethod,
      operationDestination: values.operationDestination,
      modal: values.modal,
      serviceType: values.serviceType,
      serviceTakerIdentifier: values.serviceTakerIdentifier,
      description: values.description,
      additionalTransportationFeature: values.additionalTransportationFeature,
      additionalServiceFeature: values.additionalServiceFeature,
      additionalCargoFeature: values.additionalCargoFeature,
      receiverWithdraw: values.receiverWithdraw ? RECEIVER_WITHDRAW_YES : RECEIVER_WITHDRAW_NO,
      receiverDetail: values.receiverDetail,
      personOriginId: values.personOriginId,
      addressOriginId: values.addressOriginId,
      personDestinationId: values.personDestinationId,
      addressDestinationId: values.addressDestinationId,
      dispatcherId: values.dispatcherId,
      receiverId: values.receiverId,
      serviceTakerId: values.serviceTakerId,
      serviceTakerAddressId: values.serviceTakerAddressId,
      icms: values.icms,
      icmsOrigin: values.icmsOrigin,
      icmsDestination: values.icmsDestination,
      products: (values.products || []).map((object, index) => {
        delete object.product;
        delete object.commercialUnit;
        delete object.packingKind;
        delete object.accessKey;
        delete object.suframaPin;
        delete object.quantityDocument;
        delete object.length;
        delete object.width;
        delete object.height;
        const documents = (object.documents || [{
          quantity: 1
        }]).map((document, index) => {
          delete document.invoice;
          delete document.waybill;
          return {
            ...document
          };
        });
        return {
          ...object,
          documents: documents,
          prevalent: object.prevalent || false
        };
      }),
      expectedDeliveryDate: values.expectedDeliveryDate ? values.expectedDeliveryDate : undefined,
      issuanceDate: values.issuanceDate ? values.issuanceDate : undefined,
      measureType: values.measureType,
      cargoValue: values.cargoValue,
      value: values.value,
      total: values.total,
      hiredId: values.hiredId,
      driverId: values.driverId,
      vehicleOwnerId: values.vehicleOwnerId,
      vehicleId: values.vehicleId,
      payments: values.payments
    }
  };
};
export const waybillDefaultBuilder = (record, props = {}) => {
  const object = record || {};
  object.payments = object.payments || [{
    valueToPay: object.value
  }];
  return {
    id: object.id,
    transactionId: object.transactionId,
    cfopId: object.cfopId,
    operationId: object.operationId,
    taxProfileId: object.taxProfileId,
    contributeIcms: object.contributeIcms || ICMS_TAXPAYER_EXEMPT,
    finalCostumerMethod: object.finalCostumerMethod || NORMAL,
    operationDestination: object.operationDestination || INSIDE_STATE,
    dfeModel: object.dfeModel || CTE,
    dfeSeries: object.dfeSeries,
    issuancePurpose: object.issuancePurpose || CTE_NORMAL,
    modal: object.modal || ROAD,
    serviceType: object.serviceType || CONVENTIONAL,
    serviceTakerIdentifier: object.serviceTakerIdentifier || SENDER,
    description: object.description,
    additionalTransportationFeature: object.additionalTransportationFeature,
    additionalServiceFeature: object.additionalServiceFeature,
    additionalCargoFeature: object.additionalCargoFeature,
    receiverWithdraw: object.receiverWithdraw === RECEIVER_WITHDRAW_YES,
    receiverDetail: object.receiverDetail,
    measureType: object.measureType || TON,
    cargoValue: object.cargoValue || 0,
    value: object.value || 0,
    total: object.total || 0,
    amount: object.value || 0,
    expectedDeliveryDate: dateOrToday(object.expectedDeliveryDate),
    issuanceDate: dateOrToday(object.issuanceDate),
    personOriginId: object.personOriginId,
    addressOriginId: object.addressOriginId,
    personDestinationId: object.personDestinationId,
    addressDestinationId: object.addressDestinationId,
    serviceTakerId: object.serviceTakerId,
    serviceTakerAddressId: object.serviceTakerAddressId,
    dispatcherId: object.dispatcherId,
    receiverId: object.receiverId,
    hiredId: object.hiredId,
    driverId: object.driverId,
    vehicleOwnerId: object.vehicleOwnerId,
    vehicleId: object.vehicleId,
    trailers: object.trailers || [],
    products: object.products || [{
      quantity: 1,
      prevalent: true,
      measureType: DEFAULT_PRODUCT_MEASURE_DESCRIPTION,
      documents: [{
        quantity: 1
      }]
    }],
    icms: object.icms || {
      basisReductionPercentage: 0,
      basis: 0,
      rate: 0,
      value: 0,
      grantedCreditValue: 0
    },
    icmsOrigin: object.icmsOrigin,
    icmsDestination: object.icmsDestination,
    payments: accountPaymentBuilder(object)
  };
};
export const waybillConsultationParamsBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null
    }
  };
};
export const waybillConsultationdefaultValuesBuilder = record => {
  return {};
};