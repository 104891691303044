import { WITHHOLDING, ENTRY_TAXABLE_ZERO_RATE, PER_RATE } from "../constants/taxProfile/TaxFieldsOptions";
export const ipiParamBuilder = (record, values) => {
  return {
    attributes: values.ipi.map(object => ({
      ...object,
      taxProfileId: record.id
    }))
  };
};
export const ipiDefaultBuilder = record => {
  return {
    rate: (record || {}).rate || 0,
    rateByValue: (record || {}).rateByValue || 0,
    kind: (record || {}).kind || WITHHOLDING,
    taxSituation: (record || {}).taxSituation || ENTRY_TAXABLE_ZERO_RATE,
    calculationMethod: (record || {}).calculationMethod || PER_RATE,
    ipiLegalFrameworkId: (record || {}).ipiLegalFrameworkId
  };
};