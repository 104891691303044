import gql from "graphql-tag";
export const stockDailyBalanceFragment = gql`
  fragment StockDailyBalance on StockDailyBalance  {
    id,
    date,
    previous,
    input,
    output,
    balance,
  }
`;