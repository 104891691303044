import gql from "graphql-tag";
import { ncmCestFragment } from "./NcmCest";
export const taxProfileCestFragment = gql`
  fragment TaxProfileCest on TaxProfileCest  { 
    id,
    cestId,
    ncmIds {
      ...NcmCest
    },
  }
  ${ncmCestFragment}
`;