export const electronicDocumentCorrectionParamBuilder = (record, values) => {
  return {
    attributes: {
      electronicDocumentId: record ? record.electronicDocument.id : null,
      accessKey: record ? record.electronicDocument.accessKey : null,
      correction: values.correction
    }
  };
};
export const electronicDocumentCorrectionDefaultBuilder = record => {
  return {
    sequential: (record || {}).sequential,
    correction: (record || {}).correction
  };
};