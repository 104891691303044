import gql from "graphql-tag";
import { taxBenefitCodeFragment } from "../fragments/TaxBenefitCode";
export const GQL_TAX_BENEFIT_CODES_LIST = gql`
  query Search($pageSize: Int, $cursor: String, $taxBenefitCodeFilter: TaxBenefitCodeFilter) {    
    taxBenefitCodesPaginated(first: $pageSize, after: $cursor, taxBenefitCodeFilter: $taxBenefitCodeFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...TaxBenefitCode
        }
      }
    }    
  }
  ${taxBenefitCodeFragment}
`;
export const GQL_TAX_BENEFIT_CODES_FILTER = gql`
query TaxBenefitCodes($filter: TaxBenefitFilter) {    
  taxBenefitCodes(filter: $filter) {
    ...TaxBenefitCode
  }
}
${taxBenefitCodeFragment}

`;
export const GQL_TAX_BENEFIT_CODE_STORE = gql`
  mutation StoreTaxBenefitCode($attributes: TaxBenefitCodeAttributes!) {
    storeTaxBenefitCode(input: {attributes: $attributes}) { 
      taxBenefitCode { 
        ...TaxBenefitCode,
      }, 
      errors 
    } 
  }
  ${taxBenefitCodeFragment}  
`;
export const GQL_IMPORT_TAX_BENEFIT_CODES = gql`
  mutation ImportTaxBenefitCodes($attributes: TaxBenefitCodesImporterAttributes!) {
    importTaxBenefitCodes(input: { attributes: $attributes }) {
      taxBenefitCodes {
        ...TaxBenefitCode
      },
      errors
    }
  }
  ${taxBenefitCodeFragment} 
`;