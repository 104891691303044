import gql from "graphql-tag";
export const stockBatchstatsFragment = gql`
  fragment StockBatchStats on StockBatchStats {
    id,
    number,
    date,
    expiration,
    balance,
    situation,
    personName
  }
`;