import gql from "graphql-tag";
export const fiscalProductInvoiceBalanceFifo = gql`
  fragment FiscalProductInvoiceBalanceFifo on FiscalProductInvoiceBalanceFifo {
    id,
    date,
    supplierName,
    invoiceNumber,
    input,
    output,
    outputPercent,
    remaining,
    remainingPercent
  }
`;