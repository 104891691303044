import { WITHHOLDING, TAXABLE } from "../constants/taxProfile/TaxFieldsOptions";
export const cofinsParamBuilder = (record, values) => {
  return {
    attributes: values.cofins.map(object => ({
      ...object,
      taxProfileId: record.id
    }))
  };
};
export const cofinsDefaultBuilder = record => {
  return {
    rate: (record || {}).rate || 0,
    rateByValue: (record || {}).rateByValue || 0,
    kind: (record || {}).kind || WITHHOLDING,
    taxSituation: (record || {}).taxSituation || TAXABLE,
    basisReductionPercentage: (record || {}).basisReductionPercentage || 0
  };
};