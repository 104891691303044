import gql from "graphql-tag";
import { InvoiceFragment } from "./Invoice";
import { WaybillSimpleFragment } from "./WaybillSimple";
export const WaybillDocumentFragment = gql`
  fragment WaybillDocument on WaybillDocument  { 
    id,
    quantity,
    accessKey,
    suframaPin,
    invoiceId,
    invoice {
      ...Invoice
    }
    waybillId,
    waybill {
      ...WaybillSimple
    }
  }
  ${InvoiceFragment}
  ${WaybillSimpleFragment}
`;