import gql from "graphql-tag";
import { addressFragment, userStoreSimpleFragment, contactFragment } from 'admin-front';
export const fiscalPersonToListFragment = gql`
  fragment FiscalPerson on FiscalPerson {
    id,
    name,
    tradeName,
    document,
    naturalRegistration,
    naturalRegistrationStateId,
    naturalRegistrationAgencyId,
    governmentInstitution, 
    photo64,
    email,
    discardedAt,
    juridical,
    natural,    
    mainAddress {
      ...Address
    },
    user {
      ...UserStoreSimple
    },
    ofCompany {
      id,
    }
    contacts {
      ...Contact
    }
  }
  ${addressFragment}
  ${userStoreSimpleFragment}
  ${contactFragment}
  
`;