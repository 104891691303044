import { TAXABLE, NATIONAL_EXCEPT_3_4_5_8, WITHHOLDING, MVA, FIXED_PRICE_ST } from "../constants/taxProfile/TaxFieldsOptions";
import { icmsStateItemParamBuilder } from "./TaxProfileIcmsStateBuilder";
export const icmsParamBuilder = values => {
  if (!values) return null;
  let preparedAttributes = {
    id: values.id,
    taxSituation: values.taxSituation,
    goodsOrigin: values.goodsOrigin,
    states: values.states.map(object => {
      delete object.state;
      return icmsStateItemParamBuilder(object, values.taxSituation);
    })
  };
  return preparedAttributes;
};
export const icmsDefaultBuilder = record => {
  const object = record || {};
  return {
    taxSituation: object.taxSituation || TAXABLE,
    goodsOrigin: object.goodsOrigin || NATIONAL_EXCEPT_3_4_5_8,
    states: object.states || [{
      kind: WITHHOLDING,
      rate: 0,
      fcpRate: 0,
      basisReductionPercentage: 0,
      methodBasis: MVA,
      rateSt: 0,
      fcpRateSt: 0,
      basisReductionPercentageSt: 0,
      mvaRate: 0,
      methodBasisSt: FIXED_PRICE_ST,
      unencumberedRate: 0,
      unencumberedBasisReduction: 0,
      deferralRate: 0
    }]
  };
};