export const packingKindParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name
    }
  };
};
export const packingKindDefaultBuilder = record => {
  return {
    name: (record || {}).name
  };
};