import gql from "graphql-tag";
import { taxProfileIcmsStateFragment } from "./TaxProfileIcmsState";
export const taxProfileIcmsFragment = gql`
  fragment TaxProfileIcms on TaxProfileIcms  { 
    id,
    taxSituation,
    goodsOrigin,
    states {
      ...TaxProfileIcmsState
    }
  }
  ${taxProfileIcmsStateFragment}  
`;