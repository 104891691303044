import gql from "graphql-tag";
export const fiscalProductStatsFragment = gql`
  fragment FiscalProductStats on FiscalProductStats {
    id,
    balance,
    balanceStats,
    reserved,
    availableBalance,
    lastEntry,
    lastEntryValue,
    lastEntryQuantity,
    entriesEveryDays,
    entryAverage,
    entryAverageValue,
    totalEntries,
    entryReturnRate,
    lastExit,
    lastExitValue,
    lastExitQuantity,
    exitsEveryDays,
    exitAverage,
    exitAverageValue,
    totalExits,
    exitReturnRate,
    receiptExpectation,
    deliveryExpectation,
    minimum,
    maximum,
    medium,
    suggestedMinimum,
    suggestedMaximum,
    stockTurnoverDays,
    stockTurnover,
    stockOut,
    daysToSell,
    daysToReplenish,
    suppliersCount,
    customersCount,
    valueInStock,    
  }
`;