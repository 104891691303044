import gql from "graphql-tag";
import { locationParentFragment } from "../fragments/LocationParent";
export const GQL_SEARCH_LOCATIONS = gql`
  query GetByName($name: String) {    
    locations(name: $name) {
      ...LocationParent
    }
  }
  ${locationParentFragment}  
`;
export const GQL_ALL_AVAILABLE_LOCATIONS = gql`
  query GetAll($all: Boolean) {    
    locations(all: $all) {
      ...LocationParent
    }
  }
  ${locationParentFragment}
`;
export const GQL_LOCATIONS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $locationFilter: LocationFilter) {    
    locationsPaginated(first: $pageSize, after: $cursor, locationFilter: $locationFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...LocationParent
        }
      }
    }    
  }
  ${locationParentFragment}
`;
export const GQL_LOCATION_STORE = gql`
  mutation StoreLocation($attributes: LocationAttributes!) {
    storeLocation(input: {attributes: $attributes}) { 
      location { 
        ...LocationParent
      }, 
      errors 
    } 
  }
  ${locationParentFragment}
`;
export const GQL_LOCATION_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Stock::Location"}) {
      id,
      discardedAt
    }
  }
`;