import gql from "graphql-tag";
export const electronicDocumentBatchFragment = gql`
  fragment ElectronicDocumentBatch on ElectronicDocumentBatch  { 
    id,
    companyId,
    batchId,
    version,
    situation,
    environment,
    datetime,
    receiptCode,
  }
`;