export const ncmParamBuilder = (record, values) => {
  return {
    attributes: values.ncms.map(object => ({
      ...object,
      taxProfileId: record.id
    }))
  };
};
export const ncmDefaultBuilder = record => {
  return {
    ncms: record || []
  };
};