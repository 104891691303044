import gql from "graphql-tag";
import { brandFragment } from "../fragments/Brand";
export const GQL_BRANDS_SELECTION = gql`
  {
    brands {
      ...Brand
    }
  }
  ${brandFragment}   
`;
export const GQL_BRANDS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $brandFilter: BrandFilter) {    
    brandsPaginated(first: $pageSize, after: $cursor, brandFilter: $brandFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Brand
        }
      }
    }    
  }
  ${brandFragment}
`;
export const GQL_BRANDS_BY_ID = gql`
  query ById($id: ID) {
    brands(id: $id) {
      ...Brand
    }
  }
  ${brandFragment} 
`;
export const GQL_BRAND_STORE = gql`
  mutation StoreBrand($attributes: BrandAttributes!) {
    storeBrand(input: {attributes: $attributes}) { 
      brand {
        ...Brand
      }, 
      errors 
    } 
  }
  ${brandFragment}   
`;
export const GQL_BRAND_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Stock::Brand"}) {
      id,
      discardedAt
    }
  }
`;