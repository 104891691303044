import gql from "graphql-tag";
export const stockOperationSimpleFragment = gql`
  fragment StockOperationSimple on StockOperation {
    id,
    name,
    kind,
    generatesMovement,
    subkind,
    internal,
    parentId,
    discardedAt
  }
`;