import gql from "graphql-tag";
import { commercialUnitFragment, batchFragment } from 'stock-front';
export const fiscalProductUnitWithoutTaxInfoFragment = gql`
  fragment FiscalProductUnitWithoutTaxInfo on FiscalProductUnit  {
    id,
    productId,
    packingKindId,
    commercialUnitId,  
    taxableUnitId,
    parentId,
    ncmId,
    barcode,
    multiplier,
    value,
    grossWeight,
    netWeight,
    height,
    width,
    length,
    discardedAt,
    minimum,
    maximum,
    productionTracking,
    commercialUnit {
      ...CommercialUnit
    }
    batches {
      ...Batch
    }
  }
  ${commercialUnitFragment}
  ${batchFragment}
`;