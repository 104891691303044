import { invoiceDefaultBuilder } from 'fiscal-front';
export const bidParamBuilder = (record, values) => {
  let id = ((record || {}).bid || {}).id;
  return {
    attributes: {
      id: id,
      quantity: values.quantity,
      offerId: values.offerId,
      companyId: values.companyId,
      payments: (values.payments || []).map((object, index) => {
        delete object.paymentMethod;
        delete object.paymentCondition;
        return {
          ...object
        };
      })
    }
  };
};
export const justificationBuilder = (record, values) => {
  return {
    id: values.bidId,
    reason: values.reason
  };
};
export const bidDefaultBuilder = record => {
  let offerId;
  let companyId = (record || {}).companyId;
  if (record) {
    offerId = record.id;
    if (record.bid) {
      record = record.bid || {};
      offerId = (record || {}).offerId;
      companyId = (record || {}).company.id;
    }
  }
  return {
    quantity: (record || {}).quantity || 1,
    bid: (record || {}).bid,
    offerId: offerId,
    companyId: companyId,
    payments: (record || {}).payments || [{}],
    invoice: invoiceDefaultBuilder(record.invoice),
    currentEvent: (record || {}).currentEvent
  };
};