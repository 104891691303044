import { packObjectList } from 'grifo-components';
export const fuelPumpBrandParamBuilder = (record, values) => {
  return {
    attributes: values.fuelPumpBrands.map(object => ({
      ...object,
      fuelPumpModels: packObjectList(object.fuelPumpModels, "fuelPumpModelId"),
      fuelPumpId: record.id
    })),
    fuelPumpId: record.id
  };
};
export const fuelPumpBrandDefaultBuilder = record => {
  return {
    fuelPumpBrands: (record || {}).fuelPumpBrands
  };
};