import gql from "graphql-tag";
import { batchFragment } from "./Batch";
import { locationSimpleFragment } from "./LocationSimple";
import { productSimpleFragment } from "./ProductSimple";
import { productUnitSimpleForSearchFragment } from "./ProductUnitSimpleForSearch";
export const productSearchFragment = gql`
  fragment ProductSearch on ProductSearch  {
    id,
    balance,
    product {
      ...ProductSimple
    }
    productUnit {
      ...ProductUnitSimpleForSearch
    }
    location {
      ...LocationSimple
    }
    batch {
      ...Batch
    }
  }
  ${productSimpleFragment}
  ${productUnitSimpleForSearchFragment}
  ${locationSimpleFragment}  
  ${batchFragment}  
`;