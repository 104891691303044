import gql from "graphql-tag";
import { trailerFragment } from "../fragments/Trailer";
export const GQL_TRAILERS_SEARCH = gql`
  {
    trailers { 
      ...Trailer
    }
  }
  ${trailerFragment}   
`;
export const GQL_TRAILERS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $trailerFilter: TrailerFilter) {    
    trailersPaginated(first: $pageSize, after: $cursor, trailerFilter: $trailerFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Trailer
        }
      }
    }    
  }
  ${trailerFragment}
`;
export const GQL_TRAILERS_STORE = gql`
  mutation StoreTrailer($attributes: TrailerAttributes!) {
    storeTrailer(input: {attributes: $attributes}) { 
      trailer {
        ...Trailer
    }, 
      errors 
    } 
  }
  ${trailerFragment}   
`;
export const GQL_TRAILERS_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Fleet::Trailer"}) {
      id,
      discardedAt
    }
  }
`;