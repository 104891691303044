import gql from "graphql-tag";
import { TaxProfileFragment } from "./TaxProfile";
import { stateFragment } from 'admin-front';
export const WaybillIcmsDestinationFragment = gql`
  fragment WaybillIcmsDestination on WaybillIcmsDestination  { 
    id,
    basis,
    rate,
    value,
    interstateRate,
    originValue
    fcpBasis,
    fcpRate,
    fcpValue,
    taxProfileId,
    taxProfile {
      ...TaxProfile
    }
    stateId,
    state { 
      ...State
    }  
  }    
  ${TaxProfileFragment}  
  ${stateFragment}
`;