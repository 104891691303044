import gql from "graphql-tag";
import { cestFragment } from "../fragments/Cest";
export const GQL_CESTS_SELECTION = gql`
  {
    cests {
      ...Cest,
    }    
  }
  ${cestFragment}
`;
export const GQL_CESTS_LIST = gql`
  query Search($pageSize: Int, $cursor: String, $cestFilter: CestFilter) {    
    cestsPaginated(first: $pageSize, after: $cursor, cestFilter: $cestFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Cest
        }
      }
    }    
  }
  ${cestFragment}
`;
export const GQL_CESTS_BY_NCMS = gql`
  query GetByNcms($ncmIds: [String!]) {    
    cests(ncmIds: $ncmIds) { 
        ...Cest
    }
  }
  ${cestFragment}   
`;
export const GQL_CEST_STORE = gql`
  mutation StoreCest($attributes: CestAttributes!) {
    storeCest(input: {attributes: $attributes}) { 
      cest { 
        ...Cest,
      }, 
      errors 
    } 
  }
  ${cestFragment}  
`;