import gql from "graphql-tag";
import { ownerTrailerFragment } from "./OwnerTrailer";
import { vehicleBodyworkFragment } from "./VehicleBodywork";
export const trailerFragment = gql`
  fragment Trailer on Trailer  { 
    id,
    licensingStateId,
    vehicleBodyworkId,
    vehicleBodywork {
      ...VehicleBodywork
    }
    plate,
    vin,
    renavamCode,
    manufactureYear,
    modelYear,
    identifier,
    height,
    width,
    length,
    maximumWeight,
    bodyType,
    tareWeight,
    discardedAt,
    ownerTrailer{
      ...OwnerTrailer
    }
    volumeCapacity,
    documents64 {
      id,
      data,
      filename,
      contentType,
      key
    },
  }
  ${vehicleBodyworkFragment}
  ${ownerTrailerFragment}
`;