import gql from "graphql-tag";
import { taxProfileRetentionFragment } from "../fragments/TaxProfileRetention";
export const GQL_TAX_PROFILE_RETENTION_STORE = gql`
  mutation StoreTaxProfileRetention($attributes: TaxProfileRetentionAttributes!, $taxProfileId: ID!) {
    storeTaxProfileRetention(input: {attributes: $attributes, taxProfileId: $taxProfileId}) {
      retentions {
        ...TaxProfileRetention
      },
      errors
    }
  }
  ${taxProfileRetentionFragment}
`;