import gql from "graphql-tag";
import { NcmFragment } from "./Ncm";
export const ipiIncidenceFragment = gql`
  fragment IpiIncidence on IpiIncidence  { 
    id,
    rate,
    start,
    finish,
    ncmId,
    ncm {
      ...Ncm
    }
  }
  ${NcmFragment}

`;