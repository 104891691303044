import gql from "graphql-tag";
export const NcmFragment = gql`
  fragment Ncm on Ncm {
    id,
    code,
    chapter,
    position,
    subposition,
    item,
    subitem,
    description,
    start,
    finish
  }
`;