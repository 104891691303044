export const STOCK_STATS_OUT_OF_STOCK = 'out_of_stock';
export const STOCK_STATS_IN_STOCK = 'in_stock';
export const STOCK_STATS_ABOVE_MAXIMUM = 'above_maximum';
export const STOCK_STATS_BELOW_MINIMUM = 'below_minimum';
export const STOCK_STATS_ALMOST_ZERO = 'almost_zero';
export const STOCK_STATS_GOOD = 'good';
export const STOCK_STATS_LOW = 'low';
export const STOCK_STATS_HIGH = 'high';
export const STOCK_STATS_MODERATE = 'moderate';
export const STOCK_STATS_OPTIONS = [{
  value: STOCK_STATS_OUT_OF_STOCK,
  label: 'Sem estoque'
}, {
  value: STOCK_STATS_IN_STOCK,
  label: 'Em estoque'
}, {
  value: STOCK_STATS_ABOVE_MAXIMUM,
  label: 'Acima do máximo'
}, {
  value: STOCK_STATS_BELOW_MINIMUM,
  label: 'Abaixo do mínimo'
}, {
  value: STOCK_STATS_ALMOST_ZERO,
  label: 'Quase zerado'
}, {
  value: STOCK_STATS_GOOD,
  label: 'Bom'
}, {
  value: STOCK_STATS_LOW,
  label: 'Baixo'
}, {
  value: STOCK_STATS_HIGH,
  label: 'Alto'
}, {
  value: STOCK_STATS_MODERATE,
  label: 'Moderado'
}];