import moment from 'moment';
export const personTrailerParamBuilder = (record, values) => {
  return {
    attributes: values.driverTrailers,
    personId: record.id
  };
};
export const personTrailerDefaultBuilder = record => {
  return {
    driverTrailers: ((record || {}).driverVehicles || []).vehicleTrailers?.map(object => ({
      ...object,
      id: object.id,
      trailerId: ((object || {}).trailer || {}).id,
      onwer: (object || {}).owner,
      turnOwner: !(object || {}).owner || true,
      start: object.start ? moment(object.start) : null,
      finish: object.finish ? moment(object.finish) : null,
      renavamCode: ((object || {}).trailer || {}).renavamCode,
      plate: ((object || {}).trailer || {}).plate,
      vin: ((object || {}).trailer || {}).vin,
      vehicleBodyworkId: ((object || {}).trailer || {}).vehicleBodyworkId,
      tracker: ((object || {}).trailer || {}).tracker || true,
      owner: ((object || {}).trailer || {}).owner || true,
      manufactureYear: (((object || {}).trailer || {}).manufactureYear || {}).toString() || null,
      modelYear: (((object || {}).trailer || {}).modelYear || {}).toString() || null,
      identifier: ((object || {}).trailer || {}).identifier,
      height: ((record || {}).Trailer || {}).height,
      width: ((record || {}).Trailer || {}).width,
      length: ((record || {}).Trailer || {}).length,
      netWeight: ((record || {}).Trailer || {}).netWeight,
      maximumWeight: ((record || {}).Trailer || {}).maximumWeight
    }))
  };
};