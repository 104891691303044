import gql from "graphql-tag";
import { taxProfileCfopFragment } from "../fragments/TaxProfileCfop";
export const GQL_TAX_PROFILE_CFOP_STORE = gql`
  mutation StoreTaxProfileCfop($attributes: TaxProfileCfopAttributes!, $taxProfileId: ID!) {
    storeTaxProfileCfop(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      cfops {
        ...TaxProfileCfop
      }, 
      errors 
    } 
  }
  ${taxProfileCfopFragment}   
`;