import gql from "graphql-tag";
import { vehicleBrandFragment } from "../fragments/VehicleBrand";
export const GQL_VEHICLE_BRANDS_SEARCH = gql`
  {
    vehicleBrands { 
      ...VehicleBrand
    }
  }
  ${vehicleBrandFragment}   
`;
export const GQL_VEHICLE_BRANDS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $vehicleBrandFilter: VehicleBrandFilter) {    
    vehicleBrandsPaginated(first: $pageSize, after: $cursor, vehicleBrandFilter: $vehicleBrandFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...VehicleBrand
        }
      }
    }    
  }
  ${vehicleBrandFragment}
`;
export const GQL_VEHICLE_BRANDS_STORE = gql`
  mutation StoreVehicleBrand($attributes: VehicleBrandAttributes!) {
    storeVehicleBrand(input: {attributes: $attributes}) { 
      vehicleBrand {
        ...VehicleBrand
    }, 
      errors 
    } 
  }
  ${vehicleBrandFragment}   
`;
export const GQL_VEHICLE_BRANDS_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Fleet::VehicleBrand"}) {
      id,
      discardedAt
    }
  }
`;