import gql from "graphql-tag";
import { stockFlowLocationFragment } from "./StockFlowLocation";
export const stockFlowFragment = gql`
  fragment StockFlow on StockFlow  {
    id,
    date,
    locations {
      ...StockFlowLocation
    }
  }
  ${stockFlowLocationFragment}
`;