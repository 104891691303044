export { default as CustomerFields } from './customers/CustomerFields';
export { default as CustomerForm } from './customers/CustomerForm';
export { default as PersonTrailerAction } from './customers/PersonTrailerAction';
export { default as PersonVehicleAction } from './customers/PersonVehicleAction';
export { default as PreferredDriversAction } from './customers/PreferredDriversAction';
export { default as PreferredDriversFields } from './customers/PreferredDriversFields';
export { default as DriverFields } from './drivers/DriverFields';
export { default as DriverSelectionField } from './drivers/DriverSelectionField';
export { default as TrailerSelectionField } from './trailers/TrailerSelectionField';
export { default as DriverVehicleAction } from './drivers/DriverVehicleAction';
export { default as TrailerFields } from './trailers/TrailerFields';
export { default as VehicleBodyworkFields } from './vehicleBodyworks/VehicleBodyworkFields';
export { default as VehicleBodyworkSelectionField } from './vehicleBodyworks/VehicleBodyworkSelectionField';
export { default as VehicleBrandFields } from './vehicleBrand/VehicleBrandFields';
export { default as VehicleBrandSelectionField } from './vehicleBrand/VehicleBrandSelectionField';
export { default as VehicleModelFields } from './vehicleBrand/VehicleModelFields';
export { default as VehicleModelSelectionField } from './vehicleBrand/VehicleModelSelectionField';
export { default as CodeField } from './vehicles/CodeField';
export { default as PlateField } from './vehicles/PlateField';
export { default as VehicleFields } from './vehicles/VehicleFields';
export { default as VehicleSelectionField } from './vehicles/VehicleSelectionField';
export { default as VehicleTypeFields } from './vehicleTypes/VehicleTypeFields';
export { default as VehicleTypeSelectionField } from './vehicleTypes/VehicleTypeSelectionField';