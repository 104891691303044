import gql from "graphql-tag";
import { personFragment } from 'admin-front';
import { productModelFragment } from "./ProductModel";
export const brandSimpleFragment = gql`
  fragment BrandSimple on Brand  {
    id,
    name,
    discardedAt,
  }
  ${personFragment}
  ${productModelFragment}
`;