import gql from "graphql-tag";
export const CfopFragment = gql`
  fragment Cfop on Cfop {
    id,
    code, 
    name,
    usage, 
    fuelUsage,
    dfeUsage,
    destination,
    kind,
    documents,
    start,
    finish
  }
`;