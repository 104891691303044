export { default as CustomerPage } from './customers/CustomerPage';
export { default as CustomersPage } from './customers/CustomersPage';
export { default as PersonTrailersPage } from './customers/PersonTrailersPage';
export { default as PersonVehiclesPage } from './customers/PersonVehiclesPage';
export { default as PreferredDriversPage } from './customers/PreferredDriversPage';
export { default as DriverPage } from './drivers/DriverPage';
export { default as DriversPage } from './drivers/DriversPage';
export { default as DriverTrailersPage } from './drivers/DriverTrailersPage';
export { default as DriverVehiclesPage } from './drivers/DriverVehiclesPage';
export { default as VehicleBodyworkPage } from './vehicleBodyworks/VehicleBodyworkPage';
export { default as VehicleBodyworksPage } from './vehicleBodyworks/VehicleBodyworksPage';
export { default as VehicleBrandPage } from './vehicleBrands/VehicleBrandPage';
export { default as VehicleBrandsPage } from './vehicleBrands/VehicleBrandsPage';
export { default as VehicleTypePage } from './vehicleTypes/VehicleTypePage';
export { default as VehicleTypesPage } from './vehicleTypes/VehicleTypesPage';