export const brandParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      personId: values.personId,
      productModels: values.productModels
    }
  };
};
export const brandDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    personId: ((record || {}).person || {}).id,
    productModels: (record || {}).productModels
  };
};