export const personFiscalStsParamBuilder = (record, values) => {
  return {
    attributes: values.personFiscalSt.map(object => ({
      ...object,
      personId: record.id
    }))
  };
};
export const personFiscalStsDefaultBuilder = record => {
  return {
    sts: (record || {}).sts
  };
};