import gql from "graphql-tag";
import { productModelFragment } from "./ProductModel";
export const productBrandFragment = gql`
  fragment ProductBrand on ProductBrand  {
    id,
    productId,
    brandId,
    code,
    productModels {
      ...ProductModel
    },
  }
  ${productModelFragment}
`;