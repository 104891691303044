import gql from "graphql-tag";
import { addressFragment, userStoreSimpleFragment } from 'admin-front';
import { vehicleFragment, fleetPersonFragment } from 'fleet-front';
import { fiscalOperationFragment } from "./Operation";
import { fiscalPersonFragment } from "./Person";
import { WaybillTrailerFragment } from "./WaybillTrailer";
import { WaybillAdditionalInfoFragment } from "./WaybillAdditionalInfo";
import { WaybillProductFragment } from "./WaybillProduct";
import { TaxProfileFragment } from "./TaxProfile";
import { electronicDocumentFragment } from "./ElectronicDocument";
export const WaybillFragment = gql`
  fragment Waybill on Waybill  { 
    id,
    cooperativeAct,
    transactionId,
    contributeIcms,
    finalCostumerMethod,
    operationDestination,
    dfeSeries,
    dfeModel,
    dfeNumber,
    issuancePurpose,
    issuanceDate,
    serviceType,
    modal,
    receiverWithdraw,
    receiverDetail,
    serviceTakerIdentifier,
    description,
    additionalTransportationFeature,
    additionalServiceFeature,
    additionalCargoFeature,
    cargoValue,
    value,
    total,
    totalWeight,
    expectedDeliveryDate,

    userId,
    user {
      ...UserStoreSimple
    },
    taxProfileId,
    taxProfile {
      ...TaxProfile
    },
    operationId,
    operation {
      ...FiscalOperation
    },
    cfopId,
    cfop {
      ...Cfop
    },
    cityIssuerId,
    cityIssuer {
      ...City
    },
    stateIssuerId,
    stateIssuer {
      ...State
    },
    personOriginId,
    personOrigin {
      ...FiscalPerson
    },
    addressOriginId,
    addressOrigin {
      ...Address
    },
    cityOriginId,
    cityOrigin {
      ...City
    },
    cityStartId,
    cityStart {
      ...City
    },
    dispatcherId,
    dispatcher {
      ...FiscalPerson
    },
    personDestinationId,
    personDestination {
      ...FiscalPerson
    },
    addressDestinationId,
    addressDestination {
      ...Address
    },
    cityDestinationId,
    cityDestination {
      ...City
    },
    receiverId,
    receiver {
      ...FiscalPerson
    },
    serviceTakerId,
    serviceTaker {
      ...FiscalPerson
    },
    serviceTakerAddressId,
    serviceTakerAddress {
      ...Address
    },
    hiredId,
    hired {
      ...FiscalPerson
    },
    driverId,
    driver {
      ...FleetPerson
    }
    vehicleOwnerId,
    vehicleOwner {
    ...FleetPerson
    }
    vehicleId,
    vehicle {
      ...Vehicle
    },
    electronicDocument {
      ...ElectronicDocument
    }
    trailers {
      ...WaybillTrailer
    },
    products {
      ...WaybillProduct
    },
    infos {
      ...WaybillAdditionalInfo
    }
  }
  ${userStoreSimpleFragment}
  ${fiscalPersonFragment}
  ${addressFragment}  
  ${TaxProfileFragment}  
  ${fiscalOperationFragment}  
  ${WaybillTrailerFragment}
  ${WaybillAdditionalInfoFragment}
  ${WaybillProductFragment} 
  ${fleetPersonFragment}
  ${vehicleFragment}
  ${electronicDocumentFragment}
`;