export const IMMUNITY = 'immunity';
export const SUSPENSION = 'suspension';
export const EXEMPTION = 'exemption';
export const REDUCTION = 'reduction';
export const OTHERS = 'others';
export const IPI_GROUP_OPTIONS = [{
  value: IMMUNITY,
  label: 'Imunidade'
}, {
  value: SUSPENSION,
  label: 'Suspensão'
}, {
  value: EXEMPTION,
  label: 'Isenção'
}, {
  value: REDUCTION,
  label: 'Redução'
}, {
  value: OTHERS,
  label: 'Outros'
}];