import gql from "graphql-tag";
import { taxProfileIssFragment } from "../fragments/TaxProfileIss";
export const GQL_TAX_PROFILE_ISS_STORE = gql`
  mutation StoreTaxProfileIss($attributes: TaxProfileIssAttributes!, $taxProfileId: ID!) {
    storeTaxProfileIss(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      iss {
        ...TaxProfileIss
      }, 
      errors 
    } 
  }
  ${taxProfileIssFragment}   
`;