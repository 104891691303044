import gql from "graphql-tag";
import { requestFragment } from "../fragments/Request";
import { parentRequestFragment } from "../fragments/RequestParent";
import { requestProductUnitFragment } from "../fragments/RequestProductUnit";
export const GQL_REQUESTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $requestFilter: RequestFilter) {    
    requestsPaginated(first: $pageSize, after: $cursor, requestFilter: $requestFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...ParentRequest
        }
      }
    }    
  }
  ${parentRequestFragment}
`;
export const GQL_REQUEST_STORE = gql`
  mutation StoreRequest($attributes: SimpleRequestAttributes!) {
    storeRequest(input: {attributes: $attributes}) { 
        request {
        ...Request
    }, 
      errors 
    } 
  }
  ${requestFragment}   
`;
export const GQL_GROUP_REQUESTS = gql`
mutation GroupRequests($requestIds: RequestIds!) {
  groupRequests(input: {requestIds: $requestIds}) { 
      request {
      ...Request
  }, 
    errors 
  } 
}
${requestFragment}   
`;
export const GQL_REQUEST_PRODUCT_UNIT_BY_REQUEST_LIST = gql`
  query GetByRequest($request: ID) { 
    requestProductUnits { 
      ...RequestProductUnit
    }
  }
  ${requestProductUnitFragment}   
`;
export const GQL_CHANGE_REQUESTED_EVENT = gql`
  mutation ChangeRequestedEvent($attributes: SuggestedAttributes!) { 
    changeRequestedEvent(input: {attributes: $attributes}) {
      id,
      errors
    }
  }
`;
export const GQL_DENIED_EVENT = gql`
  mutation DeniedEvent($id: ID!) { 
    deniedEvent(input: {id: $id}) {
      id
    }
  }
`;
export const GQL_ORDER_EVENT = gql`
  mutation OrderEvent($attributes: RequestInvoiceOrderAttributes!) { 
    orderEvent(input: {attributes: $attributes}) {
      id,
      errors
    }
  }
`;
export const GQL_APPROVE_REQUEST_EVENT = gql`
  mutation ApproveRequestEvent($id: ID!) { 
    approveRequestEvent(input: {id: $id}) {
      id
    }
  }
`;
export const GQL_IN_ANALYSIS_EVENT = gql`
  mutation InAnalisysEvent($id: ID!) { 
    inAnalisysEvent(input: {id: $id}) {
      id
    }
  }
`;
export const GQL_COMPANY_REQUEST_LIST = gql`
  query GetRequests($pageSize: Int, $cursor: String, $companyRequestFilter: CompanyRequestFilter) {    
    requestByCompanyPaginated(first: $pageSize, after: $cursor, companyRequestFilter: $companyRequestFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...ParentRequest
        }
      }
    }    
  }
  ${parentRequestFragment}
`;
export const GQL_CANCEL_REQUEST = gql`
  mutation CancelRequest($id: ID!) { 
    cancelRequest(input: {id: $id}) {
      id
    }
  }
`;
export const GQL_COMPANY_CANCEL_REQUEST = gql`
  mutation CompanyCancelRequest($id: ID!) { 
    companyCancelRequest(input: {id: $id}) {
      id
    }
  }
`;
export const GQL_CHANGE_REQUESTED = gql`
  mutation ChangeConfirmedRequest($id: ID!) { 
    changeConfirmedRequest(input: {id: $id}) {
      id
    } 
  }
`;
export const GQL_REQUESTS_BY_PERSON_LIST = gql`
  query GetByPerson($pageSize: Int, $cursor: String, $person: Boolean) {    
    requestsPaginated(first: $pageSize, after: $cursor, person: $person) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...ParentRequest
        }
      }
    }    
  }
  ${parentRequestFragment}
`;