import gql from "graphql-tag";
import { taxProfileCsllFragment } from "../fragments/TaxProfileCsll";
export const GQL_TAX_PROFILE_CSLL_STORE = gql`
  mutation StoreTaxProfileCSLL($attributes: TaxProfileCsllAttributes!, $taxProfileId: ID!) {
    storeTaxProfileCsll(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      csll {
        ...TaxProfileCsll
      }, 
      errors 
    } 
  }
  ${taxProfileCsllFragment}   
`;