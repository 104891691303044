import gql from "graphql-tag";
import { ipiIncidenceFragment } from "../fragments/IpiIncidence";
export const GQL_IPI_INCIDENCE_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $ipiIncidenceFilter: IpiIncidenceFilter) {    
    ipiIncidencePaginated(first: $pageSize, after: $cursor, ipiIncidenceFilter: $ipiIncidenceFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...IpiIncidence
        }
      }
    }    
  }
  ${ipiIncidenceFragment}
`;
export const GQL_IPI_INCIDENCE_STORE = gql`
  mutation StoreIpiIncidence($attributes: IpiIncidenceAttributes!) {
    storeIpiIncidence(input: {attributes: $attributes}) { 
      ipiIncidence {
        ...IpiIncidence,
      }, 
      errors 
    } 
  }
  ${ipiIncidenceFragment}   
`;