export const NORMAL = 'normal';
export const RESERVE = 'reserve';
export const UNRESERVE = 'unreserve';
export const REVERSAL = 'reversal';
export const LOSS = 'loss';
export const GATHER = 'gather';
export const OPERATIONS_SUBKIND = [{
  value: NORMAL,
  label: 'Normal'
}, {
  value: LOSS,
  label: 'Perda'
}, {
  value: RESERVE,
  label: 'Reserva',
  disabled: true
}, {
  value: UNRESERVE,
  label: 'Retorno de reserva',
  disabled: true
}, {
  value: REVERSAL,
  label: 'Estorno',
  disabled: true
}, {
  value: GATHER,
  label: 'Coleta',
  disabled: true
}];