import moment from 'moment';
export const ncmParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      code: values.code,
      description: values.description,
      chapter: values.chapter,
      position: values.position,
      subposition: values.subposition,
      item: values.item,
      subitem: values.subitem,
      start: values.start,
      finish: values.finish
    }
  };
};
export const ncmDefaultBuilder = record => {
  const start = (record || {}).start;
  const finish = (record || {}).finish;
  return {
    code: (record || {}).code,
    description: (record || {}).description,
    chapter: (record || {}).chapter,
    position: (record || {}).position,
    subposition: (record || {}).subposition,
    item: (record || {}).item,
    subitem: (record || {}).subitem,
    start: start ? moment(start) : moment(Date()),
    finish: finish ? moment(finish) : null
  };
};