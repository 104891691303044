import gql from "graphql-tag";
import { ipiLegalFrameworkFragment } from "../fragments/IpiLegalFramework";
export const GQL_IPI_LEGAL_FRAMEWORK_SELECTION = gql`
  {
    ipiLegalFrameworks { 
      ...IpiLegalFramework,
    }
  }
  ${ipiLegalFrameworkFragment}
`;
export const GQL_IPI_LEGAL_FRAMEWORK_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $ipiLegalFrameworkFilter: IpiLegalFrameworkFilter) {    
    ipiLegalFrameworksPaginated(first: $pageSize, after: $cursor, ipiLegalFrameworkFilter: $ipiLegalFrameworkFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...IpiLegalFramework
        }
      }
    }    
  }
  ${ipiLegalFrameworkFragment}
`;
export const GQL_IPI_LEGAL_FRAMEWORK_STORE = gql`
  mutation StoreIpiLegalFramework($attributes: IpiLegalFrameworkAttributes!) {
    storeIpiLegalFramework(input: {attributes: $attributes}) { 
      ipiLegalFramework {
        ...IpiLegalFramework,
      }, 
      errors 
    } 
  }
  ${ipiLegalFrameworkFragment}   
`;
export const GQL_IPI_LEGAL_FRAMEWORK_INACTIVATE = gql`
mutation Inactivate($id: ID!) {
  inactivate(input: {id: $id, className: "Fiscal::IpiLegalFramework"}) {
    id,
    discardedAt 
  }
}
`;