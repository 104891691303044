import gql from "graphql-tag";
import { TaxProfileFragment } from "./TaxProfile";
export const invoiceItemIpiFragment = gql`
  fragment InvoiceItemIpi on InvoiceItemIpi  { 
    id,
    taxSituation,
    ipiLegalFrameworkId,
    basis,
    rate,
    quantity,
    rateByValue,
    value,
    taxProfile {
      ...TaxProfile
    }
  }  
  ${TaxProfileFragment}  
`;