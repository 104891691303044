import gql from "graphql-tag";
import { fiscalOperationSimpleFragment } from "./OperationSimple";
export const fiscalOperationFragment = gql`
  fragment FiscalOperation on FiscalOperation  { 
      ...FiscalOperationSimple
      parent {
        ...FiscalOperationSimple
      },
  }
  ${fiscalOperationSimpleFragment}
`;
export const fiscalOperationAllFragment = gql`
  fragment FiscalOperation on FiscalOperation  { 
      ...FiscalOperationSimple
  }
  ${fiscalOperationSimpleFragment}
`;