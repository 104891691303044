import { DEFAULT_PRODUCT_MEASURE_DESCRIPTION } from "../constants/waybills/WaybillFieldsOptions";
export const loadProduct = (form, record, idx) => {
  if (!form && !record) return;
  let fieldsValue = form ? form.getFieldsValue() : record ? record : {};
  let products = fieldsValue.products || [];
  let product = products[idx] || {};
  product = {
    productId: product.productId || (product.product || {}).id,
    commercialUnitId: product.commercialUnitId,
    packingKindId: product.packingKindId,
    quantity: product.quantity || 1,
    measureType: product.measureType || DEFAULT_PRODUCT_MEASURE_DESCRIPTION,
    prevalent: product.prevalent,
    length: product.length || 0,
    width: product.width || 0,
    height: product.height || 0
  };
  return product;
};
export const productDefaultBuilder = form => {
  const product = loadProduct(form);
  if (!product) return;
  let fieldsValue = form.getFieldsValue();
  let products = fieldsValue.products || [];
  const index = form.getFieldsValue().products.length - 1;
  products[index] = product;
  fieldsValue.products = products;
  form.setFieldsValue(fieldsValue);
  return product;
};