import gql from "graphql-tag";
import { fleetPersonSimpleFragment } from "./FleetPersonSimple";
import { vehicleSimpleFragment } from "./VehicleSimple";
export const simpleDriverFragment = gql`
  fragment SimpleDriver on Driver  { 
    id,
    person {
      ...FleetPersonSimple
    },
    actualVehicle {
      ...VehicleSimple
    }
    discardedAt
  }
  ${fleetPersonSimpleFragment}
  ${vehicleSimpleFragment}
`;