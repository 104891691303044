import gql from "graphql-tag";
export const batchFragment = gql`
  fragment Batch on Batch  {
    id,
    productUnitId,
    number,
    manufacturingDate,
    expirationDate,
    personId,
  }
`;