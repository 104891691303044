import gql from "graphql-tag";
import { personFiscalStartFragment } from "../fragments/PersonFiscalStart";
export const GQL_PERSON_FISCAL_START_STORE = gql`
  mutation StorePersonFiscalStart($attributes: PersonFiscalStartAttributes!, $personId: ID!) {
    storePersonFiscalStart(input: {attributes: $attributes, personId: $personId}) { 
      personFiscalStart { 
        ...PersonFiscalStart,
      }, 
      errors 
    } 
  }
  ${personFiscalStartFragment}  
`;