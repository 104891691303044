export const cfopParamBuilder = (record, values) => {
  return {
    attributes: values.cfops.map(object => ({
      ...object,
      taxProfileId: record.id
    }))
  };
};
export const cfopDefaultBuilder = record => {
  return {
    cfops: record || []
  };
};