import moment from 'moment';
import { sanitize } from 'grifo-components';
export const vehicleParamBuilder = (record, values) => {
  return {
    attributes: vehicleAttributesBuilder(record, values)
  };
};
export const vehicleAttributesBuilder = (record, values) => {
  const owner = values.owner;
  const turnOwner = values.turnOwner;
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    id: record ? record.id : null,
    start: values.start,
    finish: values.finish,
    vehicle: {
      id: values.vehicleId,
      turnOwner: turnOwner === false ? turnOwner : turnOwner === true ? turnOwner : owner,
      manufactureYear: values.manufactureYear ? parseInt(values.manufactureYear) : undefined,
      modelYear: values.modelYear ? parseInt(values.modelYear) : undefined,
      identifier: values.identifier,
      renavamCode: values.renavamCode,
      plate: values.plate,
      tare: values.tare,
      wheightCapacity: values.wheightCapacity,
      volumeCapacity: values.volumeCapacity,
      vin: values.vin,
      tracker: values.tracker ? values.tracker || false : undefined,
      bodyType: values.bodyType,
      stateId: values.stateId,
      vehicleBrandId: values.vehicleBrandId,
      vehicleModelId: values.vehicleModelId,
      vehicleTypeId: values.vehicleTypeId,
      documents64: documents64
    }
  };
};
export const vehicleSimpleAttributesBuilder = (record, values) => {
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  let vehicleTrailers = sanitize(values.vehicleTrailers || [], ['trailer']) || [];
  vehicleTrailers = vehicleTrailers.filter((relation, index, self) => index === self.findIndex(t => t.trailerId === relation.trailerId));
  return {
    attributes: {
      id: record ? record.id : null,
      ownerId: values.ownerId,
      driverId: values.driverId,
      manufactureYear: values.manufactureYear ? parseInt(values.manufactureYear) : undefined,
      modelYear: values.modelYear ? parseInt(values.modelYear) : undefined,
      identifier: values.identifier,
      renavamCode: values.renavamCode,
      plate: values.plate,
      tare: values.tare,
      wheightCapacity: values.wheightCapacity,
      volumeCapacity: values.volumeCapacity,
      vin: values.vin,
      tracker: values.tracker ? values.tracker || false : undefined,
      bodyType: values.bodyType,
      stateId: values.stateId,
      vehicleBrandId: values.vehicleBrandId,
      vehicleModelId: values.vehicleModelId,
      vehicleTypeId: values.vehicleTypeId,
      documents64: documents64,
      vehicleTrailers: vehicleTrailers,
      origin: values.origin || 0
    }
  };
};
export const vehicleDefaultBuilder = record => {
  return {
    id: (record || {}).id,
    vehicleId: ((record || {}).vehicle || {}).id,
    owner: ((record || {}).vehicle || {}).owner ? ((record || {}).vehicle || {}).owner || false : undefined,
    turnOwner: ((record || {}).vehicle || {}).owner ? (!(record || {}).vehicle || {}).owner || false : undefined,
    start: (record || {}).start ? moment(record.start) : undefined,
    finish: (record || {}).finish ? moment(record.finish) : undefined,
    renavamCode: ((record || {}).vehicle || {}).renavamCode,
    plate: ((record || {}).vehicle || {}).plate,
    vin: ((record || {}).vehicle || {}).vin,
    tare: ((record || {}).vehicle || {}).tare || 0,
    wheightCapacity: ((record || {}).vehicle || {}).wheightCapacity || 0,
    volumeCapacity: ((record || {}).vehicle || {}).volumeCapacity || 0,
    bodyType: ((record || {}).vehicle || {}).bodyType,
    stateId: ((record || {}).vehicle || {}).stateId,
    vehicleBrandId: ((record || {}).vehicle || {}).vehicleBrandId,
    vehicleModelId: ((record || {}).vehicle || {}).vehicleModelId,
    vehicleTypeId: ((record || {}).vehicle || {}).vehicleTypeId,
    tracker: (record || {}).tracker ? ((record || {}).vehicle || {}).tracker || false : undefined,
    manufactureYear: (record || {}).vehicle ? (((record || {}).vehicle || {}).manufactureYear || {}).toString() : undefined,
    modelYear: (record || {}).vehicle ? (((record || {}).vehicle || {}).modelYear || {}).toString() : undefined,
    identifier: ((record || {}).vehicle || {}).identifier,
    documents64: ((record || {}).vehicle || {}).documents64.map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    }))
  };
};
export const vehicleSimpleDefaultBuilder = record => {
  return {
    id: (record || {}).id,
    vehicleId: (record || {}).id,
    renavamCode: (record || {}).renavamCode,
    plate: (record || {}).plate,
    vin: (record || {}).vin,
    tare: (record || {}).tare || 0,
    wheightCapacity: (record || {}).wheightCapacity || 0,
    volumeCapacity: (record || {}).volumeCapacity || 0,
    bodyType: (record || {}).bodyType,
    stateId: (record || {}).stateId,
    vehicleBrandId: (record || {}).vehicleBrandId,
    vehicleModelId: (record || {}).vehicleModelId,
    vehicleTypeId: (record || {}).vehicleTypeId,
    tracker: (record || {}).tracker ? (record || {}).tracker || false : undefined,
    manufactureYear: ((record || {}).manufactureYear || {}).toString(),
    modelYear: ((record || {}).modelYear || {}).toString(),
    identifier: (record || {}).identifier,
    vehicleTrailers: (record || {}).vehicleTrailers,
    documents64: (record || {}).documents64 ? (record || {}).documents64.map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    })) : undefined
  };
};