import gql from "graphql-tag";
import { InvoiceFragment } from "./Invoice";
export const parentInvoiceFragment = gql`
  fragment ParentInvoice on Invoice {
    ...Invoice,
    parent {
      ...Invoice,
    },
    children {
      ...Invoice,
      children {
        ...Invoice,
        children {
          ...Invoice,
          children {
            ...Invoice,
            children {
              ...Invoice,
            }
          }
        }
      }
    }
  }
  ${InvoiceFragment}
`;