import { GAUGE_CHART_TYPE } from "../../constants/Charts";
import { formatter } from "./GenericChartUtil";
export const buildGaugeSeries = (data, valueDataType, valueSuffix = '') => {
  const max = Math.max(...data.map(o => o.value));
  return [{
    type: GAUGE_CHART_TYPE,
    startAngle: 90,
    endAngle: -270,
    min: 0,
    max: max,
    center: ['50%', '55%'],
    pointer: {
      show: false
    },
    progress: {
      show: true,
      overlap: false,
      roundCap: true,
      clip: false,
      itemStyle: {
        borderWidth: 0.3,
        borderColor: '#464646'
      }
    },
    axisLine: {
      lineStyle: {
        width: 35,
        opacity: 0.4
      }
    },
    splitLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false
    },
    title: {
      fontSize: 14
    },
    detail: {
      width: 150,
      height: 15,
      fontSize: 15,
      color: 'inherit',
      borderColor: 'inherit',
      borderRadius: 20,
      borderWidth: 1,
      valueAnimation: true,
      formatter: value => {
        return formatter(value, valueDataType, '', undefined, valueSuffix);
      }
    },
    data: data
  }];
};