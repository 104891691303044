export const REQUESTED = 'requested';
export const CANCELLED = 'cancelled';
export const CANCELLED_BY_COMPANY = 'cancelled_by_company';
export const FINISHED = 'finished';
export const IN_ANALISYS = 'in_analisys';
export const BUYED = 'buyed';
export const WAITING_WITHDRAW = 'waiting_withdraw';
export const CHANGE_REQUESTED = 'change_requested';
export const CHANGE_CONFIRMED = 'change_confirmed';
export const DENIED = 'denied';
export const APPROVED = 'approved';
export const GROUPED = 'grouped';
export const PURCHASE_ORDER = 'purchase_order';
export const REQUEST_EVENTS = [{
  value: REQUESTED,
  label: 'Requisitado'
}, {
  value: CANCELLED,
  label: 'Cancelado'
}, {
  value: CANCELLED_BY_COMPANY,
  label: 'Cancelado pela Cooperativa'
}, {
  value: FINISHED,
  label: 'Finalizado'
}, {
  value: IN_ANALISYS,
  label: 'Sob Análise'
}, {
  value: BUYED,
  label: 'Comprado'
}, {
  value: WAITING_WITHDRAW,
  label: 'Aguardando Retirada'
}, {
  value: CHANGE_REQUESTED,
  label: 'Alteração Requisitada'
}, {
  value: CHANGE_CONFIRMED,
  label: 'Alteração Confirmada'
}, {
  value: DENIED,
  label: 'Negado'
}, {
  value: APPROVED,
  label: 'Aprovado'
}, {
  value: GROUPED,
  label: 'Agrupado'
}, {
  value: PURCHASE_ORDER,
  label: 'Pedido Realizado'
}];