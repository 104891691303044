import gql from "graphql-tag";
import { invoiceItemCofinsFragment } from "./InvoiceItemCofins";
export const invoiceItemCofinsCalculationFragment = gql`
  fragment InvoiceItemCofinsCalculation on InvoiceItemCofinsCalculation  { 
    errors,  
    status,  
    cofins {
      ...InvoiceItemCofins
    }
  }  
  ${invoiceItemCofinsFragment}  
`;