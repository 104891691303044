import gql from "graphql-tag";
import { parentStockOperationFragment } from "../fragments/StockOperationParent";
import { stockOperationSimpleFragment } from "../fragments/OperationSimple";
export const GQL_SEARCH_OPERATION = gql`
  query GetByName($name: String) {    
    stockOperations(name: $name) { 
      ...StockOperationParent
    }
  }
  ${parentStockOperationFragment}   
`;
export const GQL_ALL_AVAILABLE_OPERATIONS = gql`
  query GetAll($all: Boolean) {    
    stockOperations(all: $all) {
      ...StockOperationParent
    }
  }
  ${parentStockOperationFragment}
`;
export const GQL_SEARCH_OPERATION_BY_KIND = gql`
  query GetByKind($movement: OperationFilter) {    
    stockOperations(movement: $movement) { 
      ...StockOperationParent
    }
  }
  ${parentStockOperationFragment}   
`;
export const GQL_SEARCH_OPERATION_BY_INTERNAL = gql`
  query GetByInternal($filter: OperationFilter) {    
    stockOperations(filter: $filter) { 
      ...StockOperationParent
    }
  }
  ${parentStockOperationFragment}   
`;
export const GQL_SEARCH_OPERATION_BY_INTERNAL_PARENT = gql`
  query GetByInternal($filter: OperationFilter) {    
    stockOperations(filter: $filter) { 
      ...StockOperationParent
    }
  }
  ${parentStockOperationFragment}   
`;
export const GQL_OPERATIONS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $stockOperationFilter: StockOperationFilter) {    
    stockOperationsPaginated(first: $pageSize, after: $cursor, stockOperationFilter: $stockOperationFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...StockOperationParent
        }
      }
    }    
  }
  ${parentStockOperationFragment}
`;
export const GQL_ALL_AVAILABLE_OPERATION = gql`
query GetAll($all: Boolean) {    
  stockOperations(all: $all) {
    ...StockOperationParent
  }
}
${parentStockOperationFragment}
`;
export const GQL_OPERATION_STORE = gql`
  mutation StoreStockOperation($attributes: StockOperationAttributes!) {
    storeStockOperation(input: {attributes: $attributes}) { 
      operation {
        ...StockOperationParent
      }, 
      errors 
    } 
  }
  ${parentStockOperationFragment}   
`;
export const GQL_OPERATION_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Stock::Operation"}) {
      id,
      discardedAt
    }
  }
`;