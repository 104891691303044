import gql from "graphql-tag";
export const taxProfileRetentionFragment = gql`
  fragment TaxProfileRetention on TaxProfileRetention  { 
    id,
    rate,
    retentionId,
    financialRetentionToReceiveOperationId,
    financialRetentionPaymentMethodId,
    financialRetentionPaymentConditionId
  }
`;