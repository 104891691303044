import gql from "graphql-tag";
import { productUnitSimpleFragment } from "./ProductUnitSimple";
export const productUnitFragment = gql`
  fragment ProductUnit on ProductUnit  {
    ...ProductUnitSimple
    parent {
      ...ProductUnitSimple
    },
  }
  ${productUnitSimpleFragment}
`;