import gql from "graphql-tag";
import { vehicleBrandFragment } from "./VehicleBrand";
import { vehicleModelFragment } from "./VehicleModel";
import { vehicleTypeFragment } from "./VehicleType";
import { ownerVehicleFragment } from "./OwnerVehicle";
import { driverVehicleFragment } from "./DriverVehicle";
import { vehicleTrailerFragment } from "./VehicleTrailer";
import { stateFragment } from 'admin-front';
export const vehicleSimpleFragment = gql`
  fragment VehicleSimple on Vehicle  { 
    id,
    vehicleTypeId,
    vehicleType {
      ...VehicleType
    },
    vehicleBrandId,
    vehicleBrand {
      ...VehicleBrand
    }
    vehicleModelId,
    vehicleModel {
      ...VehicleModel
    },
    plate,
    vin,
    renavamCode,
    manufactureYear,
    modelYear,
    identifier,
    tracker,
    tare,
    wheightCapacity,
    volumeCapacity,
    volumeCapacity,
    stateId,
    bodyType,
    state {
      ...State
    },    
    ownerVehicle{
      ...OwnerVehicle
    },
    driverVehicle{
      ...DriverVehicle
    },
    vehicleTrailers{
      ...VehicleTrailer
    }
  }
  ${stateFragment}
  ${vehicleTypeFragment}
  ${vehicleBrandFragment}
  ${vehicleModelFragment}
  ${ownerVehicleFragment}
  ${driverVehicleFragment}
  ${vehicleTrailerFragment}
`;