import gql from "graphql-tag";
import { vehicleBodyworkFragment } from "../fragments/VehicleBodywork";
export const GQL_VEHICLE_BODYWORKS_SEARCH = gql`
  {
    vehicleBodyworks { 
      ...VehicleBodywork
    }
  }
  ${vehicleBodyworkFragment}   
`;
export const GQL_VEHICLE_BODYWORKS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    vehicleBodyworksPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...VehicleBodywork
        }
      }
    }    
  }
  ${vehicleBodyworkFragment}
`;
export const GQL_VEHICLE_BODYWORK_STORE = gql`
  mutation StoreVehicleBodywork($attributes: VehicleBodyworkAttributes!) {
    storeVehicleBodywork(input: {attributes: $attributes}) { 
      vehicleBodywork {
        ...VehicleBodywork
    }, 
      errors 
    } 
  }
  ${vehicleBodyworkFragment}   
`;
export const GQL_VEHICLE_BODYWORK_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Fleet::VehicleBodywork"}) {
      id,
      discardedAt
    }
  }
`;