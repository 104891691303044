import gql from "graphql-tag";
import { stockOperationFragment } from "./Operation";
export const parentStockOperationFragment = gql`
  fragment StockOperationParent on StockOperation {
    ...StockOperation,
    parent {
      ...StockOperation,
    },
    children {
      ...StockOperation,
      children {
        ...StockOperation,
        children {
          ...StockOperation,
          children {
            ...StockOperation,
            children {
              ...StockOperation,
            }
          }
        }
      }
    }
  }
  ${stockOperationFragment} 
`;