export const commercialUnitParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      initials: values.initials,
      unitForTransport: values.unitForTransport
    }
  };
};
export const commercialUnitDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    initials: (record || {}).initials,
    unitForTransport: (record || {}).unitForTransport
  };
};