import gql from "graphql-tag";
import { CfopFragment } from "../fragments/Cfop";
export const GQL_CFOP_SELECTION = gql`
  query Search($filter: FilterCfop) {    
    cfops(filter: $filter) {
      ...Cfop
    }    
  }
  ${CfopFragment}
`;
export const GQL_CFOP_SEARCH = gql`
  query Search($pageSize: Int, $cursor: String, $filter: FilterCfop) {    
    cfopsPaginated(first: $pageSize, after: $cursor, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Cfop
        }
      }
    }    
  }
  ${CfopFragment}
`;
export const GQL_CFOP_STORE = gql`
  mutation StoreCfop($attributes: CfopAttributes!) {
    storeCfop(input: {attributes: $attributes}) { 
      cfop { 
        ...Cfop,
      }, 
      errors 
    } 
  }
  ${CfopFragment}  
`;