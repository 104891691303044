import { ENTRY, sanitizeBackend, dateOrToday } from 'grifo-components';
import { ANY_DOCUMENT_EXCEPT_NFCE, INSIDE_STATE, NORMAL, NO_FUEL } from '../constants/taxProfile/TaxFieldsOptions';
export const cfopParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      code: values.code,
      name: values.name,
      kind: values.kind,
      destination: values.destination,
      usage: values.usage,
      documents: values.documents,
      fuelUsage: values.fuelUsage,
      dfeUsage: values.dfeUsage,
      start: values.start,
      finish: values.finish
    }
  };
};
export const cfopDefaultBuilder = record => {
  const object = record || {};
  return {
    code: object.code,
    name: object.name,
    kind: object.kind || ENTRY,
    destination: object.destination || INSIDE_STATE,
    usage: object.usage || NORMAL,
    fuelUsage: object.fuelUsage || NO_FUEL,
    dfeUsage: object.dfeUsage || ANY_DOCUMENT_EXCEPT_NFCE,
    documents: object.documents || [],
    start: dateOrToday(object.start),
    finish: sanitizeBackend(object.finish)
  };
};