import { NOT_TAXABLE, PREVIOUS_TAXABLE_BY_SUBSTITUTION } from "../constants/taxProfile/TaxFieldsOptions";
export const setTranferField = (form, field, indexName, value, items = form.getFieldsValue().items) => {
  if (!form || !field || !(indexName >= 0) || !value || !(items.length > 0)) return;
  if (field === 'withheldBasis') field = 'withheldBasisDestination';
  if (field === 'withheldValue') field = 'withheldValueDestination';
  if (field === 'taxSituation' && ![NOT_TAXABLE, PREVIOUS_TAXABLE_BY_SUBSTITUTION].includes(value)) {
    delete items[indexName].icmsTransfer;
    form.setFieldsValue({
      items: items
    });
    return;
  }
  if (form.getFieldsValue().items[indexName].icmsTransfer?.taxSituation === NOT_TAXABLE && field !== 'goodsOrigin') return;
  items[indexName].icmsTransfer[field] = value;
  form.setFieldsValue({
    items: items
  });
};