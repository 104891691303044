import gql from 'graphql-tag';
export const GQL_DOWNLOAD_XML = gql`
  query DownloadXmls($filters: XmlDownloaderFilter) {
    xmlDownloader(filters: $filters) {
      link
    }
  }
`;
export const GQL_DOCUMENTS_THROUGH_EMAIL = gql`
  mutation DocumentsThroughEmail($attributes: DocumentsThroughEmailAttributes!) {
    documentsThroughEmail(input: {attributes: $attributes}) {
      errors
    } 
  }
`;