export const fiscalServiceParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      code: values.code,
      description: values.description
    }
  };
};
export const fiscalServiceDefaultBuilder = record => {
  return {
    code: (record || {}).code,
    description: (record || {}).description
  };
};