import gql from "graphql-tag";
import { taxProfileAdditionalInformationFragment } from "../fragments/TaxProfileAdditionalInformation";
export const GQL_TAX_PROFILE_ADDITIONAL_INFORMATION_STORE = gql`
  mutation StoreTaxProfileAdditionalInformation($attributes: TaxProfileAdditionalInformationAttributes!, $taxProfileId: ID!) {
    storeTaxProfileAdditionalInformation(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      additionalInformations {
        ...TaxProfileAdditionalInformation
      }, 
      errors 
    } 
  }
  ${taxProfileAdditionalInformationFragment}   
`;