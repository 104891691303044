import gql from "graphql-tag";
import { taxProfileIpiFragment } from "../fragments/TaxProfileIpi";
export const GQL_TAX_PROFILE_IPI_STORE = gql`
  mutation StoreTaxProfileIpi($attributes: TaxProfileIpiAttributes!, $taxProfileId: ID!) {
    storeTaxProfileIpi(input: {attributes: $attributes, taxProfileId: $taxProfileId}) { 
      ipi {
        ...TaxProfileIpi
      }, 
      errors 
    } 
  }
  ${taxProfileIpiFragment}   
`;