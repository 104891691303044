import { GEEKBLUE, LIGHT_BLUE } from "./Colors";
export const ALL = "ALL";
export const YES = "yes";
export const NO = "no";
export const ENTRY = "entry";
export const EXIT = "exit";
export const IO_OPTIONS = [{
  label: 'Entrada',
  value: ENTRY
}, {
  label: 'Saída',
  value: EXIT
}];
export const COMPLETION_STROKE_COLOR = {
  '0%': LIGHT_BLUE,
  '100%': GEEKBLUE
};
export const SUNDAY = "sunday";
export const MONDAY = "monday";
export const TUESDAY = "tuesday";
export const WEDNESDAY = "wednesday";
export const THURSDAY = "thursday";
export const FRIDAY = "friday";
export const SATURDAY = "saturday";
export const DAY_OF_WEEK_OPTIONS = [{
  label: 'Domingo',
  value: SUNDAY
}, {
  label: 'Segunda',
  value: MONDAY
}, {
  label: 'Terça',
  value: TUESDAY
}, {
  label: 'Quarta',
  value: WEDNESDAY
}, {
  label: 'Quinta',
  value: THURSDAY
}, {
  label: 'Sexta',
  value: FRIDAY
}, {
  label: 'Sábado',
  value: SATURDAY
}];
export const JANUARY = "january";
export const FEBRUARY = "february";
export const MARCH = "march";
export const APRIL = "april";
export const MAY = "may";
export const JUNE = "june";
export const JULY = "july";
export const AUGUST = "august";
export const SEPTEMBER = "september";
export const OCTOBER = "october";
export const NOVEMBER = "november";
export const DECEMBER = "december";
export const MONTH_OPTIONS = [{
  label: 'Janeiro',
  value: JANUARY
}, {
  label: 'Fevereiro',
  value: FEBRUARY
}, {
  label: 'Março',
  value: MARCH
}, {
  label: 'Abril',
  value: APRIL
}, {
  label: 'Maio',
  value: MAY
}, {
  label: 'Junho',
  value: JUNE
}, {
  label: 'Julho',
  value: JULY
}, {
  label: 'Agosto',
  value: AUGUST
}, {
  label: 'Setembro',
  value: SEPTEMBER
}, {
  label: 'Outubro',
  value: OCTOBER
}, {
  label: 'Novembro',
  value: NOVEMBER
}, {
  label: 'Dezembro',
  value: DECEMBER
}];
export const TODAY = "today";
export const YESTERDAY = "yesterday";
export const LAST_3_DAYS = "last3Days";
export const LAST_7_DAYS = "last7Days";
export const LAST_15_DAYS = "last15Days";
export const LAST_30_DAYS = "last30Days";
export const LAST_90_DAYS = "last90Days";
export const LAST_6_MONTHS = "last6Months";
export const LAST_12_MONTHS = "last12Months";
export const PERIOD_OPTIONS = [{
  label: 'Hoje',
  value: TODAY
}, {
  label: 'Ontem',
  value: YESTERDAY
},
// { label: 'Últimos 3 dias', value: LAST_3_DAYS },
{
  label: 'Última semana',
  value: LAST_7_DAYS
},
// { label: 'Últimos 15 dias', value: LAST_15_DAYS },
{
  label: 'Últimos 30 dias',
  value: LAST_30_DAYS
}, {
  label: 'Últimos 90 dias',
  value: LAST_90_DAYS
}, {
  label: 'Últimos 6 meses',
  value: LAST_6_MONTHS
}, {
  label: 'Últimos 12 meses',
  value: LAST_12_MONTHS
}];