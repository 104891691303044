export const STOCK_ENTRY_DARK = '#5470C6';
export const STOCK_ENTRY_LIGHT = '#9BB6FF';
export const STOCK_EXIT_DARK = '#91CC75';
export const STOCK_EXIT_LIGHT = '#E9FFD9';
export const STOCK_LOSSES_DARK = '#ED474A';
export const STOCK_LOSSES_LIGHT = '#F5ABAC';
export const STOCK_RESERVE_DARK = '#ECE5A0';
export const STOCK_RESERVE_LIGHT = '#EEEDE0';
export const STOCK_REVERSAL_DARK = '#F5A75E';
export const STOCK_REVERSAL_LIGHT = '#F3C499';
export const STOCK_TOTAL_DARK = '#BEA7E5';
export const STOCK_TOTAL_LIGHT = '#D2C7E6';
export const STOCK_MINIMUM_COLOR = '#E59010';
export const STOCK_MAXIMUM_COLOR = '#F6CC8D';
export const STOCK_OUT_COLOR = '#5F6062';
export const STOCK_IN_COLOR = '#D2E59E';
export const STOCK_GOOD_COLOR = '#A8C686';
export const STOCK_MODERATE_COLOR = '#688B41';
export const STOCK_ALMOST_ZERO_COLOR = '#D10000';
export const STOCK_BELOW_MINIMUM_COLOR = '#F50000';
export const STOCK_LOW_COLOR = '#FF5C5C';
export const STOCK_HIGH_COLOR = '#DB2B39';
export const STOCK_ABOVE_MAXIMUM_COLOR = '#9D1B25';