import gql from "graphql-tag";
export const tradePeopleRankingFragment = gql`
  fragment TradePeopleRanking on TradePeopleRanking {
    id,
    personId,
    name,
    ranking,
    total,
    averageValue,
    averageQuantity,
    totalQuantity,
    invoicesCount,
    profit
  }
`;