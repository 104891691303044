import gql from "graphql-tag";
import { productUnitFragment } from "../fragments/ProductUnit";
export const GQL_PRODUCT_UNIT_STORE = gql`
  mutation StoreProductUnit($attributes: ProductUnitAttributes!, $productId: ID!) {
    storeProductUnit(input: {attributes: $attributes, productId: $productId}) { 
      productUnit {
        ...ProductUnit
      }, 
      errors 
    } 
  }
  ${productUnitFragment}   
`;
export const GQL_PRODUCT_UNITS_BY_PRODUCT = gql`
  query GetByProduct($product: ID) {    
    productUnits(product: $product) { 
      ...ProductUnit
    }
  }
  ${productUnitFragment}   
`;
export const GQL_PRODUCT_UNIT_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Stock::ProductUnit"}) {
      id,
      discardedAt,
      errors
    }
  }
`;